import { UploadOutlined } from "@ant-design/icons";
import { Button, Col, Form, Radio, Row, Spin, Table, Tag, TimePicker, Upload } from "antd";
import "../../../index.scss";
import React, { useCallback, useEffect, useState } from "react";
import {
  TextEditorTKG,
  ModalFormTKG,
  InputTKG,
  DatePickerTKG,
  SelectTKG,
  RangePickerTKG,
} from "tkg-composite-ui";
import { getListTimeoff, getRulesPayoff, uploadFile } from "../../../service";
import BoxCustom from "../../../../../Components/BoxCustom";
import Swal from "sweetalert2";
import dayjs from "dayjs";
import { TIME_OFF_MODE, TIME_OFF_STATUS } from "../../../../../utils/constants";
import RulesPayOffModal from "./RulesPayOffModal";
import { TimePickerTKG } from "tkg-composite-ui/dist/components/Select/TimePickerTKG";

const RequestModal = (props) => {
  const {
    isModalOpen,
    handleRequest,
    handleCancel,
    setListFilePath,
    loading,
    setLoading,
    timeOffMode,
    setTimeOffMode,
    form,
    paidValue,
    setPaidValue,
    setSelectedEmployee,
    listUser
  } = props;

  const [fileList, setFileList] = useState([]);

  const [filterCondition, setFilterCondition] = useState({});
  const [rulesPayoff, setRulesPayoff] = useState();
  const [openRulesModal, setOpenRulesModal] = useState(false);

  const onChangeMode = (e) => {
    setTimeOffMode(e.target.value);
  };
  // const fetchListDataTimeoff = useCallback(async () => {
  //   setLoading(true);
  //   let res = await getListTimeoff(filterCondition).then((res) => {
  //     return res.data.response;
  //   });
  //   setTableData(
  //     res.map((item, index) => ({
  //       name: item.user.first_name + " " + item.user.surname,
  //       period:
  //         dayjs(item.start_time).format("DD-MMM-YYYY") +
  //         " -> " +
  //         dayjs(item.end_time).format("DD-MMM-YYYY"),
  //       status: item.status,
  //     }))
  //   );
  //   setLoading(false);
  //   return res;
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [filterCondition]);

  const fetchRulesPayoff = async () => {
    setLoading(true);

    getRulesPayoff()
      .then((res) => {
        setRulesPayoff(res.data.response);
        setLoading(false);
        return res.data.response;
      })
      .catch((err) => console.log(err));
  };

  useEffect(() => {
    if (paidValue && paidValue !== "Unpaid") {
      fetchRulesPayoff();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [paidValue]);
  // useEffect(() => {
  //   if (filterCondition.end_time) {
  //     fetchListDataTimeoff();
  //   }
  // }, [fetchListDataTimeoff, filterCondition.end_time]);

  const onChangeStartTime = (date, dateString) => {
    switch (timeOffMode) {
      case TIME_OFF_MODE.ONE_DAY:
        setFilterCondition((prev) => ({
        ...prev,
        start_time: date.format("YYYY-MM-DD"),
        end_time: date.format("YYYY-MM-DD"),
      }));
        break;
      case TIME_OFF_MODE.PERIOD:
        setFilterCondition((prev) => ({
          ...prev,
          start_time: date[0].format("YYYY-MM-DD"),
          end_time: date[1].format("YYYY-MM-DD"),
        }));
        break;
      case TIME_OFF_MODE.CUSTOM_TIME:
        setFilterCondition((prev) => ({
        ...prev,
        start_time: date[0].format("YYYY-MM-DD"),
        end_time: date[1].format("YYYY-MM-DD"),
      }));
        break;
    
      default:
        break;
    }
  };

  const handleChangeSelect = (value) => {
    setPaidValue(value);
  };
  const handleChangeEmployee = (value) => {
    setSelectedEmployee(value);
  };
  const showRulesPayoff = () => {
    setOpenRulesModal(true);
  };
  const handleCloseRulesModal = () => {
    setOpenRulesModal(false);
  };
  const handleUpload = async (file) => {
    const formData = new FormData();
    formData.append("document", file);
    formData.append("type", "time-off");
    // You can use any AJAX library you like
    setLoading(true);
    const res = await uploadFile(formData).then((res) => {
      return res.data;
    });
    if (res.meta.status === 200) {
      setListFilePath((prev) => [
        ...prev,
        { path: res.response.path, name: file.name },
      ]);
    } else if (res.meta.status === 422) {
      Swal.fire(res.response.document[0]);
    } else {
      console.log(res);
    }
    setLoading(false);
    return res;
  };
  const uploadProps = {
    onRemove: (file) => {
      const index = fileList.indexOf(file);
      const newFileList = fileList.slice();
      newFileList.splice(index, 1);
      setFileList(newFileList);
    },
    beforeUpload: (file) => {
      const isLt2M = file.size / 1024 / 1024 < 2;
      if (!isLt2M) {
        Swal.fire("Image must smaller than 2MB!");
        return false;
      } else {
        handleUpload(file).then((res) => {
          if (res.meta.status === 200) {
            setFileList((prev) => [...prev, file]);
          }
        });
      }
    },
    fileList,
  };

  return (
    <ModalFormTKG
      title="Modal Form Request"
      onClose={handleCancel}
      open={isModalOpen}
    >
      <Spin spinning={loading}>
        <BoxCustom>
          <Row gutter={[12, 12]}>
            <Col span={24}>
              <Form form={form} layout="vertical" onFinish={handleRequest}>
                <Row gutter={[16, 16]}>
                  <Col span={24}>
                    <Form.Item
                      name="title"
                      label="Title"
                      rules={[
                        { required: true, message: "Please input your title!" },
                      ]}
                    >
                      <InputTKG form={form} />
                    </Form.Item>
                  </Col>
                  <Col span={24}>
                    <Form.Item
                      name="employeeId"
                      label="Employee"
                      // rules={[
                      //   { required: true, message: "Please input your title!" },
                      // ]}
                    >
                      <SelectTKG
                        form={form}
                        onChange={handleChangeEmployee}
                        options={listUser.map(item => ({
                          label: `${item.first_name} ${item.surname}`,
                          value: item.id
                        }))}
                      />
                    </Form.Item>
                  </Col>
                  <Col span={24}>
                    <Form.Item
                      name="reason"
                      label="Reason"
                      rules={[
                        {
                          required: true,
                          message: "Please input your reason!",
                        },
                      ]}
                    >
                      <TextEditorTKG form={form} />
                    </Form.Item>
                  </Col>
                  <Col span={24}>
                    <Form.Item
                      name="policy"
                      label="Policy"
                      rules={[
                        {
                          required: true,
                          message: "Please select your policy!",
                        },
                      ]}
                      validateStatus={
                        paidValue && paidValue !== "Unpaid" ? "warning" : null
                      }
                      help={
                        paidValue && paidValue !== "Unpaid" ? (
                          <div
                            onClick={showRulesPayoff}
                            className="payoff-rules"
                          >
                            Collect payment rules
                          </div>
                        ) : null
                      }
                    >
                      <SelectTKG
                        form={form}
                        onChange={handleChangeSelect}
                        options={[
                          {
                            value: "Annual Leave",
                            label: "Annual Leave",
                          },
                          {
                            value: "Sick Leave",
                            label: "Sick Leave",
                          },
                          {
                            value: "Carer's Leave",
                            label: "Carer's Leave",
                          },
                          {
                            value: "Unpaid",
                            label: "Unpaid",
                          },
                        ]}
                      />
                    </Form.Item>
                  </Col>
                  <Col span={24}>
                    <Radio.Group onChange={onChangeMode} value={timeOffMode}>
                      <Radio value={TIME_OFF_MODE.ONE_DAY}>1 DAY</Radio>
                      <Radio value={TIME_OFF_MODE.PERIOD}>PERIOD</Radio>
                      <Radio value={TIME_OFF_MODE.CUSTOM_TIME}>CUSTOM TIME</Radio>
                    </Radio.Group>
                  </Col>
                  {timeOffMode === TIME_OFF_MODE.PERIOD ? (
                    <Col span={12}>
                      <Form.Item
                        name="start_time"
                        label="Start Time"
                        rules={[
                          {
                            required: true,
                            message: "Please select your time start!",
                          },
                        ]}
                      >
                        <RangePickerTKG
                          allowClear={false}
                          form={form}
                          onChange={onChangeStartTime}
                        />
                      </Form.Item>
                    </Col>
                  ) : null}
                  {timeOffMode === TIME_OFF_MODE.ONE_DAY ? (
                    <Col span={12}>
                      <Form.Item
                        name="start_time"
                        label="Start Time"
                        rules={[
                          {
                            required: true,
                            message: "Please select your time start!",
                          },
                        ]}
                      >
                        <DatePickerTKG
                          allowClear={false}
                          form={form}
                          onChange={onChangeStartTime}
                        />
                      </Form.Item>
                    </Col>
                  ) : null}
                  {timeOffMode === TIME_OFF_MODE.CUSTOM_TIME ? (
                    <Col span={12}>
                      <Form.Item
                        name="start_time"
                        label="Start Time"
                        rules={[
                          {
                            required: true,
                            message: "Please select your time!",
                          },
                        ]}
                      >
                        <TimePicker.RangePicker
                          allowClear={false}
                          changeOnBlur={true}
                          format={"HH:mm A"}
                          size="large"
                          onChange={onChangeStartTime}
                        />
                      </Form.Item>
                    </Col>
                  ) : null}

                  <Col offset={8} span={4}>
                    <Form.Item name="documents" label="Documents">
                      <Upload {...uploadProps} maxCount={1}>
                        <Button icon={<UploadOutlined />}>Select File</Button>
                      </Upload>
                    </Form.Item>
                  </Col>
                </Row>

                <Row gutter={[4, 4]}>
                  <Col span={24} style={{ textAlign: "right" }}>
                    <Button
                      type="primary"
                      htmlType="submit"
                      className="btnTKGAction"
                    >
                      Save
                    </Button>
                  </Col>
                </Row>
              </Form>
            </Col>
            {rulesPayoff && (
              <RulesPayOffModal
                open={openRulesModal}
                onClose={handleCloseRulesModal}
                rulesPayOff={rulesPayoff}
              />
            )}
          </Row>
        </BoxCustom>
      </Spin>
    </ModalFormTKG>
  );
};

export default RequestModal;
