import { Badge, Descriptions, Tag, Space, Button, Spin } from "antd";
import dayjs from "dayjs";
import React from "react";
import { ModalFormTKG } from "tkg-composite-ui";
import BoxCustom from "../../../Components/BoxCustom";
import { useState } from "react";
import { deleteProjectCalendar, updateProjectCalendar } from "../service";
import Swal from "sweetalert2";
import UpdateProject from "./UpdateProject";
const DetailProject = (props) => {
  const {
    openModal,
    handleClose,
    detailData,
    fetchListProjectCalendar,
    fetchListMeetingCalendar,
    form,
    listUser,
    loading,
    setLoading,
  } = props;

  const [openUpdateModal, setOpenUpdateModal] = useState(false);

  const handleCloseUpdate = () => {
    setOpenUpdateModal(false);
  };
  const handleConfirmUpdate = (values) => {
    const payload = {
      ...values,
      start_time: values.period[0].format("YYYY-MM-DD HH:mm:ss"),
      end_time: values.period[1].format("YYYY-MM-DD HH:mm:ss"),
      start_project: values.start_project.format("YYYY-MM-DD HH:mm:ss"),
    };
    updateProjectCalendar(payload, detailData.id).then(async (res) => {
      await fetchListProjectCalendar();
      await fetchListMeetingCalendar();
      setOpenUpdateModal(false);
      handleClose();
      form.resetFields();
      Swal.fire({
        position: "top-end",
        icon: "success",
        title: "Your work has been saved",
        showConfirmButton: false,
        timer: 1000,
        timerProgressBar: true,
      });
    });
  };
  const handleDelete = async () => {
    Swal.fire({
      title: "Are you sure to delete this project?",
      text: "You won't be able to revert this!",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Confirm",
    }).then((result) => {
      if (result.isConfirmed) {
        setLoading(true);
        deleteProjectCalendar(detailData.id).then(async (res) => {
          await fetchListProjectCalendar();
          await fetchListMeetingCalendar();
          setLoading(false);
          handleClose();
          Swal.fire({
            position: "top-end",
            icon: "success",
            title: "Your work has been saved",
            showConfirmButton: false,
            timer: 1000,
            timerProgressBar: true,
          });
        });
      }
    });
  };
  return (
    <ModalFormTKG
      title="Detail Project Calendar"
      onClose={handleClose}
      open={openModal}
    >
      <Spin spinning={loading}>
        <BoxCustom>
          <Descriptions title="Project Infomation" bordered>
            <Descriptions.Item label="Project name" span={3}>
              {detailData?.title}
            </Descriptions.Item>
            <Descriptions.Item label="Start time">
              {dayjs(detailData?.start_time).format("DD-MMM-YYYY HH:mm")}
            </Descriptions.Item>
            <Descriptions.Item label="End time">
              {dayjs(detailData?.end_time).format("DD-MMM-YYYY HH:mm")}
            </Descriptions.Item>
            <Descriptions.Item label="Start project">
              {dayjs(detailData?.start_project).format("DD-MMM-YYYY HH:mm")}
            </Descriptions.Item>
            {/* <Descriptions.Item label="Automatic Renewal">YES</Descriptions.Item> */}

            <Descriptions.Item label="Working days">
              {detailData?.working_days}
            </Descriptions.Item>
            <Descriptions.Item label="Hours per day">
              {detailData?.hours_per_day}
            </Descriptions.Item>
            <Descriptions.Item label="Estimate time">
              {detailData?.estimate}
            </Descriptions.Item>
            <Descriptions.Item label="Priority" span={3}>
              <Badge
                status={
                  detailData.priority === "medium"
                    ? "warning"
                    : detailData.priority === "low"
                    ? "success"
                    : "error"
                }
                text={
                  detailData.priority === "medium" ? (
                    <Tag color={"warning"} key={"medium"}>
                      Medium
                    </Tag>
                  ) : detailData.priority === "low" ? (
                    <Tag color={"success"} key={"low"}>
                      Low
                    </Tag>
                  ) : (
                    <Tag color={"error"} key={"high"}>
                      High
                    </Tag>
                  )
                }
              />
            </Descriptions.Item>
            <Descriptions.Item label="Assign project" span={3}>
              {detailData?.assign_project?.map((item, index) => (
                <Tag key={index}>
                  {item?.first_name} {item?.surname}
                </Tag>
              ))}
            </Descriptions.Item>
            <Descriptions.Item span={3} contentStyle={{ textAlign: "right" }}>
              <Space>
                <Button
                  type="primary"
                  className="btnTKGAction"
                  ghost
                  onClick={() => setOpenUpdateModal(true)}
                >
                  Edit
                </Button>
                <Button
                  type="primary"
                  className="btnTKGDeny"
                  ghost
                  onClick={handleDelete}
                >
                  Delete
                </Button>
              </Space>
            </Descriptions.Item>
          </Descriptions>
        </BoxCustom>

        {openUpdateModal && (
          <UpdateProject
            isModalOpen={openUpdateModal}
            handleOk={handleConfirmUpdate}
            handleCancel={handleCloseUpdate}
            form={form}
            listUser={listUser}
            detailData={detailData}
          />
        )}
      </Spin>
    </ModalFormTKG>
  );
};

export default DetailProject;
