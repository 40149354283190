import {
  DeleteOutlined,
  EditOutlined,
  EllipsisOutlined,
  FolderOutlined,
  FormOutlined,
  PlusCircleOutlined,
} from "@ant-design/icons";
import moment from "moment-timezone";
import "./timeTracker.scss";
import { Button, Card, Tag, Tooltip, Spin, Form, Switch, Row, Col } from "antd";
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import CreateProjectModal from "./modal/CreateProjectModal";
import SettingIcon from "../../assets/SettingIcon";
import {
  deleteTask,
  getActivityRunning,
  getTimeTrackerData,
  handleCreateTask,
  stopTimeActivity,
  updateTask,
} from "./service";
import Swal from "sweetalert2";
import {
  calculateTimeActivity,
  compareFn,
  formatDateTimeByTimeZone,
  formatTime,
} from "../../utils/functions";
import { getDayNameWork, getStringDay } from "./function";
import ListFilter from "./ListFilter";
import {
  StyledHeader,
  StyledMainContent,
  StyledNewProject,
  StyledProjectWrapper,
  StyledUser,
} from "./styled.js";
import { TableTKG } from "tkg-composite-ui";
import DetailTask from "./DetailTask";
import EditTaskModal from "./modal/EditTaskModal";
import BoxCustom from "../../Components/BoxCustom";

const TimeTracker = () => {
  const [form] = Form.useForm();
  const user = JSON.parse(sessionStorage.getItem("user"));
  const countRef = useRef(null);

  const [timer, setTimer] = useState(0);

  const [projectData, setProjectData] = useState([]);
  const [taskData, setTaskData] = useState([]);
  const [isCreatePjOpen, setIsCreatePjOpen] = useState(false);
  const [isEditTaskOpen, setIsEditTaskOpen] = useState(false);
  const [isOpenViewDetail, setIsOpenViewDetail] = useState(false);
  const [loading, setLoading] = useState(false);
  const [checkClassic, setCheckClassic] = useState(true);

  const [filterConditions, setFilterConditions] = useState({
    title: "",
    start_date: moment().subtract(7, "days").format("YYYY-MM-DD"),
    end_date: moment().format("YYYY-MM-DD"),
    user_id: user.role_id === 1 ? undefined : user.id,
  });
  const [filterOptions, setFilterOptions] = useState({
    title: "",
    priority: "",
    status: "",
  });

  const [dataTimerByDate, setDataTimerByDate] = useState([]);
  const [activityData, setActivityData] = useState({});

  useEffect(() => {
    if (timer > 0) {
      let newDataTimerByDate = dataTimerByDate.map((item) => {
        if (
          item.date ===
          formatDateTimeByTimeZone(activityData?.created_at, "YYYY-MM-DD")
        ) {
          item.total += 1;
        }
        return item;
      });
      setDataTimerByDate(newDataTimerByDate);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [timer]);
  const fetchListData = useCallback(
    async (isLoading = true) => {
      setLoading(true);
      const payload = {
        ...(filterConditions.user_id && { user_id: filterConditions.user_id }),
        start_date: filterConditions.start_date,
        end_date: filterConditions.end_date,
      };

      let res = await getTimeTrackerData(payload).then((res) => {
        if (isLoading) {
          setLoading(false);
        }
        return res.data.response;
      });
      res = Object.keys(res).length ? res : [];

      res = res
        .map((item, index) => ({ ...item, index: index }))
        .sort(compareFn);

      let dataFormat = {};
      res.forEach((element) => {
        if (
          !Object.keys(dataFormat).includes(
            formatDateTimeByTimeZone(element.created_at, "YYYY-MM-DD")
          )
        )
          dataFormat[
            formatDateTimeByTimeZone(element.created_at, "YYYY-MM-DD")
          ] = [];
        dataFormat[
          formatDateTimeByTimeZone(element.created_at, "YYYY-MM-DD")
        ].push(element);
      });
      let dataTable = [];
      let dataTimerByDate = [];
      Object.keys(dataFormat).forEach((item) => {
        let totalTime = 0;
        dataTable.push(dataFormat[item]);
        dataFormat[item].forEach((timeTracker) => {
          timeTracker.activities.forEach((activity) => {
            if (activity.logs && activity.logs.length) {
              totalTime += calculateTimeActivity(activity.logs);
            }
          });
        });
        dataTimerByDate.push({ total: totalTime, date: item });
      });
      setDataTimerByDate(dataTimerByDate);
      setProjectData(dataTable);
      return res;
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [filterConditions]
  );

  const fetchActivityRunning = useCallback(async () => {
    setLoading(true);
    let res = await getActivityRunning().then((res) => {
      setLoading(false);
      return res.data.response;
    });
    res = Object.keys(res).length ? res : {};
    if (Object.keys(res).length > 0) {
      clearInterval(countRef.current);
      countRef.current = setInterval(() => {
        setTimer((timer) => timer + 1);
      }, 1000);
    }
    setActivityData(res);
    return res;
  }, []);

  useEffect(() => {
    fetchListData();
    fetchActivityRunning();
  }, [fetchActivityRunning, fetchListData]);

  const handleOpenViewDetail = (record) => {
    setTaskData(record);
    setIsOpenViewDetail(true);
  };
  const handleCloseViewDetail = () => setIsOpenViewDetail(false);
  const handleCancel = () => setIsCreatePjOpen(false);

  const handleCreatePjOpen = () => {
    setIsCreatePjOpen(true);
  };
  const handleCreate = (values) => {
    const payload = {
      title: values.Title,
      tags: [values.Tags],
    };
    handleCreateTask(payload).then((res) => {
      setIsCreatePjOpen(false);
      setFilterOptions({
        title: "",
        priority: "",
        status: "",
      });
      fetchListData();
      // fetchactivityData();
      form.resetFields();
    });
  };

  const handleEditTaskOpen = (record) => {
    setIsEditTaskOpen(true);
    setTaskData(record);
  };
  const handleEditTaskClose = () => {
    setIsEditTaskOpen(false);
  };
  const handleEdit = (values) => {
    const payload = {
      title: values.Title,
      tags: typeof values.Tags === "object" ? values.Tags : [values.Tags],
    };
    updateTask(payload, taskData.id).then((res) => {
      setIsEditTaskOpen(false);
      fetchListData();
      // fetchactivityData();
      Swal.fire({
        position: "top-end",
        icon: "success",
        title: "Your work has been saved",
        showConfirmButton: false,
        timer: 1000,
        timerProgressBar: true,
      });
    });
  };
  const handleDeleteTask = (record) => {
    const payload = {
      start_time: getStringDay(record.created_at),
    };

    const checkTaskRunning = record?.activities?.some(item => item?.logs?.length > 0 && !item?.logs[item?.logs?.length - 1]?.end_time)

    if(checkTaskRunning) {
      Swal.fire({
        position: "top-end",
        icon: "info",
        title: "This task has a running activity. It cannot be deleted!",
        showConfirmButton: false,
        timer: 1500,
        timerProgressBar: true,
      });
    } else {
          Swal.fire({
      title: "Are you sure to delete this task?",
      showCancelButton: true,
      confirmButtonText: "Delete",
    }).then((result) => {
      /* Read more about isConfirmed, isDenied below */
      if (result.isConfirmed) {
        deleteTask(record.id, payload).then((res) => {
          Swal.fire({
            position: "top-end",
            icon: "success",
            title: "Your work has been saved",
            showConfirmButton: false,
            timer: 1000,
            timerProgressBar: true,
          });
          fetchListData();
        });

        // fetchactivityData();
      }
    });
    }

  };

  // Stop activity
  const handleStopTime = async (record, callFetchListData = true) => {
    setLoading(true);
    clearInterval(countRef.current);
    await stopTimeActivity(activityData.id || record.id);
    if (callFetchListData) {
      await fetchListData().then((res) => {
        setActivityData({});
        const selectedRow = res.find((item) => item.id === taskData.id);
        setTaskData(selectedRow);
      });
    }
  };

  const renderStatus = (record) => {
    let results = {
      text: "Pending",
      color: "#fcae26",
    };
    if (
      record?.activities?.length === 0 ||
      !record?.activities?.some((act) => act?.logs?.length > 0)
    ) {
      results = {
        text: "Pending",
        color: "#fcae26",
      };
    } else if (record?.finished_at) {
      results = {
        text: "Completed",
        color: "#108ee9",
      };
    } else {
      results = {
        text: "In Process",
        color: "#87d068",
      };
    }

    return results;
  };

  const getListData = useMemo(() => {
    let dataSearch = projectData;

    if (filterOptions?.title) {
      const data = dataSearch?.map((item) => {
        if (
          item?.filter((el) =>
            el?.title
              ?.toLowerCase()
              .includes(filterOptions?.title.toLowerCase()?.trim())
          )?.length > 0
        ) {
          return item?.filter((el) =>
            el?.title
              ?.toLowerCase()
              .includes(filterOptions?.title.toLowerCase()?.trim())
          );
        } else {
          return [];
        }
      });
      dataSearch = data;
    }
    if (filterOptions?.priority) {
      const data = dataSearch?.map((item) => {
        if (
          item?.filter((el) => el?.tags.includes(filterOptions?.priority))
            ?.length > 0
        ) {
          return item?.filter((el) =>
            el?.tags.includes(filterOptions?.priority)
          );
        } else {
          return [];
        }
      });
      dataSearch = data;
    }
    if (filterOptions?.status) {
      switch (filterOptions?.status) {
        case "pending":
          const dataPending = dataSearch?.map((item) =>
            item?.filter(
              (el) =>
                el?.activities?.length === 0 ||
                !el?.activities?.some((act) => act?.logs?.length > 0)
            )
          );
          dataSearch = dataPending;
          break;
        case "completed":
          const dataCompleted = dataSearch?.map((item) =>
            item?.filter((el) => el?.finished_at)
          );
          dataSearch = dataCompleted;
          break;
        case "ongoing":
          const dataOngoing = dataSearch?.map((item) =>
            item?.filter(
              (el) =>
                !el?.finished_at &&
                el?.activities?.length > 0 &&
                el?.activities?.some((act) => act?.logs?.length > 0)
            )
          );
          dataSearch = dataOngoing;
          break;

        default:
          break;
      }
    }
    //Current Page slice
    return dataSearch;
  }, [projectData, filterOptions]);

  const columns = [
    {
      title: "Title",
      dataIndex: "title",
      key: "title",
      textWrap: "word-break",
      render: (_, record) => {
        const checkNew = record?.activities?.some(
          (el) =>
            !el?.is_finish &&
            el?.logs?.length === 0 &&
            record?.user?.id !== el?.user_id
        );
        return (
          <div>
            {record?.title}{" "}
            {checkNew && <span className="pd-left-5 new-task">New</span>}
          </div>
        );
      },
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      textWrap: "word-break",
      width: 150,
      render: (_, record) => {
        const status = renderStatus(record);
        return (
          <Tag color={status?.color}>
            <span style={{ textTransform: "uppercase" }}>{status?.text}</span>
          </Tag>
        );
      },
    },
    {
      title: "Employee",
      dataIndex: "employee",
      key: "employee",
      width: 200,
      render: (_, record) => (
        <Tag>
          {record?.user.first_name} {record?.user.surname}
        </Tag>
      ),
    },
    {
      title: "Create At",
      dataIndex: "created_at",
      key: "created_at",
      width: 200,
      render: (_, record) => (
        <span>
          {formatDateTimeByTimeZone(record?.created_at, "DD MMM YY - HH:mm")}
        </span>
      ),
    },
    {
      title: "Tags",
      key: "tags",
      dataIndex: "tags",
      width: 150,
      render: (_, record) => {
        const tag = record?.tags[0];
        let color;
        if (tag === "medium") {
          color = "warning";
        } else if (tag === "low") {
          color = "success";
        } else {
          color = "error";
        }

        return (
          <Tag color={color}>
            <span style={{ textTransform: "uppercase" }}>{tag}</span>
          </Tag>
        );
      },
    },
    {
      title: "Action",
      key: "action",
      dataIndex: "action",
      width: 100,
      render: (_, record) => {
        return (
          <Button
            type="primary"
            className="btnTKGAction"
            onClick={() => handleOpenViewDetail(record)}
            icon={<FolderOutlined />}
            ghost
          >
            View detail
          </Button>
        );
      },
    },
    {
      title: "Setting Button",
      key: "settingButton",
      width: 50,
      render: (_, record) => (
        <Tooltip
          color="white"
          overlayInnerStyle={{ color: "black", padding: "0" }}
          placement="bottomRight"
          arrow={false}
          title={
            <div>
              <p
                className="action-btn"
                onClick={() => handleEditTaskOpen(record)}
              >
                <FormOutlined />
              </p>
              <p
                className="action-btn"
                onClick={() => handleDeleteTask(record)}
              >
                <DeleteOutlined style={{ color: "red" }} />
              </p>
            </div>
          }
          trigger="click"
        >
          <Button type="text" icon={<SettingIcon />} />
        </Tooltip>
      ),
    },
  ];
  const statusCard = (record) => {
    const status = renderStatus(record);
    return (
      <Tag color={status?.color}>
        <span style={{ textTransform: "uppercase" }}>{status?.text}</span>
      </Tag>
    );
  };
  const tagCard = (record) => {
    const tag = record?.tags[0];
    let color;
    if (tag === "medium") {
      color = "warning";
    } else if (tag === "low") {
      color = "success";
    } else {
      color = "error";
    }

    return (
      <Tag color={color}>
        <span style={{ textTransform: "uppercase" }}>{tag}</span>
      </Tag>
    );
  };
  const cardItem = (item) => {
    const checkNew = item?.activities?.some(
      (el) =>
        !el?.is_finish &&
        el?.logs?.length === 0 &&
        item?.user?.id !== el?.user_id
    );
    return (
      <Card
        bordered
        key={item?.id}
        style={{ marginBottom: 10 }}
        actions={[
          <Button
            type="primary"
            className="btnTKGAction"
            onClick={() => handleOpenViewDetail(item)}
            icon={<FolderOutlined />}
            ghost
          >
            View detail
          </Button>,
          <Tooltip
            color="white"
            overlayInnerStyle={{ color: "black", padding: "0" }}
            placement="bottomRight"
            arrow={false}
            title={
              <div>
                <p
                  className="action-btn"
                  onClick={() => handleEditTaskOpen(item)}
                >
                  <FormOutlined />
                </p>
                <p
                  className="action-btn"
                  onClick={() => handleDeleteTask(item)}
                >
                  <DeleteOutlined style={{ color: "red" }} />
                </p>
              </div>
            }
            trigger="click"
          >
            <Button type="text" icon={<SettingIcon />} />
          </Tooltip>,
        ]}
      >
        <div>
          <div>
            {item?.title}{" "}
            {checkNew && <span className="pd-left-5 new-task">New</span>}
          </div>
          <StyledHeader>
            <div>{statusCard(item)}</div>
            {/* <StyledUser> */}
            <span style={{ color: "blue", fontWeight: 600 }}>
              {item.user.first_name}
            </span>
            {/* </StyledUser> */}
          </StyledHeader>
        </div>

        <StyledHeader>
          <div>
            <span>
              {formatDateTimeByTimeZone(item?.created_at, "DD MMM YY - HH:mm")}
            </span>
          </div>
          <div>{tagCard(item)}</div>
        </StyledHeader>
      </Card>
    );
  };

  const listTaskPending = getListData?.map((item) =>
    item?.filter(
      (el) =>
        el?.activities?.length === 0 ||
        !el?.activities?.some((act) => act?.logs?.length > 0)
    )
  );
  const listTaskCompleted = getListData?.map((item) =>
    item?.filter((el) => el?.finished_at)
  );
  const listTaskOngoing = getListData?.map((item) =>
    item?.filter(
      (el) =>
        !el?.finished_at &&
        el?.activities?.length > 0 &&
        el?.activities?.some((act) => act?.logs?.length > 0)
    )
  );

  console.log(listTaskOngoing);
  return (
    <BoxCustom>
      <Spin spinning={loading}>
        <StyledMainContent>
          <div
            className="cl-d-flex"
            style={{
              alignItems: "center",
              justifyContent: "space-between",
              marginBottom: "25px",
            }}
          >
            <Button
              type="primary"
              className="btnTKGAction"
              icon={<PlusCircleOutlined />}
              onClick={handleCreatePjOpen}
            >
              Add New
            </Button>
            <StyledHeader>
              {/* <div style={{ margin: "auto 0" }}>
                <ListFilter
                  filterConditions={filterConditions}
                  setFilterConditions={setFilterConditions}
                  form={form}
                />
              </div> */}

              <div style={{ marginRight: 10 }}>
                Total all period time:{" "}
                <b style={{ fontSize: 16 }}>
                  {formatTime(
                    dataTimerByDate.reduce(
                      (total, item) => item.total + total,
                      0
                    )
                  )}
                </b>
              </div>
              <Switch
                checkedChildren="Classic View"
                unCheckedChildren="Chart View"
                checked={checkClassic}
                onChange={(checked) => setCheckClassic(checked)}
              />
            </StyledHeader>
          </div>{" "}
          <div>
            <ListFilter
              filterConditions={filterConditions}
              setFilterConditions={setFilterConditions}
              filterOptions={filterOptions}
              setFilterOptions={setFilterOptions}
              checkClassic={checkClassic}
              form={form}
            />
          </div>
          {Object.keys(activityData).length > 0 ? (
            <StyledNewProject>
              <div style={{ flex: 4 }} className="font-bold">
                {activityData.title}
              </div>
              <div style={{ flex: 3 }}>
                <Tag>
                  {" "}
                  <span style={{ color: "white" }}>
                    {activityData.user.first_name} {activityData.user.surname}
                  </span>
                </Tag>
              </div>

              <div style={{ flex: 1 }}>
                <span className="font-bold">
                  {formatTime(calculateTimeActivity(activityData.logs))}
                </span>
              </div>
              <div>
                <Button type="primary" danger onClick={handleStopTime}>
                  STOP
                </Button>
              </div>
            </StyledNewProject>
          ) : null}
          {checkClassic ? (
            <StyledProjectWrapper>
              {getListData?.length > 0 && getListData?.flat()?.length > 0 ? (
                getListData?.map((item, index) => {
                  return (
                    <>
                      {" "}
                      {item?.length > 0 && (
                        <Card
                          title={getDayNameWork(item[0]?.created_at)}
                          headStyle={{
                            backgroundColor: "#dddfe5",
                            margin: "10px 0px",
                            boxShadow:
                              "0 1px 2px 0 rgb(0 0 0 / 3%), 0 1px 6px -1px rgb(0 0 0 / 2%), 0 2px 4px 0 rgb(0 0 0 / 2%)",
                            borderRadius: "10px",
                          }}
                          extra={
                            <div
                              style={{
                                display: "flex",
                                alignItems: "flex-end",
                                gap: "5px",
                              }}
                            >
                              <span>
                                Total:{" "}
                                <span className="font-bold">
                                  {formatTime(dataTimerByDate[index].total)}
                                </span>
                              </span>
                              <span style={{ fontWeight: 600 }}></span>
                            </div>
                          }
                          style={{
                            width: "100%",
                            margin: "10px 0",
                            borderRadius: "none",
                          }}
                          bodyStyle={{ width: "100%", padding: "0px" }}
                        >
                          <TableTKG
                            dataSource={item}
                            columns={columns}
                            showHeader={false}
                            isShowedFullData
                            pagination={false}
                            scroll={{ x: "auto" }}
                            rowKey="id"
                          />
                        </Card>
                      )}
                    </>
                  );
                })
              ) : (
                <h2
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    marginTop: 30,
                  }}
                >
                  No data
                </h2>
              )}
            </StyledProjectWrapper>
          ) : (
            <div style={{ marginTop: 10 }}>
              <Row gutter={16}>
                <Col span={8}>
                  <Card
                    title="Assigned/Pending"
                    headStyle={{ background: "#fcae26", fontSize: "20px" }}
                    bodyStyle={{
                      minHeight: 200,
                      maxHeight: "1000px",
                      overflowY: "auto",
                    }}
                  >
                    {listTaskPending?.length > 0 &&
                    listTaskPending?.flat()?.length > 0
                      ? listTaskPending?.map((item, index) => {
                          return (
                            <>
                              {" "}
                              {item?.length > 0 && (
                                <Card
                                  bordered={false}
                                  title={getDayNameWork(item[0]?.created_at)}
                                  headStyle={{
                                    backgroundColor: "#dddfe5",
                                    margin: "10px 0px",
                                    boxShadow:
                                      "0 1px 2px 0 rgb(0 0 0 / 3%), 0 1px 6px -1px rgb(0 0 0 / 2%), 0 2px 4px 0 rgb(0 0 0 / 2%)",
                                    borderRadius: "10px",
                                  }}
                                  extra={
                                    <div
                                      style={{
                                        display: "flex",
                                        alignItems: "flex-end",
                                        gap: "5px",
                                      }}
                                    >
                                      <span>
                                        Total:{" "}
                                        <span className="font-bold">
                                          {formatTime(
                                            dataTimerByDate[index].total
                                          )}
                                        </span>
                                      </span>
                                      <span style={{ fontWeight: 600 }}></span>
                                    </div>
                                  }
                                  style={{
                                    width: "100%",
                                    margin: "10px 0",
                                    borderRadius: "none",
                                  }}
                                  bodyStyle={{ width: "100%", padding: "0px" }}
                                >
                                  {item?.map((el) => cardItem(el))}
                                </Card>
                              )}
                            </>
                          );
                        })
                      : null}
                  </Card>
                </Col>

                <Col span={8}>
                  <Card
                    title="In Process"
                    headStyle={{ background: "#87d068", fontSize: "20px" }}
                    bodyStyle={{
                      minHeight: 200,
                      maxHeight: "1000px",
                      overflowY: "auto",
                    }}
                  >
                    {listTaskOngoing?.length > 0 &&
                    listTaskOngoing?.flat()?.length > 0
                      ? listTaskOngoing?.map((item, index) => {
                          return (
                            <>
                              {" "}
                              {item?.length > 0 && (
                                <Card
                                  bordered={false}
                                  title={getDayNameWork(item[0]?.created_at)}
                                  headStyle={{
                                    backgroundColor: "#dddfe5",
                                    margin: "10px 0px",
                                    boxShadow:
                                      "0 1px 2px 0 rgb(0 0 0 / 3%), 0 1px 6px -1px rgb(0 0 0 / 2%), 0 2px 4px 0 rgb(0 0 0 / 2%)",
                                    borderRadius: "10px",
                                  }}
                                  extra={
                                    <div
                                      style={{
                                        display: "flex",
                                        alignItems: "flex-end",
                                        gap: "5px",
                                      }}
                                    >
                                      <span>
                                        Total:{" "}
                                        <span className="font-bold">
                                          {formatTime(
                                            dataTimerByDate[index].total
                                          )}
                                        </span>
                                      </span>
                                      <span style={{ fontWeight: 600 }}></span>
                                    </div>
                                  }
                                  style={{
                                    width: "100%",
                                    margin: "10px 0",
                                    borderRadius: "none",
                                  }}
                                  bodyStyle={{ width: "100%", padding: "0px" }}
                                >
                                  {item?.map((el) => cardItem(el))}
                                </Card>
                              )}
                            </>
                          );
                        })
                      : null}
                  </Card>
                </Col>
                <Col span={8}>
                  <Card
                    title="Complete"
                    headStyle={{ background: "#108ee9", fontSize: "20px" }}
                    bodyStyle={{
                      minHeight: 200,
                      maxHeight: "1000px",
                      overflowY: "auto",
                    }}
                  >
                    {listTaskCompleted?.length > 0 &&
                    listTaskCompleted?.flat()?.length > 0
                      ? listTaskCompleted?.map((item, index) => {
                          return (
                            <>
                              {" "}
                              {item?.length > 0 && (
                                <Card
                                  bordered={false}
                                  title={getDayNameWork(item[0]?.created_at)}
                                  headStyle={{
                                    backgroundColor: "#dddfe5",
                                    margin: "10px 0px",
                                    boxShadow:
                                      "0 1px 2px 0 rgb(0 0 0 / 3%), 0 1px 6px -1px rgb(0 0 0 / 2%), 0 2px 4px 0 rgb(0 0 0 / 2%)",
                                    borderRadius: "10px",
                                  }}
                                  extra={
                                    <div
                                      style={{
                                        display: "flex",
                                        alignItems: "flex-end",
                                        gap: "5px",
                                      }}
                                    >
                                      <span>
                                        Total:{" "}
                                        <span className="font-bold">
                                          {formatTime(
                                            dataTimerByDate[index].total
                                          )}
                                        </span>
                                      </span>
                                      <span style={{ fontWeight: 600 }}></span>
                                    </div>
                                  }
                                  style={{
                                    width: "100%",
                                    margin: "10px 0",
                                    borderRadius: "none",
                                  }}
                                  bodyStyle={{ width: "100%", padding: "0px" }}
                                >
                                  {item?.map((el) => cardItem(el))}
                                </Card>
                              )}
                            </>
                          );
                        })
                      : null}
                  </Card>
                </Col>
              </Row>
            </div>
          )}
        </StyledMainContent>

        {isOpenViewDetail && (
          <DetailTask
            timer={timer}
            taskData={taskData}
            setTaskData={setTaskData}
            isOpenViewDetail={isOpenViewDetail}
            handleCloseViewDetail={handleCloseViewDetail}
            fetchListData={fetchListData}
            fetchActivityRunning={fetchActivityRunning}
            activityData={activityData}
            countRef={countRef}
            handleStopTime={handleStopTime}
            setActivityData={setActivityData}
            loading={loading}
            setLoading={setLoading}
          />
        )}
        <CreateProjectModal
          isModalOpen={isCreatePjOpen}
          handleCreate={handleCreate}
          handleCancel={handleCancel}
          form={form}
        />
        <EditTaskModal
          taskData={taskData}
          isEditTaskOpen={isEditTaskOpen}
          handleEdit={handleEdit}
          handleCancel={handleEditTaskClose}
          form={form}
        />
      </Spin>
    </BoxCustom>
  );
};

export default TimeTracker;
