import { API_CALENDAR } from "../../api-url/calendar";

export const CALENDAR_ENDPOIND = {
  // Project
  GET_PROJECT_CALENDAR: API_CALENDAR.GET_PROJECT_CALENDAR,
  GET_USER_PROJECT_CALENDAR: API_CALENDAR.GET_USER_PROJECT_CALENDAR,
  CREATE_NEW_PROJECT_CALENDAR: API_CALENDAR.CREATE_NEW_PROJECT_CALENDAR,
  UPDATE_PROJECT_CALENDAR: API_CALENDAR.UPDATE_PROJECT_CALENDAR,
  DELETE_PROJECT_CALENDAR: API_CALENDAR.DELETE_PROJECT_CALENDAR,

  // meeting
  GET_MEETING_CALENDAR: API_CALENDAR.GET_MEETING_CALENDAR,
  GET_USER_MEETING_CALENDAR: API_CALENDAR.GET_USER_MEETING_CALENDAR,
  CREATE_NEW_MEETING_CALENDAR: API_CALENDAR.CREATE_NEW_MEETING_CALENDAR,
  UPDATE_MEETING_CALENDAR: API_CALENDAR.UPDATE_MEETING_CALENDAR,
  DELETE_MEETING_CALENDAR: API_CALENDAR.DELETE_MEETING_CALENDAR,
};
