import React from "react";

const SettingIcon = () => {
  return (
    <svg
      fill="none"
      height="20"
      viewBox="0 0 14 14"
      width="20"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g stroke="#999999" strokeLinecap="round" strokeLinejoin="round">
        <path d="m7 3.5c.82843 0 1.5-.67157 1.5-1.5s-.67157-1.5-1.5-1.5-1.5.67157-1.5 1.5.67157 1.5 1.5 1.5z" />
        <path d="m7 8.5c.82843 0 1.5-.67157 1.5-1.5s-.67157-1.5-1.5-1.5-1.5.67157-1.5 1.5.67157 1.5 1.5 1.5z" />
        <path d="m7 13.5c.82843 0 1.5-.6716 1.5-1.5s-.67157-1.5-1.5-1.5-1.5.6716-1.5 1.5.67157 1.5 1.5 1.5z" />
      </g>
    </svg>
  );
};

export default SettingIcon;
