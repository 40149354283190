import { Descriptions, Tag, Space, Button, Spin } from "antd";
import dayjs from "dayjs";
import React from "react";
import { ModalFormTKG } from "tkg-composite-ui";
import BoxCustom from "../../../Components/BoxCustom";
import { useState } from "react";
import { deleteMeetingCalendar, updateMeetingCalendar } from "../service";
import Swal from "sweetalert2";
import UpdateMeeting from "./UpdateMeeting";
import { Link } from "react-router-dom";
const DetailMeeting = (props) => {
  const {
    openModal,
    handleClose,
    detailData,
    fetchListProjectCalendar,
    fetchListMeetingCalendar,
    form,
    listUser,
    loading,
  } = props;

  const [openUpdateModal, setOpenUpdateModal] = useState(false);

  const handleCloseUpdate = () => {
    setOpenUpdateModal(false);
  };
  const handleConfirmUpdate = (values) => {
    const payload = {
      ...values,
      start_time: values.period[0].format("YYYY-MM-DD HH:mm:ss"),
      end_time: values.period[1].format("YYYY-MM-DD HH:mm:ss"),
    };
    updateMeetingCalendar(payload, detailData.id).then(async (res) => {
      await fetchListProjectCalendar();
      await fetchListMeetingCalendar();
      setOpenUpdateModal(false);
      handleClose();
      form.resetFields();
      Swal.fire({
        position: "top-end",
        icon: "success",
        title: "Your work has been saved",
        showConfirmButton: false,
        timer: 1000,
        timerProgressBar: true,
      });
    });
  };
  const handleDelete = async () => {
    Swal.fire({
      title: "Are you sure to delete this meeting?",
      text: "You won't be able to revert this!",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Confirm",
    }).then((result) => {
      if (result.isConfirmed) {
        deleteMeetingCalendar(detailData.id).then(async (res) => {
          await fetchListProjectCalendar();
          await fetchListMeetingCalendar();
          handleClose();
          Swal.fire({
            position: "top-end",
            icon: "success",
            title: "Your work has been saved",
            showConfirmButton: false,
            timer: 1000,
            timerProgressBar: true,
          });
        });
      }
    });
  };
  return (
    <ModalFormTKG title="Detail Meeting" onClose={handleClose} open={openModal}>
      <Spin spinning={loading}>
        <BoxCustom>
          <Descriptions title="Meeting Infomation" bordered>
            <Descriptions.Item label="Topic" span={3}>
              {detailData?.topic}
            </Descriptions.Item>
            <Descriptions.Item label="Start time">
              {dayjs(detailData?.start_time).format("DD-MMM-YYYY HH:mm")}
            </Descriptions.Item>
            <Descriptions.Item label="End time">
              {dayjs(detailData?.end_time).format("DD-MMM-YYYY HH:mm")}
            </Descriptions.Item>
            <Descriptions.Item label="Duration">
              {detailData?.duration}
            </Descriptions.Item>
            <Descriptions.Item label="Location">
              {detailData?.location}
            </Descriptions.Item>
            {detailData?.zoom_link ? (
              <Descriptions.Item label="Zoom link">
                <Link to={detailData?.zoom_link} target="_blank">
                  {detailData?.zoom_link}
                </Link>
              </Descriptions.Item>
            ) : null}
            <Descriptions.Item label="Repeat days">
              <Tag color="success">{detailData?.repeat_days}</Tag>
            </Descriptions.Item>
            <Descriptions.Item label="Assign meeting" span={3}>
              {detailData?.assign_meeting.map((item, index) => (
                <Tag key={index}>
                  {item.first_name} {item.surname}
                </Tag>
              ))}
            </Descriptions.Item>
            <Descriptions.Item span={3} contentStyle={{ textAlign: "right" }}>
              <Space>
                <Button
                  type="primary"
                  className="btnTKGAction"
                  ghost
                  onClick={() => setOpenUpdateModal(true)}
                >
                  Edit
                </Button>
                <Button
                  type="primary"
                  className="btnTKGDeny"
                  ghost
                  onClick={handleDelete}
                >
                  Delete
                </Button>
              </Space>
            </Descriptions.Item>
          </Descriptions>
        </BoxCustom>
        {openUpdateModal && (
          <UpdateMeeting
            isModalOpen={openUpdateModal}
            handleOk={handleConfirmUpdate}
            handleCancel={handleCloseUpdate}
            form={form}
            listUser={listUser}
            detailData={detailData}
          />
        )}
      </Spin>
    </ModalFormTKG>
  );
};

export default DetailMeeting;
