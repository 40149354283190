import { Endpoint } from "../../../utils/endpoind";
import { deleteData, getData, postData } from "../../../utils/request";
import dayjs from 'dayjs';


export const handleClockIn = async () => {
  const payload = { request_date_time: dayjs().format('YYYY-MM-DD HH:mm:ss') }
    return await postData(
    {url: `${Endpoint.CLOCK_IN}`, payload}
  );
};
export const handleClockOut = async () => {
  const payload = { request_date_time: dayjs().format('YYYY-MM-DD HH:mm:ss') }
  return await postData(
    {url: `${Endpoint.CLOCK_OUT}`, payload}
  );
};
export const getLogToday = async () => {
    return await getData(
    {url: `${Endpoint.LOG_TODAY}?request_date_time=${dayjs().format('YYYY-MM-DD HH:mm:ss')}`}
  );
};
export const handleBreakWork = async () => {
  const payload = { request_date_time: dayjs().format('YYYY-MM-DD HH:mm:ss') }
  return await postData(
    {url: `${Endpoint.BREAK}`, payload}
  );
};
export const handleLunchBreakWork = async () => {
  const payload = { request_date_time: dayjs().format('YYYY-MM-DD HH:mm:ss') }
  return await postData(
    {url: `${Endpoint.LUNCH_BREAK}`, payload}
  );
};
export const handleResumeWork = async () => {
  const payload = { request_date_time: dayjs().format('YYYY-MM-DD HH:mm:ss') }
  return await postData(
    {url: `${Endpoint.RESUME_WORK}`, payload}
  );
};
export const handleResetTime = async () => {
  return await deleteData(
    {url: `${Endpoint.RESET_TIME}`}
  );
};
