import { Badge, Calendar, Spin } from "antd";
import "./style.scss";
import { useCallback, useEffect, useState } from "react";
import dayjs from "dayjs";
import { getListTimeoff } from "./service";
import { TIME_OFF_STATUS } from "../../utils/constants";
import moment from "moment";

const setListNotification = (value, timeoffEvents) => {
  let listData = [];
  const dataTimeroff = timeoffEvents.filter(
    (item) => moment(item.start_time).date() === value.date()
  );

  if (dataTimeroff.length > 0) {
    dataTimeroff.map((item) =>
      listData.push({
        ...item,
        type:
          item?.status === TIME_OFF_STATUS.REJECTED
            ? "error"
            : item?.status === TIME_OFF_STATUS.APPROVED
            ? "success"
            : "warning",
        content: `${item?.user?.first_name} ${item?.user?.surname}`,
      })
    );
  }

  return listData;
};
const getListData = (value, timeoffEvents) => {
  let listData = [];
  switch (value.date()) {
    case 1:
      listData = setListNotification(value, timeoffEvents);

      break;
    case 2:
      listData = setListNotification(value, timeoffEvents);

      break;
    case 3:
      listData = setListNotification(value, timeoffEvents);
      break;
    case 4:
      listData = setListNotification(value, timeoffEvents);

      break;
    case 5:
      listData = setListNotification(value, timeoffEvents);

      break;
    case 6:
      listData = setListNotification(value, timeoffEvents);
      break;
    case 7:
      listData = setListNotification(value, timeoffEvents);

      break;
    case 8:
      listData = setListNotification(value, timeoffEvents);

      break;
    case 9:
      listData = setListNotification(value, timeoffEvents);

      break;
    case 10:
      listData = setListNotification(value, timeoffEvents);

      break;
    case 11:
      listData = setListNotification(value, timeoffEvents);

      break;
    case 12:
      listData = setListNotification(value, timeoffEvents);
      break;
    case 13:
      listData = setListNotification(value, timeoffEvents);

      break;
    case 14:
      listData = setListNotification(value, timeoffEvents);

      break;
    case 15:
      listData = setListNotification(value, timeoffEvents);
      break;
    case 16:
      listData = setListNotification(value, timeoffEvents);

      break;
    case 17:
      listData = setListNotification(value, timeoffEvents);

      break;
    case 18:
      listData = setListNotification(value, timeoffEvents);

      break;
    case 19:
      listData = setListNotification(value, timeoffEvents);

      break;
    case 20:
      listData = setListNotification(value, timeoffEvents);

      break;
    case 21:
      listData = setListNotification(value, timeoffEvents);
      break;
    case 22:
      listData = setListNotification(value, timeoffEvents);

      break;
    case 23:
      listData = setListNotification(value, timeoffEvents);

      break;
    case 24:
      listData = setListNotification(value, timeoffEvents);
      break;
    case 25:
      listData = setListNotification(value, timeoffEvents);

      break;
    case 26:
      listData = setListNotification(value, timeoffEvents);

      break;
    case 27:
      listData = setListNotification(value, timeoffEvents);

      break;
    case 28:
      listData = setListNotification(value, timeoffEvents);

      break;
    case 29:
      listData = setListNotification(value, timeoffEvents);

      break;
    case 30:
      listData = setListNotification(value, timeoffEvents);
      break;
    case 31:
      listData = setListNotification(value, timeoffEvents);

      break;

    default:
  }
  return listData || [];
};

const CalendarTimeoff = () => {
  const [loading, setLoading] = useState([]);
  const [timeoffEvents, setTimeoffEvents] = useState([]);
  const [filterConditions, setFilterConditions] = useState({
    start_date: dayjs().startOf("month").format("YYYY-MM-DD"),
    end_date: dayjs().endOf("month").format("YYYY-MM-DD"),
  });

  const fetchListTimeOff = async () => {
    const res = await getListTimeoff(filterConditions).then(
      (res) => res.data.response
    );

    setTimeoffEvents(res);
    return res;
  };

  useEffect(() => {
    setLoading(true);
    fetchListTimeOff().then((res) => setLoading(false));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterConditions]);

  const onPanelChange = (value, mode) => {
    setFilterConditions({
      start_date: value.startOf("month").format("YYYY-MM-DD"),
      end_date: value.endOf("month").format("YYYY-MM-DD"),
    });
  };
  const dateCellRender = useCallback(
    (value) => {
      const listData = getListData(value, timeoffEvents);
      return (
        <ul className="events">
          {listData.map((item, i) => (
            <li key={i}>
              <Badge status={item.type} text={item.content} />
            </li>
          ))}
        </ul>
      );
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [timeoffEvents]
  );
  return (
    <Spin spinning={loading}>
      <Calendar onPanelChange={onPanelChange} dateCellRender={dateCellRender} />
    </Spin>
  );
};
export default CalendarTimeoff;
