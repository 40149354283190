import React, { useEffect, useReducer, useState } from "react";
import {
  createBrowserRouter,
  RouterProvider,
  useNavigate,
} from "react-router-dom";
import CalendarPage from "./pages/CalendarPage";
import DashBoardPage from "./pages/DashBoardPage";
import ErrorPage from "./pages/ErrorPage";
import RootPage from "./pages/RootPage";
import TimeOffPage from "./pages/TimeOffPage";
import TimeSheetPage from "./pages/TimeSheetPage";
import TimeTrackerPage from "./pages/TimeTrackerPage";
import { ConfigProvider } from "antd";
import Context from "./utils/context";
import * as ACTIONS from "./store/actions/actions";
import * as TimerReducer from "./store/reducers/timerReducer";
import VerifyPage from "./pages/VerifyPage";
import ClockinOutReportPage from "./pages/ClockinOutReportPage";
import Swal from "sweetalert2";
import dayjs from "dayjs";
import {
  getListRequest,
  getUserWorkOut,
} from "./modules/clockin-out-report/service";
import ManageTimer from "./modules/manage-time";

const router = createBrowserRouter([
  {
    path: "/",
    element: <RootPage />,
    errorElement: <DashBoardPage />,
    children: [
      {
        path: "/dashboard",
        key: "dashboard",
        element: <DashBoardPage />,
      },
      {
        path: "/timesheet",
        key: "timesheet",
        element: <TimeSheetPage />,
      },
      {
        path: "/time-tracker",
        key: "timetracker",
        element: <TimeTrackerPage />,
      },
      {
        path: "/calendar",
        key: "calendar",
        element: <CalendarPage />,
      },
      {
        path: "/time-off",
        key: "timeoff",
        element: <TimeOffPage />,
      },
      {
        path: "/clock-in-out-report",
        key: "clockinoutreport",
        element: <ClockinOutReportPage />,
      },
      {
        path: "/manage-timer",
        key: "managetimer",
        element: <ManageTimer />,
      },
      {
        path: "/verify/:token",
        key: "verify",
        element: <VerifyPage />,
      },
    ],
  },
]);
function App() {
  const [stateTimerReducer, dispatchTimerReducer] = useReducer(
    TimerReducer.TimerReducer,
    TimerReducer.initialState
  );

  const handleShowModal = () => {
    dispatchTimerReducer(ACTIONS.showModal());
  };

  const handleHideModal = () => {
    dispatchTimerReducer(ACTIONS.hideModal());
  };
  const handleShowRequestModal = () => {
    dispatchTimerReducer(ACTIONS.showRequestModal());
  };

  const handleHideRequestModal = () => {
    dispatchTimerReducer(ACTIONS.hideRequestModal());
  };
  const handleShowListRequestModal = () => {
    dispatchTimerReducer(ACTIONS.showListRequestModal());
  };

  const handleHideListRequestModal = () => {
    dispatchTimerReducer(ACTIONS.hideListRequestModal());
  };

  return (
    <Context.Provider
      value={{
        isOpenModal: stateTimerReducer.isOpenModal,
        showModal: () => handleShowModal(),
        hideModal: () => handleHideModal(),

        // list request modal
        isOpenListRequestModal: stateTimerReducer.isOpenListRequestModal,
        showListRequestModal: () => handleShowListRequestModal(),
        hideListRequestModal: () => handleHideListRequestModal(),
        // request modal
        isOpenRequestModal: stateTimerReducer.isOpenRequestModal,
        showRequestModal: () => handleShowRequestModal(),
        hideRequestModal: () => handleHideRequestModal(),
      }}
    >
      <RouterProvider router={router} />
    </Context.Provider>
  );
}

export default App;
