import { Endpoint } from "../../utils/endpoind";
import { buildQueryString } from "../../utils/functions";
import { deleteData, getData, postData, putData } from "../../utils/request";

export const getListClients = async () => {
  return await getData({ url: `${Endpoint.GET_LIST_USER}` });
};
export const getTimeSheetData = async (payload, id) => {
  const query = buildQueryString(payload);
  return await getData({ url: `${Endpoint.LIST_TIMESHEET}/${id}?${query}` });
};
export const getTotalClockInOut = async (payload, id) => {
  const query = buildQueryString(payload);
  return await getData({
    url: `${Endpoint.TOTAL_CLOCK_IN_OUT}/${id}?${query}`,
  });
};
export const postNoteTimeTracker = async (payload) => {
  return await postData({
    url: `${Endpoint.CREATE_NOTE_TIME_TRACKER}`,
    payload,
  });
};
export const updateNoteTimeTracker = async (payload, id) => {
  return await putData({
    url: `${Endpoint.UPDATE_NOTE_TIME_TRACKER}/${id}`,
    payload,
  });
};
export const deleteNoteTimeTracker = async (id) => {
  return await deleteData({
    url: `${Endpoint.DELETE_NOTE_TIME_TRACKER}/${id}`,
  });
};
