export const ACTION = {
  LUNCH_BREAK: "Lunch Break",
  BREAK: "Break",
  RESUME_WORK: "Resume work",
};

export const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

export const TIME_OFF_STATUS = {
  ALL: "",
  PENDING: 1,
  APPROVED: 2,
  REJECTED: 3,
};
export const TIME_OFF_POLICY = {
  ALL: "",
  ANNUAL_LEAVE: "Annual Leave",
  SICK_LEAVE: "Sick Leave",
  UNPAID: "Unpaid",
};
export const TIME_OFF_MODE = {
  ONE_DAY: 1,
  PERIOD: 2,
  CUSTOM_TIME: 3
};
export const REQUEST_WORKOUT_STATUS = {
  PENDING: 1,
  ACCEPTED: 2,
  REJECTED: 3,
};
export const CLOCK_IN_OUT_STATUS = {
  MANUAL: 0,
  REQUEST: 1,
};
