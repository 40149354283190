import React from "react";
import { ModalFormTKG } from "tkg-composite-ui";
import BoxCustom from "../../../../../Components/BoxCustom";
import { Descriptions, Tag, Badge, Space } from "antd";
import dayjs from "dayjs";
import { TIME_OFF_STATUS } from "../../../../../utils/constants";
import { Link } from "react-router-dom";
const DetailTimeOffModal = (props) => {
  const { open, onClose, selectedEvent } = props;
  return (
    <ModalFormTKG title="Detail Time Off" onClose={onClose} open={open}>
      <BoxCustom>
        <Descriptions title="Time Off Info" bordered>
          <Descriptions.Item label="Employee">
            <Tag>
              {selectedEvent?.user.first_name} {selectedEvent?.user.surname}
            </Tag>
          </Descriptions.Item>
          <Descriptions.Item label="Billing Mode">
            {selectedEvent?.policy === "No Paid" ? (
              <Tag color="error">{selectedEvent?.policy}</Tag>
            ) : (
              <Tag color="success">{selectedEvent?.policy}</Tag>
            )}
          </Descriptions.Item>
          {/* <Descriptions.Item label="Automatic Renewal">YES</Descriptions.Item> */}
          <Descriptions.Item label="Order time">
            {dayjs(selectedEvent.start_time).format("DD-MMM-YYYY")} {" -> "}
            {dayjs(selectedEvent.end_time).format("DD-MMM-YYYY")} -{" "}
            {dayjs(selectedEvent.end_time).diff(
              dayjs(selectedEvent.start_time),
              "day"
            ) + 1}{" "}
            days
          </Descriptions.Item>
          <Descriptions.Item label="Reason" span={3}>
            <div
              className="view-content"
              dangerouslySetInnerHTML={{
                __html: selectedEvent?.reason,
              }}
            ></div>
          </Descriptions.Item>
          <Descriptions.Item label="Status" span={3}>
            <Badge
              status={
                selectedEvent.status === TIME_OFF_STATUS.PENDING
                  ? "warning"
                  : selectedEvent.status === TIME_OFF_STATUS.APPROVED
                  ? "success"
                  : "error"
              }
              text={
                selectedEvent.status === TIME_OFF_STATUS.PENDING
                  ? "Pending"
                  : selectedEvent.status === TIME_OFF_STATUS.APPROVED
                  ? "Approve"
                  : "Rejected"
              }
            />
          </Descriptions.Item>
          {selectedEvent?.documents ? (
            <Descriptions.Item label="Documents" span={3}>
              <Space>
                {JSON.parse(selectedEvent?.documents)?.map((item, index) => (
                  <Link
                    to={`${process.env.REACT_APP_AWS_URL}${item?.path}`}
                    target="_blank"
                  >
                    {item?.name}
                  </Link>
                ))}
              </Space>
            </Descriptions.Item>
          ) : null}
        </Descriptions>
      </BoxCustom>
    </ModalFormTKG>
  );
};

export default DetailTimeOffModal;
