import { LeftOutlined, RightOutlined } from "@ant-design/icons";
import moment from "moment";
import React, { useState } from "react";
import { getDateRangeOfWeek } from "../../utils/functions";
import "./calendar.scss";

// Returns the ISO week of the date.
// eslint-disable-next-line no-extend-native
Date.prototype.getWeek = function () {
  const date = new Date(this.getTime());
  date.setHours(0, 0, 0, 0);
  // Thursday in current week decides the year.
  date.setDate(date.getDate() + 3 - ((date.getDay() + 6) % 7));
  // January 4 is always in week 1.
  var week1 = new Date(date.getFullYear(), 0, 4);
  // Adjust to Thursday in week 1 and count number of weeks from date to week1.
  return (
    1 +
    Math.round(
      ((date.getTime() - week1.getTime()) / 86400000 -
        3 +
        ((week1.getDay() + 6) % 7)) /
        7
    )
  );
};

function CustomToolbar(props) {
  const [viewState, setViewState] = useState("week");
  const getCustomToolbar = () => {
    // const toolbarDate = props.date;
    const goToDayView = () => {
      props.onView("day");
      setViewState("day");
    };
    const goToWeekView = () => {
      props.onView("week");
      setViewState("week");
    };
    // const goToMonthView = () => {
    //   props.onView("month");
    //   setViewState("month");
    // };
    const goToAgendaView = () => {
      props.onView("agenda");
      setViewState("agenda");
    };
    const goToBack = () => {
      let view = viewState;
      let mDate = props.date;
      let newDate;
      if (view === "month") {
        newDate = new Date(mDate.getFullYear(), mDate.getMonth() - 1, 1);
      } else if (view === "week") {
        newDate = new Date(
          mDate.getFullYear(),
          mDate.getMonth(),
          mDate.getDate() - 7,
          1
        );
      } else {
        newDate = new Date(
          mDate.getFullYear(),
          mDate.getMonth(),
          mDate.getDate() - 1,
          1
        );
      }
      props.onNavigate("prev", newDate);
    };
    const goToNext = () => {
      let view = viewState;
      let mDate = props.date;
      let newDate;
      if (view === "month") {
        newDate = new Date(mDate.getFullYear(), mDate.getMonth() + 1, 1);
      } else if (view === "week") {
        newDate = new Date(
          mDate.getFullYear(),
          mDate.getMonth(),
          mDate.getDate() + 7,
          1
        );
      } else {
        newDate = new Date(
          mDate.getFullYear(),
          mDate.getMonth(),
          mDate.getDate() + 1,
          1
        );
      }
      props.onNavigate("next", newDate);
    };

    const goToToday = () => {
      const now = new Date();
      props.date.setMonth(now.getMonth());
      props.date.setYear(now.getFullYear());
      props.date.setDate(now.getDate());
      props.onNavigate("current");
    };

    // const goToBackYear = () => {
    //   let mDate = props.date;
    //   let newDate = new Date(mDate.getFullYear() - 1, 1);
    //   props.onNavigate("prev", newDate);
    // };

    // const goToNextYear = () => {
    //   let mDate = props.date;
    //   let newDate = new Date(mDate.getFullYear() + 1, 1);
    //   props.onNavigate("next", newDate);
    // };

    // const month = () => {
    //   const date = moment(props.date);
    //   let month = date.format("MMMM");
    //   let day = date.format("D");

    //   return (
    //     <span className="rbc-toolbar-label rbc-date">
    //       <i className="far fa-calendar"></i> <span>{`${month} ${day}`}</span>
    //     </span>
    //   );
    // };

    const week = () => {
      const date = moment(props.date);
      const [startWeek, endWeek] = getDateRangeOfWeek({
        weekNo: date.week(),
        year: date.year(),
      });
      const startDayWeek = moment(startWeek).format("Do");
      const startDayOfMonth = moment(startWeek).format("MMM");
      const endDayWeek = moment(endWeek).format("Do");
      const endDayOfMonth = moment(endWeek).format("MMM");
      return (
        <span className="rbc-toolbar-label rbc-date">
          <i className="far fa-calendar"></i>{" "}
          <span>{`${startDayOfMonth} ${startDayWeek} - ${endDayOfMonth} ${endDayWeek}`}</span>
        </span>
      );
    };
    // const year = () => {
    //   const date = moment(props.date);
    //   let year = date.format("YYYY");

    //   return (
    //     <span className="rbc-btn-group">
    //       {viewState === "month" && (
    //         <button type="button" onClick={goToBackYear}>
    //           <span className="prev-icon">&#8249;&#8249;</span>
    //         </button>
    //       )}
    //       <span className="rbc-toolbar-label">{year}</span>
    //       {viewState === "month" && (
    //         <button type="button" onClick={goToNextYear}>
    //           <span className="prev-icon">&#8250;&#8250;</span>
    //         </button>
    //       )}
    //     </span>
    //   );
    // };

    const day = () => {
      let view = viewState;
      const date = moment(props.date);
      let day;
      if (view === "day") {
        day = date.format("ddd") + " " + date.format("Do");
      }
      return <span className="rbc-toolbar-label">{day}</span>;
    };

    return (
      <div className="rbc-toolbar">
        <span className="rbc-btn-group">
          {/* <button onClick={goToMonthView}>
            <span className="label-filter-off">CALENDAR</span>
          </button> */}
          <button className="" onClick={goToDayView}>
            <span className="label-filter-off">Day</span>
          </button>
          <button className="" onClick={goToWeekView}>
            <span className="label-filter-off">Week</span>
          </button>
          <button className="" onClick={goToAgendaView}>
            <span className="label-filter-off">Agenda</span>
          </button>
        </span>
        {viewState === "day" && day()}
        {viewState === "week" && week()}
        {/* {viewState === "month" && month()} */}
        <span className="rbc-btn-group">
          <span className="next-icon today" onClick={goToToday}>
            Today
          </span>

          <span
            className="btn-icon prev-icon"
            id="prev-btn-icon"
            onClick={goToBack}
          >
            <LeftOutlined style={{ color: "#cccccc" }} />
          </span>

          <span
            className="btn-icon next-icon"
            id="next-btn-icon"
            onClick={goToNext}
          >
            <RightOutlined style={{ color: "#cccccc" }} />
          </span>
        </span>
      </div>
    );
  };

  return <>{getCustomToolbar()}</>;
}

export default CustomToolbar;
