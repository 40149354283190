import { Form, Row, Col, Spin, Space, Typography } from "antd";
import moment from "moment";
import "./timeLine.scss";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import "react-big-calendar/lib/css/react-big-calendar.css";
import PropTypes from "prop-types";
import {
  Calendar,
  Views,
  DateLocalizer,
  momentLocalizer,
} from "react-big-calendar";
import dayjs from "dayjs";
import BoxCustom from "../../../../Components/BoxCustom";
import { getListTimeoff } from "../../service";
import { TIME_OFF_STATUS } from "../../../../utils/constants";
import DetailTimeOffModal from "./modal/DetailTimeOffModal";
import CustomToolbar from "../../../../Components/CustomToolbar";
import styled from "styled-components";

const TimeLineTab = () => {
  const user = JSON.parse(sessionStorage.getItem("user"));

  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);

  const [isOpen, setIsOpen] = useState(false);
  const [selectedEvent, setSelectedEvent] = useState();
  const [userEvents, setUserEvents] = useState([]);
  const [filterConditions, setFilterConditions] = useState({
    start_date: dayjs().startOf("month").format("YYYY-MM-DD"),
    end_date: dayjs().endOf("month").format("YYYY-MM-DD"),
  });

  const fetchListTimeOff = async () => {
    const res = await getListTimeoff(filterConditions).then(
      (res) => res.data.response
    );

    const filterEvent = res.filter(
      (item) => item.status !== TIME_OFF_STATUS.REJECTED
    );
    const userEvents = filterEvent.map((item) => {
      return {
        ...item,
        desc: item?.title,
        key: item?.id,
        id: item?.id,
        title: `${item?.user.first_name} ${item?.user.surname}`,
        start: new Date(item?.start_time),
        end: new Date(item?.end_time ? item?.end_time : item?.start_time),
        isActive: item?.status === TIME_OFF_STATUS.APPROVED ? true : false,
      };
    });
    setUserEvents(userEvents);
    return userEvents;
  };

  useEffect(() => {
    setLoading(true);
    fetchListTimeOff().then((res) => setLoading(false));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterConditions]);

  // const handleSelectSlot = useCallback(({ start, end }) => {
  //   setOpenRequestModal(true);
  // }, []);

  const handleCloseModal = () => {
    setIsOpen(false);
  };
  const handleSelectEvent = (event) => {
    setSelectedEvent(event);
    setIsOpen(true);
  };

  const CustomEvent = (event) => {
    return (
      <span style={{ display: "flex", alignItems: "center" }}>
        <span
          className={event.event.isActive ? "dot green-dot" : " dot red-dot"}
        ></span>{" "}
        {event.event.title}{" "}
      </span>
    );
  };
  const eventPropGetter = useCallback((event, start, end, isSelected) => {
    return {
      ...(event && {
        style: {
          backgroundColor: "#265985",
          color: "white",
          fontSize: "12px",
          // border: "1px solid #265985",
        },
      }),
    };
  }, []);

  const { defaultDate, scrollToTime, formats } = useMemo(
    () => ({
      defaultDate: new Date(),
      scrollToTime: new Date(1970, 1, 1, 6),
      formats: {
        dayHeaderFormat: (date, culture, localizer) =>
          localizer.format(date, `ddd, DD / MMM`, culture),
        dayRangeHeaderFormat: ({ start, end }, culture, localizer) =>
          localizer.format(start, `ddd, DD / MMM`, culture) +
          " — " +
          localizer.format(end, `ddd, DD / MMM`, culture),
        agendaHeaderFormat: ({ start, end }, culture, localizer) =>
          localizer.format(start, `ddd, DD / MMM`, culture) +
          " — " +
          localizer.format(end, `ddd, DD / MMM`, culture),
      },
    }),
    []
  );

  return (
    <Spin spinning={loading}>
      <BoxCustom>
        <Row gutter={[12, 12]}>
          <Col span={24} align="end">
            <Space>
              <BoxCustom>
                <Space>
                  <Space>
                    <Typography className="dot red-dot"></Typography>
                    <Typography> Request rejected timeoff</Typography>
                  </Space>
                  |
                  <Space>
                    <Typography className="dot green-dot"></Typography>
                    <Typography>Request accepted timeoff</Typography>
                  </Space>
                </Space>
              </BoxCustom>
            </Space>
          </Col>
          <Col span={24}>
            <Container className="height600">
              <Calendar
                defaultDate={defaultDate}
                defaultView={Views.MONTH}
                events={userEvents}
                eventPropGetter={eventPropGetter}
                localizer={momentLocalizer(moment)}
                onSelectEvent={handleSelectEvent}
                // onSelectSlot={handleSelectSlot}
                selectable
                scrollToTime={scrollToTime}
                components={{
                  // toolbar: CustomToolbar,
                  event: CustomEvent,
                }}
                formats={formats}
                style={{
                  height: `calc(100vh - 380px)`,
                  width: "100%",
                  backgroundColor: "white",
                  padding: "10px",
                }}
              />
            </Container>
          </Col>
        </Row>
      </BoxCustom>
      {selectedEvent && (
        <DetailTimeOffModal
          open={isOpen}
          onClose={handleCloseModal}
          selectedEvent={selectedEvent}
        />
      )}
    </Spin>
  );
};

const Container = styled.div`
  .rbc-toolbar-label {
    font-weight: 600 !important;
  }
  .rbc-current button {
    background: blue !important;
    color: #fff !important;
    border-radius: 50%;
    height: 20px !important;
    width: 25px !important;
  }

  .rbc-today button {
    background: blue !important;
    color: #fff !important;
    border-radius: 40%;
    height: 20px !important;
    width: 45px !important;
    font-weight: 600;
    padding: 2px;
  }
  .rbc-row-segment .rbc-show-more {
    color: #063964 !important;
  }
`;
Calendar.propTypes = {
  localizer: PropTypes.instanceOf(DateLocalizer),
};
export default TimeLineTab;
