const BASE_API_URL_TIME_TRACKER = process.env.REACT_APP_TIME_REPORT_API_URL;
const BASE_API_URL_COMPANY_RULES = process.env.REACT_APP_COMPANY_RULES_API_URL;
export const API_TIMEOFF = {
  ADD_NEW_REQUEST: `${BASE_API_URL_TIME_TRACKER}/api/time-off/store`,
  GET_LIST_DATA_TIME_OFF: `${BASE_API_URL_TIME_TRACKER}/api/time-off`,
  UPDATE_REQUEST: `${BASE_API_URL_TIME_TRACKER}/api/time-off/update`,
  DELETE_REQUEST: `${BASE_API_URL_TIME_TRACKER}/api/time-off/delete`,
  RESPONSE_REQUEST: `${BASE_API_URL_TIME_TRACKER}/api/time-off/accepted`,
  DETAIL_REQUEST: `${BASE_API_URL_TIME_TRACKER}/api/time-off/detail`,
  GET_RULES_PAYOFF: `${BASE_API_URL_COMPANY_RULES}/api/rule-fines/detail/28`,
};
