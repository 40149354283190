import { Button, Col, Form, Row } from "antd";
import React from "react";
import {
  ModalTKG,
  InputTKG,
  RangePickerTKG,
  SelectTKG,
} from "tkg-composite-ui";
import BoxCustom from "../../../Components/BoxCustom";
import { useEffect } from "react";
import dayjs from "dayjs";

const listRepeatDays = [
  {
    value: "Every Day",
    label: "Every Day",
  },
  {
    value: "Every Moday",
    label: "Every Moday",
  },
  {
    value: "Every Tuesday",
    label: "Every Tuesday",
  },
  {
    value: "Every Wednesday",
    label: "Every Wednesday",
  },
  {
    value: "Every Thurday",
    label: "Every Thurday",
  },
  {
    value: "Every Friday",
    label: "Every Friday",
  },
];
const UpdateMeeting = (props) => {
  const { isModalOpen, handleOk, handleCancel, form, listUser, detailData } =
    props;
  const user = JSON.parse(sessionStorage.getItem("user"));

  useEffect(() => {
    form.setFieldsValue({
      topic: detailData.topic,
      location: detailData.location,
      zoom_link: detailData.zoom_link,
      period: [dayjs(detailData.start_time), dayjs(detailData.end_time)],
      duration: detailData.duration,
      assign_meeting: detailData.assign_meeting.map((item) => item.id),
      repeat_days: detailData.repeat_days,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [detailData]);
  return (
    <ModalTKG
      title="Create Meeting"
      open={isModalOpen}
      footer={false}
      onCancel={handleCancel}
    >
      <BoxCustom>
        <Form
          layout="vertical"
          form={form}
          onFinish={handleOk}
          initialValues={{
            topic: detailData.topic,
            location: detailData.location,
            zoom_link: detailData.zoom_link,
            period: [dayjs(detailData.start_time), dayjs(detailData.end_time)],
            duration: detailData.duration,
            assign_meeting: detailData.assign_meeting.map((item) => item.id),
            repeat_days: detailData.repeat_days,
          }}
        >
          <Row gutter={[8, 4]}>
            <Col span={24}>
              <Form.Item
                name="topic"
                label="Topic"
                rules={[
                  { required: true, message: "Please input your topic!" },
                ]}
              >
                <InputTKG />
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item
                name="location"
                label="Location"
                rules={[
                  { required: true, message: "Please input your location!" },
                ]}
              >
                <InputTKG />
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item name="zoom_link" label="Zoom Link">
                <InputTKG />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={[8, 4]}>
            <Col span={8}>
              <Form.Item
                name="period"
                label="Period"
                rules={[
                  { required: true, message: "Please choose your time!" },
                ]}
              >
                {/* <RangePickerTKG allowClear={false} format="DD-MM-YYYY" /> */}
                <RangePickerTKG
                  showTime={{
                    format: "HH:mm",
                  }}
                  format="YYYY-MM-DD HH:mm"
                  minuteStep={15}
                />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item
                name="duration"
                label="Duration"
                rules={[
                  { required: true, message: "Please input your duration!" },
                ]}
              >
                <InputTKG />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={[8, 4]}>
            {user.role_id === 1 ? (
              <Col span={12}>
                <Form.Item
                  name="assign_meeting"
                  label="Assign Meeting"
                  rules={[
                    {
                      required: true,
                      message: "Please choose your assign meeting!",
                    },
                  ]}
                >
                  <SelectTKG
                    form={form}
                    mode="multiple"
                    maxTagCount={2}
                    allowClear
                    placeholder="Select members"
                    showSearch
                    optionFilterProp="children"
                    filterOption={(input, option) =>
                      (option?.label ?? "")
                        .toLowerCase()
                        .includes(input.toLowerCase())
                    }
                    options={listUser.map((item) => ({
                      label: `${item?.first_name} ${item?.surname} ( ${item?.group} - ${item?.position} ) `,
                      value: item.id,
                    }))}
                  />
                </Form.Item>
              </Col>
            ) : null}
            <Col span={12}>
              <Form.Item
                name="repeat_days"
                label="Repeat days"
                rules={[
                  {
                    required: true,
                    message: "Please choose your repeat days!",
                  },
                ]}
              >
                <SelectTKG
                  form={form}
                  // placeholder="Select tags"
                  showSearch
                  optionFilterProp="children"
                  filterOption={(input, option) =>
                    (option?.label ?? "")
                      .toLowerCase()
                      .includes(input.toLowerCase())
                  }
                  options={listRepeatDays.map((item) => ({
                    label: item.label,
                    value: item.value,
                  }))}
                />
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={[4, 4]} justify="end">
            <Col span={4}>
              <Button
                type="primary"
                className="btnTKGDeny"
                ghost
                onClick={handleCancel}
                style={{ marginRight: "10px" }}
              >
                Cancel
              </Button>

              <Button type="primary" className="btnTKGAction" htmlType="submit">
                Update
              </Button>
            </Col>
          </Row>
        </Form>
      </BoxCustom>
    </ModalTKG>
  );
};

export default UpdateMeeting;
