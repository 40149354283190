import { API_TIME_TRACKER } from "../../api-url/time-tracker";

export const TIME_TRACKER_ENDPOIND = {
  STORE: API_TIME_TRACKER.STORE,
  LIST_TIME_TRACKER: API_TIME_TRACKER.LIST_TIME_TRACKER,
  RUNNING_FRAGMENT: API_TIME_TRACKER.RUNNING_FRAGMENT,
  DELETE_TASK: API_TIME_TRACKER.DELETE_TASK,
  DELETE_FRAGMENT_TASK: API_TIME_TRACKER.DELETE_FRAGMENT_TASK,
  UPDATE_TASK: API_TIME_TRACKER.UPDATE_TASK,
  FINISH_TASK: API_TIME_TRACKER.FINISH_TASK,

  // Activity   // Activity
  ACTIVITY_STORE: API_TIME_TRACKER.ACTIVITY_STORE,
  ACTIVITY_RUNNING: API_TIME_TRACKER.ACTIVITY_RUNNING,
  ACTIVITY_DELETE: API_TIME_TRACKER.ACTIVITY_DELETE,
  ACTIVITY_START: API_TIME_TRACKER.ACTIVITY_START,
  ACTIVITY_PAUSE: API_TIME_TRACKER.ACTIVITY_PAUSE,
  ACTIVITY_STOP: API_TIME_TRACKER.ACTIVITY_STOP,
  // Timer
  START_TIME_TRACKER: API_TIME_TRACKER.START_TIME_TRACKER,
  STOP_TIME_TRACKER: API_TIME_TRACKER.STOP_TIME_TRACKER,
  CREATE_NOTE_TIME_TRACKER: API_TIME_TRACKER.CREATE_NOTE_TIME_TRACKER,
  UPDATE_NOTE_TIME_TRACKER: API_TIME_TRACKER.UPDATE_NOTE_TIME_TRACKER,
  DELETE_NOTE_TIME_TRACKER: API_TIME_TRACKER.DELETE_NOTE_TIME_TRACKER,
};
