import { Button, Col, Form, Row, TimePicker } from "antd";
import dayjs from "dayjs";
import React, { useContext, useState } from "react";
import Swal from "sweetalert2";
import { ModalFormTKG, DatePickerTKG, TextEditorTKG } from "tkg-composite-ui";
import BoxCustom from "../../../Components/BoxCustom";
import Context from "../../../utils/context";
import { requestWorkHours } from "../service";

const RequestWorkModal = (props) => {
  const { open, onClose, fetchListRequest } = props;
  const context = useContext(Context);

  const [form] = Form.useForm();

  const [startTime, setStartTime] = useState(dayjs());
  const [endTime, setEndTime] = useState(dayjs());
  const [loading, setLoading] = useState(false);

  const handleRequest = async (values) => {
    const payload = {
      ...values,
      date: dayjs(values.date).format("YYYY-MM-DD"),
      start_time: `${dayjs(values.date).format("YYYY-MM-DD")} ${dayjs(
        values.start_time
      ).format("HH:mm:ss")}`,
      end_time: `${dayjs(values.date).format("YYYY-MM-DD")} ${dayjs(
        values.end_time
      ).format("HH:mm:ss")}`,
    };

    setLoading(true);
    requestWorkHours(payload).then((res) => {
      setLoading(false);
      if (res.data.meta.status === 200) {
        Swal.fire("Create Request Successfully!", "", "success");
      } else if (res.data.meta.status === 422) {
        Swal.fire("The date has already been taken.", "", "error");
      }
      fetchListRequest();
      context.hideRequestModal();
      form.resetFields();
    });
  };
  return (
    <ModalFormTKG
      title="Request WorkHours Form"
      onClose={onClose}
      open={open}
      footer={false}
    >
      <BoxCustom>
        <Form form={form} layout="vertical" onFinish={handleRequest}>
          <Row gutter={[16, 16]}>
            <Col span={8}>
              <Form.Item
                name="date"
                label="Date"
                rules={[
                  { required: true, message: "Please choose your date!" },
                ]}
              >
                <DatePickerTKG form={form} />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item
                name="start_time"
                label="Clock in"
                rules={[
                  { required: true, message: "Please choose your time!" },
                ]}
                trigger="onSelect"
              >
                <TimePicker
                  size="large"
                  format="HH:mm"
                  minuteStep={10}
                  style={{ width: "100%" }}
                  value={startTime}
                  onSelect={(time) => {
                    // Making it controlled, but this is NOT required
                    setStartTime(time);
                  }}
                />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item
                name="end_time"
                label="Clock out"
                rules={[
                  { required: true, message: "Please choose your time!" },
                ]}
                trigger="onSelect"
              >
                <TimePicker
                  size="large"
                  format="HH:mm"
                  minuteStep={10}
                  style={{ width: "100%" }}
                  value={endTime}
                  onSelect={(time) => {
                    // Making it controlled, but this is NOT required
                    setEndTime(time);
                  }}
                />
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item
                name="reason"
                label="Reason"
                rules={[
                  { required: true, message: "Please input your reason!" },
                ]}
              >
                <TextEditorTKG form={form} />
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={[4, 4]}>
            <Col span={24} style={{ textAlign: "right" }}>
              <Button
                loading={loading}
                type="primary"
                htmlType="submit"
                className="btnTKGAction"
              >
                Save
              </Button>
            </Col>
          </Row>
        </Form>
      </BoxCustom>
    </ModalFormTKG>
  );
};

export default RequestWorkModal;
