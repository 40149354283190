import { API_CLOCK_IN_CLOCK_OUT } from "../../api-url/workout";

export const CLOCKIN_CLOCKOUT_ENDPOIND = {
  //Clock in - Clock out
  CLOCK_IN: API_CLOCK_IN_CLOCK_OUT.CLOCK_IN,
  CLOCK_OUT: API_CLOCK_IN_CLOCK_OUT.CLOCK_OUT,
  BREAK: API_CLOCK_IN_CLOCK_OUT.BREAK,
  LUNCH_BREAK: API_CLOCK_IN_CLOCK_OUT.LUNCH_BREAK,
  RESUME_WORK: API_CLOCK_IN_CLOCK_OUT.RESUME_WORK,
  LOG_TODAY: API_CLOCK_IN_CLOCK_OUT.LOG_TO_DAY,
  RESET_TIME: API_CLOCK_IN_CLOCK_OUT.RESET_TIME,
  TOTAL_CLOCK_IN_OUT: API_CLOCK_IN_CLOCK_OUT.TOTAL_CLOCK_IN_OUT,

  // Request workout
  REQUEST_WORKOUT: API_CLOCK_IN_CLOCK_OUT.REQUEST_WORKOUT,
  UPDATE_REQUEST_WORKOUT: API_CLOCK_IN_CLOCK_OUT.UPDATE_REQUEST_WORKOUT,
  DELETE_REQUEST_WORKOUT: API_CLOCK_IN_CLOCK_OUT.DELETE_REQUEST_WORKOUT,
  LIST_REQUEST_WORKOUT: API_CLOCK_IN_CLOCK_OUT.LIST_REQUEST_WORKOUT,
  REJECT_REQUEST_WORKOUT: API_CLOCK_IN_CLOCK_OUT.REJECT_REQUEST_WORKOUT,
  ACCEPT_REQUEST_WORKOUT: API_CLOCK_IN_CLOCK_OUT.ACCEPT_REQUEST_WORKOUT,

  // List all workout
  LIST_ALL_WORKOUT: API_CLOCK_IN_CLOCK_OUT.LIST_ALL_WORKOUT,
};
