import React from "react";
import { Card, Table } from "antd";
import styled from "styled-components";
const TableComponent = (props) => {
  const { columns, dataSource, pagination, header, scroll } = props;
  return (
    <StyledContainer>
      <Card
        title={header ? header : ""}
        bordered={false}
        headStyle={{ background: "#ebeeef" }}
      >
        <Table
          columns={columns}
          dataSource={dataSource}
          pagination={pagination ? pagination : false}
          scroll={scroll}
          rowKey={"id"}
        />
      </Card>
    </StyledContainer>
  );
};

const StyledContainer = styled.div`
  padding: 10px;
`;
export default TableComponent;
