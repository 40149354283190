import React, { useEffect } from "react";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { getInfoUser } from "../modules/verify/service";
import { Spin } from "antd";

const VerifyPage = () => {
  const param = useParams();
  const token = param.token;
  const [searchParams, setSearchParams] = useSearchParams();

  sessionStorage.setItem("accessToken", token);

  const navigate = useNavigate();

  useEffect(() => {
    getInfoUser({ Authorization: `Bearer ${token}` }).then((res) => {
      if (res) {
        if (searchParams.get("from-iframe") != null) {
          sessionStorage.setItem("from-iframe", JSON.stringify(true));
        } else {
          sessionStorage.setItem("from-iframe", JSON.stringify(false));
        }
        sessionStorage.setItem("user", JSON.stringify(res.data.response));
        navigate("/dashboard");
      }
    });
  }, []);
  return (
    <Spin style={{ height: "100vh", width: "100%" }} spinning={true}>
      <div></div>
    </Spin>
  );
};

export default VerifyPage;
