import React from "react";
import { ModalTKG } from "tkg-composite-ui";

const RulesPayOffModal = (props) => {
  const { open, onClose, rulesPayOff } = props;
  return (
    <ModalTKG
      title={rulesPayOff?.title}
      footer={false}
      onClose={onClose}
      closable={true}
      open={open}
    >
      <div
        className="view-content"
        dangerouslySetInnerHTML={{
          __html: rulesPayOff?.content,
        }}
        style={{ height: "650px", overflowY: "scroll" }}
      ></div>
    </ModalTKG>
  );
};

export default RulesPayOffModal;
