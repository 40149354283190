import { Row, Col, Form, Spin, Space, Typography, Button } from "antd";
import CreateProjectModal from "./modal/CreateProjectModal";
import "./calendar.scss";
import CreateMeetingModal from "./modal/CreateMeetingModal";
import React, { useState, useCallback, useMemo } from "react";
import "react-big-calendar/lib/css/react-big-calendar.css";
import PropTypes from "prop-types";
import {
  Calendar,
  Views,
  DateLocalizer,
  momentLocalizer,
} from "react-big-calendar";
import moment from "moment";
import CustomToolbar from "./CustomToolbar";
import withDragAndDrop from "react-big-calendar/lib/addons/dragAndDrop";
import "react-big-calendar/lib/addons/dragAndDrop/styles.css";
import BoxCustom from "../../Components/BoxCustom";
import {
  createMeetingCalendar,
  createProjectCalendar,
  getListClients,
  getListMeetingCalendar,
  getListProjectCalendar,
  updateMeetingCalendar,
  updateProjectCalendar,
} from "./service";
import { useEffect } from "react";
import dayjs from "dayjs";
import { SelectTKG } from "tkg-composite-ui";
import DetailProject from "./modal/DetailProject";
import DetailMeeting from "./modal/DetailMeeting";
import Swal from "sweetalert2";
import styled from "styled-components";

const DnDCalendar = withDragAndDrop(Calendar);

const Calender = () => {
  const user = JSON.parse(sessionStorage.getItem("user"));
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [isMeetingModalOpen, setIsMeetingModalOpen] = useState(false);
  const [openDetailProject, setOpenDetailProject] = useState(false);
  const [openDetailMeeting, setOpenDetailMeeting] = useState(false);
  const [myEvents, setEvents] = useState([]);
  const [selectedEvent, setSelectedEvent] = useState({});
  const [selectedSlot, setSelectedSlot] = useState({});
  const [listUser, setListUser] = useState([]);
  const [filterConditions, setFilterConditions] = useState({
    start_time: dayjs().startOf("month").format("YYYY-MM-DD"),
    end_time: dayjs().endOf("month").format("YYYY-MM-DD"),
    user_id: user.id,
  });

  const handleSelectSlot = (slotInfo) => {
    console.log(slotInfo, "0909090");
    setSelectedSlot(slotInfo);
    setIsMeetingModalOpen(true);
  };

  const fetchListUser = async () => {
    let res = await getListClients().then((res) => {
      return res.data.response;
    });
    setListUser(res);
    return res;
  };

  const fetchListProjectCalendar = async () => {
    const res = await getListProjectCalendar(filterConditions).then(
      (res) => res.data.response
    );

    const projectEvents = res.map((item, ind) => {
      return {
        key: item?.id + "P",
        title: item?.title,
        start: new Date(item?.start_time),
        end: new Date(item.end_time ? item?.end_time : item?.start_time),
        isProject: true,
        allDay: !item?.all_day ? false : true,
        ...item,
      };
    });
    setEvents(projectEvents);
    return projectEvents;
  };
  const fetchListMeetingCalendar = async () => {
    const res = await getListMeetingCalendar(filterConditions).then(
      (res) => res.data.response
    );

    const meetingEvents = res.map((item, ind) => {
      return {
        key: item?.id + "M",
        title: item?.topic,
        start: new Date(item?.start_time),
        end: new Date(item.end_time ? item?.end_time : item?.start_time),
        isMeeting: true,
        allDay: item?.all_day === 1 ? true : false,
        ...item,
      };
    });
    setEvents((prev) => [...prev, ...meetingEvents]);
    return meetingEvents;
  };

  useEffect(() => {
    setLoading(true);
    fetchListProjectCalendar().then((res) =>
      fetchListMeetingCalendar().then((res) => setLoading(false))
    );

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterConditions]);
  useEffect(() => {
    fetchListUser();
  }, []);
  const CustomEvent = (event) => {
    return event.event.allDay ? (
      <>
        <span>
          {dayjs(event.event.start_time).format("DD-MMM")}
          {" -> "}
          {dayjs(event.event.end_time).format("DD-MMM")}
        </span>{" "}
        {" | "}
        <span>{event.event.title}</span>
      </>
    ) : (
      <span>{event.event.title}</span>
    );
  };
  const eventPropGetter = useCallback((event, start, end, isSelected) => {
    return {
      ...(event && {
        style: {
          padding: "5px",
          backgroundColor: event.isMeeting ? "#81caec" : "#f3ec67",
          color: event.isMeeting ? "#000" : "#000",
          fontSize: "12px",
          fontWeight: 600,
          border: "1px solid #fff",
        },
      }),
    };
  }, []);

  const handleSelectEvent = (event) => {
    if (event.isProject) {
      setSelectedEvent(event);
      setOpenDetailProject(true);
    } else if (event.isMeeting) {
      setSelectedEvent(event);
      setOpenDetailMeeting(true);
    }
  };

  const moveEvent = ({
    event,
    start,
    end,
    isAllDay: droppedOnAllDaySlot = false,
  }) => {
    let payload = {};

    setEvents((prev) => {
      // const existing = prev.find((ev) => ev.id === event.id) ?? {};
      const filtered = prev.filter((ev) => ev.id !== event.id);
      // return [...filtered, { ...existing, start, end, allDay }];
      return filtered;
    });
    if (event.isProject) {
      if (droppedOnAllDaySlot) {
        payload = {
          ...event,
          assign_project: event.assign_project.map((item) => item.id),
          start_time: dayjs(start).format("YYYY-MM-DD HH:mm:ss"),
          end_time: dayjs(end).format("YYYY-MM-DD HH:mm:ss"),
          all_day: droppedOnAllDaySlot,
        };
      } else {
        payload = {
          ...event,
          assign_project: event.assign_project.map((item) => item.id),
          start_time: dayjs(start).format("YYYY-MM-DD HH:mm:ss"),
          end_time: `${dayjs(start).format("YYYY-MM-DD")} ${dayjs(end).format(
            "HH:mm:ss"
          )}`,
          all_day: droppedOnAllDaySlot,
        };
      }

      setLoading(true);
      updateProjectCalendar(payload, event.id).then((res) => {
        console.log("Move successfully");
        fetchListProjectCalendar().then((res) =>
          fetchListMeetingCalendar().then((res) => {
            setLoading(false);
            Swal.fire({
              position: "top-end",
              icon: "success",
              title: "Your work has been saved",
              showConfirmButton: false,
              timer: 1000,
              timerProgressBar: true,
            });
          })
        );
      });
    } else if (event.isMeeting) {
      if (droppedOnAllDaySlot) {
        payload = {
          ...event,
          assign_meeting: event.assign_meeting.map((item) => item.id),
          start_time: dayjs(start).format("YYYY-MM-DD HH:mm:ss"),
          end_time: dayjs(end).format("YYYY-MM-DD HH:mm:ss"),
          all_day: droppedOnAllDaySlot,
        };
      } else {
        payload = {
          ...event,
          assign_meeting: event.assign_meeting.map((item) => item.id),
          start_time: dayjs(start).format("YYYY-MM-DD HH:mm:ss"),
          end_time: `${dayjs(start).format("YYYY-MM-DD")} ${dayjs(end).format(
            "HH:mm:ss"
          )}`,
          all_day: droppedOnAllDaySlot,
        };
      }

      setLoading(true);
      updateMeetingCalendar(payload, event.id).then((res) => {
        console.log("Move successfully");
        fetchListProjectCalendar().then((res) =>
          fetchListMeetingCalendar().then((res) => {
            setLoading(false);
            Swal.fire({
              position: "top-end",
              icon: "success",
              title: "Your work has been saved",
              showConfirmButton: false,
              timer: 1000,
              timerProgressBar: true,
            });
          })
        );
      });
    }
  };

  const resizeEvent = ({ event, start, end }) => {
    setEvents((prev) => {
      const filtered = prev.filter((ev) => ev.id !== event.id);
      return filtered;
    });
    if (event.isProject) {
      const payload = {
        ...event,
        assign_project: event.assign_project.map((item) => item.id),
        start_time: dayjs(start).format("YYYY-MM-DD HH:mm:ss"),
        end_time: dayjs(end).format("YYYY-MM-DD HH:mm:ss"),
      };
      setLoading(true);

      updateProjectCalendar(payload, event.id).then((res) => {
        console.log("resize p successfully");
        fetchListProjectCalendar().then((res) =>
          fetchListMeetingCalendar().then((res) => {
            setLoading(false);
            Swal.fire({
              position: "top-end",
              icon: "success",
              title: "Your work has been saved",
              showConfirmButton: false,
              timer: 1000,
              timerProgressBar: true,
            });
          })
        );
      });
    } else if (event.isMeeting) {
      const payload = {
        ...event,
        assign_meeting: event.assign_meeting.map((item) => item.id),
        start_time: dayjs(start).format("YYYY-MM-DD HH:mm:ss"),
        end_time: dayjs(end).format("YYYY-MM-DD HH:mm:ss"),
      };
      setLoading(true);

      updateMeetingCalendar(payload, event.id).then((res) => {
        console.log("resize m successfully");
        fetchListProjectCalendar().then((res) =>
          fetchListMeetingCalendar().then((res) => {
            setLoading(false);
            Swal.fire({
              position: "top-end",
              icon: "success",
              title: "Your work has been saved",
              showConfirmButton: false,
              timer: 1000,
              timerProgressBar: true,
            });
          })
        );
      });
    }
  };
  const { defaultDate, scrollToTime, formats } = useMemo(
    () => ({
      defaultDate: new Date(),
      scrollToTime: new Date(1970, 1, 1, 6),
      formats: {
        dayHeaderFormat: (date, culture, localizer) =>
          localizer.format(date, `ddd, DD / MMM`, culture),
        dayRangeHeaderFormat: ({ start, end }, culture, localizer) =>
          localizer.format(start, `ddd, DD / MMM`, culture) +
          " — " +
          localizer.format(end, `ddd, DD / MMM`, culture),
        agendaHeaderFormat: ({ start, end }, culture, localizer) =>
          localizer.format(start, `ddd, DD / MMM`, culture) +
          " — " +
          localizer.format(end, `ddd, DD / MMM`, culture),
      },
    }),
    []
  );

  const onSelectChange = (value) => {
    setFilterConditions((prev) => ({ ...prev, user_id: value }));
  };

  const handleCancelMeeting = () => {
    setIsMeetingModalOpen(false);
  };

  const handleCloseDetailMeeting = () => {
    setOpenDetailMeeting(false);
  };
  const handleCloseDetailProject = () => {
    setOpenDetailProject(false);
  };

  const handleCreateProject = (values) => {
    console.log(values);
    setLoading(true);
    const payload = {
      ...values,
      ...(user.role_id !== 1 && { assign_project: [user.id] }),
      start_time: `${values.start_date.format(
        "YYYY-MM-DD"
      )} ${values.start_time.format("HH:mm:ss")}`,
      end_time: `${values.end_date.format(
        "YYYY-MM-DD"
      )} ${values.end_time.format("HH:mm:ss")}`,
    };
    createProjectCalendar(payload).then(async (res) => {
      await fetchListProjectCalendar();
      await fetchListMeetingCalendar();
      setLoading(false);
      form.resetFields();
      // Call lai api get lis
    });
    setIsMeetingModalOpen(false);
  };
  const handleCreateMeeting = (values) => {
    setLoading(true);
    const payload = {
      ...values,
      ...(user.role_id !== 1 && { assign_meeting: [user.id] }),
      start_time: `${values.start_date.format(
        "YYYY-MM-DD"
      )} ${values.start_time.format("HH:mm:ss")}`,
      end_time: `${values.end_date.format(
        "YYYY-MM-DD"
      )} ${values.end_time.format("HH:mm:ss")}`,
      duration: values.end_time.diff(values.start_time, "min"),
    };
    createMeetingCalendar(payload).then(async (res) => {
      await fetchListProjectCalendar();
      await fetchListMeetingCalendar();
      setLoading(false);
      form.resetFields();
      // Call lai api get lis
    });
    setIsMeetingModalOpen(false);
  };

  return (
    <Spin spinning={loading}>
      <BoxCustom>
        <Row gutter={4} style={{ marginBottom: "15px" }}>
          {user?.role_id !== 1 ? null : (
            <Col span={4}>
              <SelectTKG
                form={form}
                onChange={onSelectChange}
                // placeholder="Select tags"
                defaultValue={`${user?.first_name} ${user?.surname} ( ${user?.group} - ${user?.position} ) `}
                showSearch
                optionFilterProp="children"
                filterOption={(input, option) =>
                  (option?.label ?? "")
                    .toLowerCase()
                    .includes(input.toLowerCase())
                }
                options={listUser.map((item) => ({
                  label: `${item?.first_name} ${item?.surname} ( ${item?.group} - ${item?.position} ) `,
                  value: item.id,
                }))}
              />
            </Col>
          )}
          <Col span={user?.role_id !== 1 ? 24 : 20} align="end">
            <Space>
              <BoxCustom>
                <Space>
                  <Space>
                    <Typography
                      style={{
                        width: "20px",
                        height: "5px",
                        backgroundColor: "#26a4de94",
                      }}
                    ></Typography>
                    <Typography>Meeting</Typography>
                  </Space>
                  |
                  <Space>
                    <Typography
                      style={{
                        width: "20px",
                        height: "5px",
                        backgroundColor: "#265985",
                      }}
                    ></Typography>
                    <Typography> Project</Typography>
                  </Space>
                </Space>
              </BoxCustom>
            </Space>
          </Col>
        </Row>
        {/* React big week calendar */}
        <Container className="height600">
          <DnDCalendar
            defaultDate={defaultDate}
            defaultView={Views.WEEK}
            events={myEvents}
            eventPropGetter={eventPropGetter}
            localizer={momentLocalizer(moment)}
            onSelectEvent={handleSelectEvent}
            onSelectSlot={handleSelectSlot}
            selectable
            scrollToTime={scrollToTime}
            components={{
              // toolbar: CustomToolbar,
              event: CustomEvent,
              // week: {
              //   header: ({ date, localizer }) => localizer.format(date, "dddd"),
              // },
            }}
            formats={formats}
            // draggableAccessor={(event) => true}
            onEventDrop={moveEvent}
            onEventResize={resizeEvent}
            popup
            resizable
            style={{ height: "100vh", width: "100%", backgroundColor: "white" }}
          />
        </Container>
        {selectedSlot && isMeetingModalOpen && (
          <CreateMeetingModal
            isModalOpen={isMeetingModalOpen}
            handleCreateMeeting={handleCreateMeeting}
            handleCreateProject={handleCreateProject}
            handleCancel={handleCancelMeeting}
            listUser={listUser}
            form={form}
            loading={loading}
            setLoading={setLoading}
            selectedSlot={selectedSlot}
          />
        )}

        {openDetailProject && (
          <DetailProject
            openModal={openDetailProject}
            handleClose={handleCloseDetailProject}
            detailData={selectedEvent}
            listUser={listUser}
            form={form}
            fetchListProjectCalendar={fetchListProjectCalendar}
            fetchListMeetingCalendar={fetchListMeetingCalendar}
            loading={loading}
            setLoading={setLoading}
          />
        )}
        {openDetailMeeting && (
          <DetailMeeting
            openModal={openDetailMeeting}
            handleClose={handleCloseDetailMeeting}
            listUser={listUser}
            form={form}
            fetchListProjectCalendar={fetchListProjectCalendar}
            fetchListMeetingCalendar={fetchListMeetingCalendar}
            detailData={selectedEvent}
            loading={loading}
            setLoading={setLoading}
          />
        )}
      </BoxCustom>
    </Spin>
  );
};

const Container = styled.div`
  .rbc-toolbar-label {
    font-weight: 600 !important;
  }
  .rbc-current button {
    background: blue !important;
    color: #fff !important;
    border-radius: 50%;
    height: 20px !important;
    width: 25px !important;
  }

  .rbc-today button {
    background: blue !important;
    color: #fff !important;
    border-radius: 40%;
    height: 20px !important;
    width: 45px !important;
    font-weight: 600;
    padding: 2px;
  }
  .rbc-row-segment .rbc-show-more {
    color: #063964 !important;
  }
`;
Calendar.propTypes = {
  localizer: PropTypes.instanceOf(DateLocalizer),
};
export default Calender;
