import { Button, Form, Spin, Tabs } from "antd";
import styled from "styled-components";
import RequestTab from "./Tabs/Request/RequestTab";
import RequestModal from "./Tabs/Request/modal/RequestModal";
import { useCallback, useEffect, useState } from "react";
import dayjs from "dayjs";
import { addNewRequest, getListClients, getListTimeoff } from "./service";
import { TIME_OFF_MODE } from "../../utils/constants";
import TimeLineTab from "./Tabs/Timeline/TimeLineTab";

const TimeOff = () => {
  const [form] = Form.useForm();
  const [isRequestModalOpen, setIsRequestModalOpen] = useState(false);
  const [listFilePath, setListFilePath] = useState([]);
  const [loading, setLoading] = useState(false);
  const [dataTimeoff, setDataTimeoff] = useState([]);
  const [timeOffMode, setTimeOffMode] = useState(TIME_OFF_MODE.ONE_DAY);
  const [paidValue, setPaidValue] = useState();
  const [selectedEmployee, setSelectedEmployee] = useState();
  const [listUser, setListUser] = useState([]);

  const fetchListDataTimeoff = useCallback(async () => {
    setLoading(true);
    let res = await getListTimeoff().then((res) => {
      return res.data.response;
    });
    setDataTimeoff(res);
    setLoading(false);
    return res;
  }, []);
  const fetchListUser = async () => {
    let res = await getListClients().then((res) => {
      return res.data.response;
    });
    setListUser(res);
    return res;
  };

  useEffect(() => {
    fetchListUser();
  }, []);
  const handleCancel = () => setIsRequestModalOpen(false);
  const handleOpenNewRequest = () => {
    setIsRequestModalOpen(true);
  };
  const handleRequest = (values) => {
    const payload = {
      ...values,
      start_time:
        timeOffMode === TIME_OFF_MODE.ONE_DAY
          ? dayjs(values.start_time).format("YYYY-MM-DD")
          : dayjs(values.start_time[0]).format("YYYY-MM-DD HH:mm:ss"),
      end_time:
        timeOffMode === TIME_OFF_MODE.ONE_DAY
          ? dayjs(values.start_time).format("YYYY-MM-DD")
          : dayjs(values.start_time[1]).format("YYYY-MM-DD HH:mm:ss"),
      documents: listFilePath,
      employeeId: selectedEmployee
    };
    setLoading(true);
    addNewRequest(payload).then((res) => {
      setIsRequestModalOpen(false);
      fetchListDataTimeoff();
      setLoading(false);
      setListFilePath([]);
      setPaidValue();
      form.resetFields();
    });
  };
  return (
    <Spin spinning={loading}>
      <StyledSettingWrapper>
        <Button type="primary" onClick={handleOpenNewRequest}>
          REQUEST TIME OFF
        </Button>
      </StyledSettingWrapper>

      <StyledContainer>
        <Tabs
          defaultActiveKey="1"
          type="card"
          // onChange={onChangeTabs}
          items={[
            {
              label: "REQUESTS",
              key: "1",
              children: (
                <RequestTab
                  loading={loading}
                  setLoading={setLoading}
                  dataTimeoff={dataTimeoff}
                  listFilePath={listFilePath}
                  setListFilePath={setListFilePath}
                  fetchListDataTimeoff={fetchListDataTimeoff}
                  timeOffMode={timeOffMode}
                  setTimeOffMode={setTimeOffMode}
                  paidValue={paidValue}
                  setPaidValue={setPaidValue}
                />
              ),
            },
            {
              label: "TIMELINE",
              key: "2",
              children: <TimeLineTab />,
            },
            {
              label: "BALANCE",
              key: "3",
              // children: <RequestTab listStartTime={listSelectData} />,
            },
          ]}
        />
      </StyledContainer>
      <RequestModal
        isModalOpen={isRequestModalOpen}
        handleRequest={handleRequest}
        handleCancel={handleCancel}
        setListFilePath={setListFilePath}
        loading={loading}
        setLoading={setLoading}
        timeOffMode={timeOffMode}
        setTimeOffMode={setTimeOffMode}
        form={form}
        paidValue={paidValue}
        setPaidValue={setPaidValue}
        setSelectedEmployee={setSelectedEmployee}
        listUser={listUser}
      />
    </Spin>
  );
};

const StyledSettingWrapper = styled.div`
  margin: 10px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 5px;
`;
const StyledContainer = styled.div``;
export default TimeOff;
