import { Col, Form, Row } from "antd";
import dayjs from "dayjs";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { RangePickerTKG, InputTKG } from "tkg-composite-ui";
import { SelectTKG } from "tkg-composite-ui/dist/components/Select/SelectTKG";
import { getListClients } from "./service";

const ListFilter = (props) => {
  const { filterConditions, setFilterConditions, form, filterOptions, setFilterOptions, checkClassic } = props;
  const user = JSON.parse(sessionStorage.getItem("user"));
  const [listUser, setListUser] = useState([]);

  const fetchListUser = async () => {
    let res = await getListClients().then((res) => {
      return res.data.response;
    });
    setListUser(res);
    return res;
  };

  useEffect(() => {
    fetchListUser();
  }, []);
  const onChangeDate = (date) => {
    if (date.length > 0) {
      setFilterConditions((prev) => ({
        ...prev,
        start_date: moment(new Date(date[0])).format("YYYY-MM-DD"),
        end_date: moment(new Date(date[1])).format("YYYY-MM-DD"),
      }));
    }
  };
  const onSelectUserChange = (value) => {
    setFilterConditions((prev) => ({ ...prev, user_id: value }));
  };
  return (
    <Form
      form={form}
      layout="vertical"
      initialValues={{
        filter_date: [
          dayjs(new Date(filterConditions.start_date)),
          dayjs(new Date(filterConditions.end_date)),
        ],
      }}
    >
      <Row gutter={12}>
        {user.role_id === 1 ? (
          <Col span={6}>
            <Form.Item name="User" style={{ marginBottom: 10 }}>
              <SelectTKG
                form={form}
                onChange={onSelectUserChange}
                allowClear
                placeholder="Show All User"
                showSearch
                optionFilterProp="children"
                filterOption={(input, option) =>
                  (option?.label ?? "")
                    .toLowerCase()
                    .includes(input.toLowerCase())
                }
                options={listUser.map((item) => ({
                  label: `${item?.first_name} ${item?.surname} ( ${item?.group} - ${item?.position} ) `,
                  value: item.id,
                }))}
              />
            </Form.Item>
          </Col>
        ) : null}
                <Col span={7}>
        
        <Form.Item name="title">
            <InputTKG name={'title'} placeholder="Search" form={form} value={filterOptions?.title} onChange={e => setFilterOptions((prev) => ({ ...prev, title: e?.target?.value }))} />
        </Form.Item>
        </Col>
        {checkClassic && <Col span={5}>
            <Form.Item name="Status" style={{ marginBottom: 10 }}>
              <SelectTKG
                form={form}
                onChange={value => setFilterOptions((prev) => ({ ...prev, status: value }))}
                allowClear
                placeholder="Show All Status"
                showSearch
                optionFilterProp="children"
                filterOption={(input, option) =>
                  (option?.label ?? "")
                    .toLowerCase()
                    .includes(input.toLowerCase())
                }
                options={[
                  {
                  label: "PENDING",
                  value: "pending"}, 
                  {
                  label: "IN PROCESS",
                  value: "ongoing"}, 
                  {
                  label: "COMPLETED",
                  value: "completed"}, 
                ]}
              />
            </Form.Item>
          </Col>}
          <Col span={5}>
            <Form.Item name="Priority" style={{ marginBottom: 10 }}>
              <SelectTKG
                form={form}
                onChange={value => setFilterOptions((prev) => ({ ...prev, priority: value }))}
                allowClear
                placeholder="Show All Priority"
                showSearch
                optionFilterProp="children"
                filterOption={(input, option) =>
                  (option?.label ?? "")
                    .toLowerCase()
                    .includes(input.toLowerCase())
                }
                options={[
                  {
                  label: "HIGH",
                  value: "high"}, 
                  {
                  label: "MEDIUM",
                  value: "medium"}, 
                  {
                  label: "LOW",
                  value: "low"}, 
                ]}
              />
            </Form.Item>
          </Col>

        <Col span={6}>
          <Form.Item name="filter_date" style={{ marginBottom: 10 }}>
            <RangePickerTKG form={form} onChange={onChangeDate} allowClear={false} />
          </Form.Item>
        </Col>
      </Row>
    </Form>
  );
};

export default ListFilter;
