import { Button, Col, Form, Row, TimePicker } from "antd";
import dayjs from "dayjs";
import React, { useEffect, useState } from "react";
import Swal from "sweetalert2";
import { ModalFormTKG, DatePickerTKG, TextEditorTKG } from "tkg-composite-ui";
import BoxCustom from "../../../Components/BoxCustom";
import { updateRequestWorkHours } from "../service";

const UpdateRequestWorkModal = (props) => {
  const {
    open,
    setOpenUpdateRequestModal,
    onClose,
    fetchListRequest,
    selectedRequest,
  } = props;
  const [form] = Form.useForm();

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    form.setFieldsValue({
      date: dayjs(selectedRequest?.date),
      start_time: dayjs(selectedRequest?.start_time),
      end_time: dayjs(selectedRequest?.end_time),
      reason: selectedRequest?.reason,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedRequest]);

  const handleUpdateRequest = async (values) => {
    const payload = {
      ...values,
      date: dayjs(values.date).format("YYYY-MM-DD"),
      start_time: `${dayjs(values.date).format("YYYY-MM-DD")} ${dayjs(
        values.start_time
      ).format("HH:mm:ss")}`,
      end_time: `${dayjs(values.date).format("YYYY-MM-DD")} ${dayjs(
        values.end_time
      ).format("HH:mm:ss")}`,
    };

    setLoading(true);
    updateRequestWorkHours(payload, selectedRequest.id).then((res) => {
      setLoading(false);
      Swal.fire("Update Request Successfully!", "", "success");
      fetchListRequest();
      setOpenUpdateRequestModal(false);
      form.resetFields();
    });
  };
  return (
    <ModalFormTKG
      title="Update Request WorkHours"
      onClose={onClose}
      open={open}
      footer={false}
    >
      <BoxCustom>
        <Form
          form={form}
          layout="vertical"
          onFinish={handleUpdateRequest}
          initialValues={{
            date: dayjs(selectedRequest?.date),
            reason: selectedRequest?.reason,
            start_time: dayjs(selectedRequest?.start_time),
            end_time: dayjs(selectedRequest?.end_time),
          }}
        >
          <Row gutter={[16, 16]}>
            <Col span={8}>
              <Form.Item
                name="date"
                label="Date"
                rules={[
                  { required: true, message: "Please choose your time!" },
                ]}
              >
                <DatePickerTKG form={form} />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item
                name="start_time"
                label="Clock in"
                rules={[
                  { required: true, message: "Please choose your time!" },
                ]}
              >
                <TimePicker
                  size="large"
                  format="HH:mm"
                  style={{ width: "100%" }}
                />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item
                name="end_time"
                label="Clock out"
                rules={[
                  { required: true, message: "Please choose your time!" },
                ]}
              >
                <TimePicker
                  size="large"
                  format="HH:mm"
                  style={{ width: "100%" }}
                />
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item
                name="reason"
                label="Reason"
                rules={[
                  { required: true, message: "Please choose your date!" },
                ]}
              >
                <TextEditorTKG form={form} />
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={[4, 4]}>
            <Col span={24} style={{ textAlign: "right" }}>
              <Button
                loading={loading}
                type="primary"
                htmlType="submit"
                className="btnTKGAction"
              >
                Save
              </Button>
            </Col>
          </Row>
        </Form>
      </BoxCustom>
    </ModalFormTKG>
  );
};

export default UpdateRequestWorkModal;
