import styled from "styled-components";

export const StyledMainContent = styled.div`
  padding: 10px 10px 0 5px;
  /* .ant-table-cell {
    padding: 5px !important;
  } */
`;

export const StyledHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 10px;
  margin-top: 20px;
`;
export const StyledTitle = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 10px;
`;
export const StyledUser = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-color: #ccc;
  color: #fff;
  font-weight: 600;
  font-size: 20px;
`;

export const StyledNewProject = styled.div`
  background-color: #154d76;
  color: #fff;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 52px;
  box-shadow: 0 1px 2px 0 rgb(0 0 0 / 3%), 0 1px 6px -1px rgb(0 0 0 / 2%),
    0 2px 4px 0 rgb(0 0 0 / 2%);
  border-radius: 10px;
  padding: 0 24px;
`;
export const StyledProjectWrapper = styled.div`
  margin-top: 10px;
  .ant-card {
    &.ant-card-bordered {
      min-height: 50px;
      margin: 10px 0px;
      background: transparent;
      border: none;
    }
  }
`;

export const StyleContainer = styled.div`
.ant-table-thead > tr > td {
  background: #e3e6ef;
}
`