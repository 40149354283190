import styled from "styled-components";
import { Table, Form, Row, Col, Spin, Space } from "antd";
import React, { useCallback, useEffect, useState } from "react";
import TableInputComponent from "./TableInputComponent";
import Text from "antd/lib/typography/Text";
import { FileDoneOutlined, MoreOutlined } from "@ant-design/icons";
import dayjs from "dayjs";
import { getDateRangeOfWeek, formatHours } from "../../utils/functions";
import { SelectTKG, DatePickerTKG } from "tkg-composite-ui";
import moment from "moment";
import { getStringDay } from "../time-tracker/function";
import {
  deleteNoteTimeTracker,
  getListClients,
  getTimeSheetData,
  getTotalClockInOut,
  postNoteTimeTracker,
  updateNoteTimeTracker,
} from "./service";
import ModalNote from "./model/ModalCreateNote";
import Swal from "sweetalert2";
import ModalUpdateNote from "./model/ModalUpdateNote";

const handleSum = (record) => {
  const values = Object.values(record).filter(
    (item) => typeof item === "number"
  );
  return values.reduce((total, currentElement) => total + currentElement, 0);
};
const TimeSheet = () => {
  const user = JSON.parse(sessionStorage.getItem("user"));
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [userId, setUserId] = useState(user.id);
  const [dataTable, setDataTable] = useState([]);
  const [listDateColumn, setListDateColumn] = useState([]);
  const [listUser, setListUser] = useState([]);
  const [dataClockIn, setDataClockIn] = useState([]);
  const [noteTimeTracker, setNoteTimeTracker] = useState({});
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isOpenUpdateNoteModal, setIsOpenUpdateNoteModal] = useState(false);
  const [startWeek, endWeek] = getDateRangeOfWeek({
    weekNo: moment().week(),
    year: moment().year(),
  });

  useEffect(() => {
    if (isModalOpen) {
      form.resetFields();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isModalOpen]);

  useEffect(() => {
    const weekDay = dayjs(getStringDay(endWeek)).diff(
      dayjs(getStringDay(startWeek)),
      "day"
    );
    const arrDay = [];
    for (let i = 0; i <= weekDay; i++) {
      arrDay.push(
        dayjs(getStringDay(startWeek)).add(i, "day").format("DD-MMM-YYYY")
      );
    }
    setListDateColumn(arrDay);
  }, []);

  const [filterConditions, setFilterConditions] = useState({
    start_date: getStringDay(startWeek),
    end_date: getStringDay(endWeek),
  });
  const fetchListUser = async () => {
    let res = await getListClients().then((res) => {
      return res.data.response;
    });
    setListUser(res);
    return res;
  };
  const fetchTotalClockIn = async () => {
    const payload = {
      from: filterConditions.start_date,
      to: filterConditions.end_date,
    };
    let res = await getTotalClockInOut(payload, userId).then((res) => {
      return res.data.response;
    });
    setDataClockIn(res);
    return res;
  };
  const fetchListData = useCallback(async () => {
    setLoading(true);
    const payload = {
      from: filterConditions.start_date,
      to: filterConditions.end_date,
    };

    let res = await getTimeSheetData(payload, userId).then((res) => {
      setLoading(false);
      return res.data.response;
    });
    const dataFormat = [];

    for (const [key, value] of Object.entries(res)) {
      if (Object.keys(value).length > 0) {
        dataFormat.push({ project: key, ...value });
      }
    }

    setDataTable(dataFormat);
    return res;
  }, [filterConditions, userId]);

  useEffect(() => {
    fetchTotalClockIn();
    fetchListData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fetchListData, filterConditions, userId]);
  useEffect(() => {
    fetchListUser();
  }, []);

  const handleCancel = () => setIsModalOpen(false);
  const handleCloseUpdateNoteModal = () => setIsOpenUpdateNoteModal(false);
  const handleTakeNote = (values) => {
    setLoading(true);
    const payload = {
      ...noteTimeTracker,
      ...values,
    };
    postNoteTimeTracker(payload).then((res) => {
      setLoading(false);
      setIsModalOpen(false);
      fetchListData();
    });
  };
  const handleUpdateNote = (values) => {
    setLoading(true);
    const payload = {
      ...noteTimeTracker,
      note: values.note,
    };
    updateNoteTimeTracker(payload, noteTimeTracker.id).then((res) => {
      setIsOpenUpdateNoteModal(false);
      fetchListData();
      setLoading(false);
      Swal.fire({
        position: "top-end",
        icon: "success",
        title: "Your work has been saved",
        showConfirmButton: false,
        timer: 1000,
        timerProgressBar: true,
      });
    });
  };
  const handleDeleteNote = () => {
    Swal.fire({
      title: "Are you sure to delete this project?",
      text: "You won't be able to revert this!",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Confirm",
    }).then((result) => {
      if (result.isConfirmed) {
        setLoading(true);

        deleteNoteTimeTracker(noteTimeTracker.id).then((res) => {
          setIsOpenUpdateNoteModal(false);
          fetchListData();
          setLoading(false);
          Swal.fire({
            position: "top-end",
            icon: "success",
            title: "Your work has been saved",
            showConfirmButton: false,
            timer: 1000,
            timerProgressBar: true,
          });
        });
      }
    });
  };
  const onChangeDate = (date) => {
    const [startWeek, endWeek] = getDateRangeOfWeek({
      weekNo: date.week(),
      year: date.year(),
    });
    setFilterConditions((prev) => ({
      ...prev,
      start_date: getStringDay(startWeek),
      end_date: getStringDay(endWeek),
    }));

    const weekDay = dayjs(getStringDay(endWeek)).diff(
      dayjs(getStringDay(startWeek)),
      "day"
    );
    const arrDay = [];
    for (let i = 0; i <= weekDay; i++) {
      arrDay.push(
        dayjs(getStringDay(startWeek)).add(i, "day").format("DD-MMM-YYYY")
      );
    }
    setListDateColumn(arrDay);
  };

  const onSelectChange = (value) => {
    setUserId(value);
  };

  const dateColumn = listDateColumn.map((item) => {
    return {
      title: item,
      dataIndex: item,
      key: item,
      render: (text, record, index) => {
        return (
          <Space>
            <div>{text ? formatHours(Number(text || 0)) : null}</div>
            <div style={{ position: "absolute", right: "10px", top: "30%" }}>
              {Object.keys(record.notes).includes(item) ? (
                <FileDoneOutlined
                  style={{ color: "#154d76" }}
                  onClick={() => {
                    for (const [key, value] of Object.entries(record.notes)) {
                      if (key === item) {
                        setNoteTimeTracker({
                          id: value.id,
                          date: moment(item).format("YYYY-MM-DD"),
                          note: value.note,
                        });
                        setIsOpenUpdateNoteModal(true);
                      }
                    }
                  }}
                />
              ) : (
                <MoreOutlined
                  onClick={() => {
                    setNoteTimeTracker({
                      time_tracker_id: record.id,
                      date: moment(item).format("YYYY-MM-DD"),
                    });
                    setIsModalOpen(true);
                  }}
                />
              )}
            </div>
          </Space>
        );
      },
    };
  });

  const columns = [
    {
      title: "Projects",
      dataIndex: "project",
      key: "project",
      fixed: "left",
      width: "30%",
    },
    dateColumn,
    {
      title: "Total",
      dataIndex: "total",
      key: "total",
      fixed: "right",
      width: "130px",
      render: (_, record) => (
        <div>{formatHours(Number(handleSum(record)))}</div>
      ),
    },
  ];

  const newColumn = columns.flatMap((col) => col);

  //footer total count table
  const countTotals = (pageData) => {
    let sumOfTotal = 0;
    let sumOfTotalClockIn = 0;
    let mergedObj = {};
    let mergedObjClockIn = {};

    listDateColumn.forEach((item) => {
      mergedObj[item] = 0;
      mergedObjClockIn[item] = 0;
    });
    if (pageData.length > 0) {
      pageData.forEach((item, index) => {
        Object.keys(item).forEach((el) => {
          if (el !== "project" && el !== "id" && el !== "notes") {
            if (mergedObj[el] !== undefined) {
              mergedObj[el] += item[el];
            }
          }
        });
      });
    }

    if (dataClockIn.length > 0) {
      dataClockIn.forEach((item) => {
        Object.keys(item).forEach((el) => {
          if (el !== "project" && el !== "id") {
            mergedObjClockIn[el] += item[el];
          }
        });
      });
    }

    sumOfTotal = Object.values(mergedObj).reduce(
      (total, currentElement) => (total += currentElement),
      0
    );
    sumOfTotalClockIn = Object.values(mergedObjClockIn).reduce(
      (total, currentElement) => (total += currentElement),
      0
    );
    return (
      pageData.length > 0 && (
        <Table.Summary fixed>
          <Table.Summary.Row style={{ backgroundColor: "#FFEAEA" }}>
            <Table.Summary.Cell index={0}>
              <Text className="font-bold">Total:</Text>
            </Table.Summary.Cell>
            {Object.values(mergedObj).map((item, index) => (
              <Table.Summary.Cell index={index + 1}>
                <Text className="font-bold">{formatHours(Number(item))}</Text>
              </Table.Summary.Cell>
            ))}
            <Table.Summary.Cell index={1110}>
              <Text className="font-bold">
                {formatHours(Number(sumOfTotal))}
              </Text>
            </Table.Summary.Cell>
          </Table.Summary.Row>
          <Table.Summary.Row>
            <Table.Summary.Cell index={0}>
              <Text className="font-bold">Clock in-out:</Text>
            </Table.Summary.Cell>
            {Object.values(mergedObjClockIn).map((item, index) => (
              <Table.Summary.Cell index={index + 1}>
                <Text className="font-bold">{formatHours(Number(item))}</Text>
              </Table.Summary.Cell>
            ))}
            <Table.Summary.Cell index={1110}>
              <Text className="font-bold">
                {formatHours(Number(sumOfTotalClockIn))}
              </Text>
            </Table.Summary.Cell>
          </Table.Summary.Row>
        </Table.Summary>
      )
    );
  };

  return (
    <Spin spinning={loading}>
      <StyledContainer>
        <StyledHeader>
          <Form
            style={{ width: "100%" }}
            form={form}
            layout="vertical"
            initialValues={{
              filter_date: dayjs(new Date()),
            }}
          >
            <Row gutter={4}>
              {user?.role_id !== 1 ? null : (
                <Col span={4}>
                  <SelectTKG
                    form={form}
                    onChange={onSelectChange}
                    // placeholder="Select tags"
                    defaultValue={`${user.first_name} ${user.surname}`}
                    showSearch
                    optionFilterProp="children"
                    filterOption={(input, option) =>
                      (option?.label ?? "")
                        .toLowerCase()
                        .includes(input.toLowerCase())
                    }
                    options={listUser.map((item) => ({
                      label: `${item.first_name} ${item.surname}`,
                      value: item.id,
                    }))}
                  />
                </Col>
              )}
              <Col span={4}>
                <Form.Item name="filter_date" style={{ marginBottom: 0 }}>
                  <DatePickerTKG
                    form={form}
                    onChange={onChangeDate}
                    picker="week"
                  />
                </Form.Item>
              </Col>
            </Row>
          </Form>
        </StyledHeader>
        <TableInputComponent
          bordered={true}
          pagination={false}
          columns={newColumn}
          dataSource={dataTable}
          scroll={{
            y: 500,
            x: 1000,
          }}
          summary={(pageData) => countTotals(pageData)}
        />
      </StyledContainer>
      <ModalNote
        isModalOpen={isModalOpen}
        handleTakeNote={handleTakeNote}
        handleCancel={handleCancel}
        form={form}
        loading={loading}
      />

      <ModalUpdateNote
        isModalOpen={isOpenUpdateNoteModal}
        handleUpdateNote={handleUpdateNote}
        handleDeleteNote={handleDeleteNote}
        handleCancel={handleCloseUpdateNoteModal}
        form={form}
        loading={loading}
        noteTimeTracker={noteTimeTracker}
      />
    </Spin>
  );
};

const StyledContainer = styled.div`
  margin-top: 10px;
  .ant-table-wrapper .ant-table-cell-fix-left,
  .ant-table-wrapper .ant-table-cell-fix-right {
    z-index: 0;
  }
  .ant-table-cell {
    position: relative;
  }
`;
const StyledHeader = styled.div`
  width: 100%;
  margin-bottom: 10px;
  display: flex;
  align-items: center;
`;

export default TimeSheet;
