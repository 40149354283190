import { Endpoint } from "../../utils/endpoind";
import { buildQueryString } from "../../utils/functions";
import { getData } from "../../utils/request";

export const getListClients = async () => {
  return await getData({ url: `${Endpoint.GET_LIST_USER}` });
};
export const getListTimeoff = async (filterCondition) => {
  const query = buildQueryString(filterCondition);
  return await getData({ url: `${Endpoint.LIST_TIMEOFF}?${query}` });
};
export const getDataDashboard = async (payload) => {
  const query = buildQueryString(payload);
  return await getData({
    url: `${Endpoint.GET_DATA_DASHBOARD}?${query}`,
  });
};
