import moment from "moment";

export const getTotalTimer = (data) => {
  let dataClone = data.map((item) => item);
  let totalTime = 0;
  if (dataClone.length > 0) {
    if (!dataClone?.findLast((item) => item)?.end_time) {
      const lastTimeTracker = dataClone?.findLast((item) => item);
      lastTimeTracker.end_time_new = lastTimeTracker?.start_time;
    } else {
      const lastTimeTracker = dataClone?.findLast((item) => item);
      lastTimeTracker.end_time_new = dataClone?.findLast(
        (item) => item
      )?.end_time;
    }
  } else {
    return totalTime;
  }

  if (dataClone.length > 0) {
    dataClone.forEach((current) => {
      totalTime += moment(current?.end_time_new).diff(
        moment(current?.start_time),
        "seconds"
      );
    });
    return totalTime;
  }
  return totalTime;
};

export const getDayNameWork = (day) => {
  return moment().isSame(day, "day")
    ? "Today"
    : moment().subtract(1, "day").isSame(day, "day")
    ? "Yesterday"
    : moment(day).format("ddd, MMM YY");
};

export const getStringDay = (date) => {
  return moment(date).format("YYYY-MM-DD");
};
