import React from "react";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import { Pie } from "react-chartjs-2";

ChartJS.register(ArcElement, Tooltip, Legend);

export function ClockInOutPieChart(props) {
  const { dataDashBoard } = props;
  const ClockIn = dataDashBoard[2]?.half
    ? Math.round((dataDashBoard[2]?.half / dataDashBoard[2]?.total) * 100)
    : 0;
  const ClockInOut = dataDashBoard[2]?.full
    ? Math.round((dataDashBoard[2]?.full / dataDashBoard[2]?.total) * 100)
    : 0;
  const NotClock = 100 - ClockIn - ClockInOut;
  const data = {
    labels: ["Clock in", "Clock in-out", "Not clock"],
    datasets: [
      {
        label: "Percentage",
        data: [ClockIn, ClockInOut, NotClock],
        // data: [60, 30, 10],
        backgroundColor: [
          "rgba(255, 206, 86, 0.2)",
          "rgba(54, 162, 235, 0.2)",
          "rgba(255, 99, 132, 0.2)",
        ],
        borderColor: [
          "rgba(255, 206, 86, 1)",
          "rgba(54, 162, 235, 1)",
          "rgba(255, 99, 132, 1)",
        ],
        borderWidth: 1,
      },
    ],
  };
  return <Pie height={"300px"} data={data} />;
}
