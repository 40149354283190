// import { BASE_API_URL_LO_TRINH_NHAN_VIEN } from "@utils/constants";

const BASE_API_URL_TIME_TRACKER = process.env.REACT_APP_TIME_REPORT_API_URL;
export const API_TIME_TRACKER = {
  STORE: `${BASE_API_URL_TIME_TRACKER}/api/time-tracker/store`,
  LIST_TIME_TRACKER: `${BASE_API_URL_TIME_TRACKER}/api/time-tracker`,
  RUNNING_FRAGMENT: `${BASE_API_URL_TIME_TRACKER}/api/time-tracker/fragment/running`,
  DELETE_TASK: `${BASE_API_URL_TIME_TRACKER}/api/time-tracker/delete`,
  DELETE_FRAGMENT_TASK: `${BASE_API_URL_TIME_TRACKER}/api/time-tracker/fragment/delete`,
  UPDATE_TASK: `${BASE_API_URL_TIME_TRACKER}/api/time-tracker/update`,
  FINISH_TASK: `${BASE_API_URL_TIME_TRACKER}/api/time-tracker/finish`,

  // Activity
  ACTIVITY_STORE: `${BASE_API_URL_TIME_TRACKER}/api/time-tracker/activity/store`,
  ACTIVITY_RUNNING: `${BASE_API_URL_TIME_TRACKER}/api/time-tracker/activity/running`,
  ACTIVITY_DELETE: `${BASE_API_URL_TIME_TRACKER}/api/time-tracker/activity/delete`,
  ACTIVITY_START: `${BASE_API_URL_TIME_TRACKER}/api/time-tracker/activity/start`,
  ACTIVITY_PAUSE: `${BASE_API_URL_TIME_TRACKER}/api/time-tracker/activity/pause`,
  ACTIVITY_STOP: `${BASE_API_URL_TIME_TRACKER}/api/time-tracker/activity/stop`,

  // Timer
  START_TIME_TRACKER: `${BASE_API_URL_TIME_TRACKER}/api/time-tracker/start`,
  STOP_TIME_TRACKER: `${BASE_API_URL_TIME_TRACKER}/api/time-tracker/stop`,

  CREATE_NOTE_TIME_TRACKER: `${BASE_API_URL_TIME_TRACKER}/api/time-tracker/note/store`,
  UPDATE_NOTE_TIME_TRACKER: `${BASE_API_URL_TIME_TRACKER}/api/time-tracker/note/update`,
  DELETE_NOTE_TIME_TRACKER: `${BASE_API_URL_TIME_TRACKER}/api/time-tracker/note/delete`,
};
