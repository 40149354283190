import { Endpoint } from "../../utils/endpoind";
import {
  deleteData,
  getData,
  postData,
  postFile,
  putData,
} from "../../utils/request";

export const uploadFile = async (payload) => {
  return await postFile({
    url: `${Endpoint.UPLOAD_FILE}`,
    payload,
  });
};

export const getListClients = async () => {
  return await getData({ url: `${Endpoint.GET_LIST_USER}` });
};
export const getRulesPayoff = async () => {
  return await getData({ url: `${Endpoint.GET_RULES_PAYOFF}` });
};
export const getListTimeoff = async () => {
  return await getData({ url: `${Endpoint.LIST_TIMEOFF}` });
};
export const addNewRequest = async (payload) => {
  return await postData({ url: `${Endpoint.ADD_NEW_REQUEST}`, payload });
};
export const updateTimeoffRequest = async (payload, id) => {
  return await putData({ url: `${Endpoint.UPDATE_REQUEST}/${id}`, payload });
};
export const deleteTimeoffRequest = async (id) => {
  return await deleteData({ url: `${Endpoint.DELETE_REQUEST}/${id}` });
};
export const responseRequest = async (payload, id) => {
  return await postData({ url: `${Endpoint.RESPONSE_REQUEST}/${id}`, payload });
};
export const getDetailRequest = async (id) => {
  return await deleteData({ url: `${Endpoint.DELETE_REQUEST}/${id}` });
};
