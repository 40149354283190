import React from "react";
import styled from "styled-components";

const BoxCustom = (props) => {
  return <StyledCustom>{props.children}</StyledCustom>;
};

const StyledCustom = styled.div`
  width: 100%;
  background: #fff;
  box-shadow: 0 1px 2px 0 rgb(0 0 0 / 3%), 0 1px 6px -1px rgb(0 0 0 / 2%),
    0 2px 4px 0 rgb(0 0 0 / 2%);
  border-radius: 10px;
  padding: 10px;
`;
export default BoxCustom;
