import * as ACTION_TYPES from "../actions/actionType";

export const showModal = () => {
  return {
    type: ACTION_TYPES.SHOWMODAL,
  };
};

export const hideModal = () => {
  return {
    type: ACTION_TYPES.HIDEMODAL,
  };
};
export const showRequestModal = () => {
  return {
    type: ACTION_TYPES.SHOWREQUESTMODAL,
  };
};

export const hideRequestModal = () => {
  return {
    type: ACTION_TYPES.HIDEREQUESTMODAL,
  };
};
export const showListRequestModal = () => {
  return {
    type: ACTION_TYPES.SHOWLISTREQUESTMODAL,
  };
};

export const hideListRequestModal = () => {
  return {
    type: ACTION_TYPES.HIDELISTREQUESTMODAL,
  };
};
