import React, { useEffect, useState } from "react";
import BoxCustom from "../../Components/BoxCustom";
import { ClockInOutPieChart } from "./ClockInOutPieChart";
import { TimeoffRequestChart } from "./TimeoffRequesttChart";
import { Row, Col, Form, Spin } from "antd";
import { SelectTKG, RangePickerTKG } from "tkg-composite-ui";
import dayjs from "dayjs";
import { getDataDashboard, getListClients } from "./service";
import { ClockInOutRequestChart } from "./ClockInOutRequestChart";
import CalendarTimeoff from "./CalendarTimeoff";
const DashBoard = () => {
  const [form] = Form.useForm();
  const user = JSON.parse(sessionStorage.getItem("user"));
  const [loading, setLoading] = useState(false);
  const [filterConditions, setFilterConditions] = useState({
    start: dayjs().startOf("month").format("YYYY-MM-DD"),
    end: dayjs().endOf("month").format("YYYY-MM-DD"),
    user_id: user.id,
  });

  const [listUser, setListUser] = useState([]);
  const [dataDashBoard, setDataDashBoard] = useState([]);

  const fetchListUser = async () => {
    let res = await getListClients().then((res) => {
      return res.data.response;
    });
    setListUser(res);
    return res;
  };
  const fetchDataDashboard = async () => {
    let res = await getDataDashboard(filterConditions).then((res) => {
      return res.data.response;
    });
    setDataDashBoard(res);
    return res;
  };

  useEffect(() => {
    fetchDataDashboard();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterConditions]);
  useEffect(() => {
    fetchListUser();
  }, []);
  const onSelectUserChange = (value) => {
    setFilterConditions((prev) => ({ ...prev, user_id: value }));
  };
  const onChangeDate = (date) => {
    if (date.length > 0) {
      setFilterConditions((prev) => ({
        ...prev,
        start: dayjs(new Date(date[0])).startOf("month").format("YYYY-MM-DD"),
        end: dayjs(new Date(date[1])).endOf("month").format("YYYY-MM-DD"),
      }));
    }
  };

  //

  return (
    <Spin spinning={loading}>
      <BoxCustom>
        <Form
          style={{ marginBottom: "30px" }}
          form={form}
          layout="vertical"
          initialValues={{
            filter_date: [
              dayjs(new Date(filterConditions.start)),
              dayjs(new Date(filterConditions.end)),
            ],
          }}
        >
          <Row gutter={[12, 8]}>
            <Col span={6}>
              <Form.Item name="filter_date" style={{ marginBottom: 0 }}>
                <RangePickerTKG
                  form={form}
                  onChange={onChangeDate}
                  picker="month"
                  format="MMM-YYYY"
                />
              </Form.Item>
            </Col>
            {user.role_id === 1 ? (
              <Col span={6}>
                <Form.Item name="User">
                  <SelectTKG
                    form={form}
                    onChange={onSelectUserChange}
                    // placeholder="Select tags"
                    defaultValue={`${user?.first_name} ${user?.surname} ( ${user?.group} - ${user?.position} ) `}
                    showSearch
                    optionFilterProp="children"
                    filterOption={(input, option) =>
                      (option?.label ?? "")
                        .toLowerCase()
                        .includes(input.toLowerCase())
                    }
                    options={
                      user.role_id === 1
                        ? listUser.map((item) => ({
                            label: `${item?.first_name} ${item?.surname} ( ${item?.group} - ${item?.position} ) `,
                            value: item.id,
                          }))
                        : [user]
                    }
                  />
                </Form.Item>
              </Col>
            ) : null}
          </Row>
        </Form>

        <Row
          gutter={[8, 8]}
          style={{ margin: "50px 20px" }}
          justify="space-between"
        >
          {dataDashBoard && (
            <Col span={6}>
              <ClockInOutPieChart
                dataDashBoard={dataDashBoard}
                filterConditions={filterConditions}
                setLoading={setLoading}
              />
            </Col>
          )}
          {dataDashBoard && (
            <Col span={9}>
              <ClockInOutRequestChart
                dataDashBoard={dataDashBoard}
                filterConditions={filterConditions}
                setLoading={setLoading}
              />
            </Col>
          )}
          {dataDashBoard && (
            <Col span={9}>
              <TimeoffRequestChart
                dataDashBoard={dataDashBoard}
                filterConditions={filterConditions}
                setLoading={setLoading}
              />
            </Col>
          )}
          {dataDashBoard && (
            <Col span={24}>
              <CalendarTimeoff />
            </Col>
          )}
        </Row>
      </BoxCustom>
    </Spin>
  );
};

export default DashBoard;
