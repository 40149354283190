import { Badge, Descriptions, Tag } from "antd";
import dayjs from "dayjs";
import React from "react";
import { ModalFormTKG } from "tkg-composite-ui";
import BoxCustom from "../../../Components/BoxCustom";
import { REQUEST_WORKOUT_STATUS } from "../../../utils/constants";
const DetailRequestWorkHour = (props) => {
  const { openModal, handleClose, detailData } = props;

  return (
    <ModalFormTKG title="Detail Request" onClose={handleClose} open={openModal}>
      <BoxCustom>
        <Descriptions title="Requested Info" bordered>
          <Descriptions.Item label="Employee">
            <Tag>
              {detailData?.user.first_name} {detailData?.user.surname}
            </Tag>
          </Descriptions.Item>
          <Descriptions.Item label="Email">
            {detailData?.user.email}
          </Descriptions.Item>
          {/* <Descriptions.Item label="Automatic Renewal">YES</Descriptions.Item> */}
          <Descriptions.Item label="Order time">
            {dayjs(detailData.start_time).format("HH:mm")} -{" "}
            {dayjs(detailData.end_time).format("HH:mm")}{" "}
            {dayjs(detailData.date).format("DD-MMM-YYYY")}
          </Descriptions.Item>
          <Descriptions.Item label="Reason" span={3}>
            <div
              className="view-content"
              dangerouslySetInnerHTML={{
                __html: detailData?.reason,
              }}
            ></div>
          </Descriptions.Item>
          <Descriptions.Item label="Status" span={3}>
            <Badge
              status={
                detailData.status === REQUEST_WORKOUT_STATUS.PENDING
                  ? "warning"
                  : detailData.status === REQUEST_WORKOUT_STATUS.ACCEPTED
                  ? "success"
                  : "error"
              }
              text={
                detailData.status === REQUEST_WORKOUT_STATUS.PENDING ? (
                  <Tag color={"warning"} key={"Pending"}>
                    Pending
                  </Tag>
                ) : detailData.status === REQUEST_WORKOUT_STATUS.ACCEPTED ? (
                  <>
                    <Tag color={"success"} key={"Accepted"}>
                      Accepted
                    </Tag>

                    <span>
                      {" By "} {detailData?.approver?.first_name}{" "}
                      {detailData?.approver?.surname}
                    </span>
                  </>
                ) : (
                  <>
                    {" "}
                    <Tag color={"error"} key={"Rejected"}>
                      Rejected
                    </Tag>
                    <span>
                      {" By "} {detailData?.approver?.first_name}{" "}
                      {detailData?.approver?.surname}
                    </span>
                  </>
                )
              }
            />
          </Descriptions.Item>
          {detailData.status === REQUEST_WORKOUT_STATUS.REJECTED ? (
            <Descriptions.Item label="Reason reject" span={3}>
              <div
                className="view-content"
                dangerouslySetInnerHTML={{
                  __html: detailData?.reason_reject,
                }}
              ></div>
            </Descriptions.Item>
          ) : null}
          {/* <Descriptions.Item label="Negotiated Amount">
            $80.00
          </Descriptions.Item>
          <Descriptions.Item label="Discount">$20.00</Descriptions.Item>
          <Descriptions.Item label="Official Receipts">
            $60.00
          </Descriptions.Item> */}
        </Descriptions>
      </BoxCustom>
    </ModalFormTKG>
  );
};

export default DetailRequestWorkHour;
