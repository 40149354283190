import React from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Filler,
  Legend,
} from "chart.js";
import { Line } from "react-chartjs-2";
import faker from "faker";
import { getListMonths } from "../../utils/functions";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Filler,
  Legend
);

export const options = {
  responsive: true,
  plugins: {
    legend: {
      position: "top",
    },
    title: {
      display: true,
      text: "Number Of Clock in-out Requests Accepted In Months",
    },
  },
};

export function ClockInOutRequestChart(props) {
  const { filterConditions, dataDashBoard } = props;

  const labels = getListMonths(filterConditions.start, filterConditions.end);
  const data = {
    labels,
    datasets: [
      {
        fill: true,
        label: "Number of Clock In-Out Requests",
        data: Object.values(dataDashBoard[0] || {}),
        borderColor: "#ffd05c",
        backgroundColor: "#fff5dd",
      },
    ],
  };
  return <Line height={"200px"} options={options} data={data} />;
}
