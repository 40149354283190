import {
  DeleteOutlined,
  FolderOutlined,
  FormOutlined,
  PlusOutlined,
} from "@ant-design/icons";
import { Button, Col, Row, Space, Spin, Table, Tag, Tooltip } from "antd";
import dayjs from "dayjs";
import React, { useContext, useEffect, useState } from "react";
import { ModalFormTKG } from "tkg-composite-ui";
import { RangePickerTKG, SelectTKG } from "tkg-composite-ui";
import BoxCustom from "../../../Components/BoxCustom";
import { REQUEST_WORKOUT_STATUS } from "../../../utils/constants";
import Context from "../../../utils/context";
import {
  acceptRequest,
  deleteRequest,
  getListRequest,
  rejectRequest,
} from "../service";
import DetailRequestWorkHour from "./DetailRequestWorkHour";
import RejectRequestModal from "./RejectRequestModal";
import RequestWorkModal from "./RequestWorkModal";
import UpdateRequestWorkModal from "./UpdateRequestWorkModal";
import Swal from "sweetalert2";
const ListRequestWorkHours = (props) => {
  const user = JSON.parse(sessionStorage.getItem("user"));
  const { open, onClose, form } = props;
  const [loading, setLoading] = useState(false);
  const context = useContext(Context);

  const [openUpdateRequestModal, setOpenUpdateRequestModal] = useState(false);
  const [openDetailRequest, setOpenDetailRequest] = useState(false);
  const [selectedRequest, setSelectedRequest] = useState({});
  const [openRejectModal, setOpenRejectModal] = useState(false);
  const [dataRequest, setDataRequest] = useState([]);
  const [listEmployees, setListEmployees] = useState([user]);

  const [filterConditionsRequest, setFilterConditionsRequest] = useState({
    start_date: dayjs().startOf("month").format("YYYY-MM-DD"),
    end_date: dayjs().endOf("month").format("YYYY-MM-DD"),
    user_id: user.id,
  });

  const fetchListRequest = async () => {
    setLoading(true);
    const res = await getListRequest(filterConditionsRequest).then(
      (res) => res.data.response
    );
    setLoading(false);
    setDataRequest(res.requests);
    setListEmployees((prev) => [...[user], ...res.users]);
  };
  useEffect(() => {
    fetchListRequest();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterConditionsRequest]);
  const handleChange = (date, stringDate) => {
    setFilterConditionsRequest((prev) => ({
      ...prev,
      start_date: dayjs(date[0]).format("YYYY-MM-DD"),
      end_date: dayjs(date[1]).format("YYYY-MM-DD"),
    }));
  };
  const onChange = (value) => {
    setFilterConditionsRequest((prev) => ({ ...prev, user_id: value }));
  };
  const handleOpenDetailRequest = (record) => {
    setOpenDetailRequest(true);
    setSelectedRequest(record);
  };
  const handleCloseDetailRequest = () => {
    setOpenDetailRequest(false);
  };
  const handleOpenUpdateRequest = (record) => {
    setOpenUpdateRequestModal(true);
    setSelectedRequest(record);
  };

  const handleCloseModal = () => {
    setOpenUpdateRequestModal(false);
  };
  const handleCloseRequestModal = () => {
    context.hideRequestModal();
  };
  const handleDeleteRequest = (id) => {
    Swal.fire({
      title: "Are you sure to delete this request?",
      showCancelButton: true,
      confirmButtonText: "Delete",
    }).then((result) => {
      /* Read more about isConfirmed, isDenied below */
      if (result.isConfirmed) {
        setLoading(true);
        deleteRequest(id).then((res) => {
          setLoading(false);
          fetchListRequest();
          Swal.fire({
            position: "top-end",
            icon: "success",
            title: "Your work has been saved",
            showConfirmButton: false,
            timer: 1000,
            timerProgressBar: true,
          });
        });
      }
    });
  };

  const handleAccept = (record) => {
    setLoading(true);
    acceptRequest(record.id).then((res) => {
      fetchListRequest();
      setLoading(false);
    });
  };
  const handleOpenReject = (record) => {
    setOpenRejectModal(true);
    setSelectedRequest(record);
  };
  const handleCancelReject = () => setOpenRejectModal(false);
  const handleConfirmReject = (values) => {
    const payload = {
      ...values,
    };
    setLoading(true);
    rejectRequest(payload, selectedRequest.id).then((res) => {
      setOpenRejectModal(false);
      fetchListRequest();
      setLoading(false);
    });
    setOpenRejectModal(false);
    form.resetFields();
  };
  const columnsAdmin = [
    {
      title: "Employee",
      dataIndex: "employee",
      key: "employee",
      render: (_, record) => (
        <Tag>
          {record?.user.first_name} {record?.user.surname}
        </Tag>
      ),
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
      render: (_, record) => record?.user.email,
    },
    {
      title: "Request Date",
      dataIndex: "date",
      key: "date",
      render: (_, record) => (
        <span>{dayjs(record.date).format("DD-MMM-YYYY")}</span>
      ),
    },
    {
      title: "Reason",
      dataIndex: "reason",
      key: "reason",
      render: (_, record) => (
        <div
          className="view-content"
          dangerouslySetInnerHTML={{
            __html: record?.reason,
          }}
        ></div>
      ),
    },
    {
      title: "Create at",
      dataIndex: "created_at",
      key: "created_at",
      render: (_, record) => (
        <span>{dayjs(record.created_at).format("DD-MMM-YYYY")}</span>
      ),
    },
    {
      title: "STATUS",
      dataIndex: "Status",
      key: "Status",
      // width: "150px",
      render: (_, record) => {
        let color;
        let currentStatus;
        if (record.status === REQUEST_WORKOUT_STATUS.PENDING) {
          color = "warning";
          currentStatus = "Pending";
        } else if (record.status === REQUEST_WORKOUT_STATUS.ACCEPTED) {
          color = "success";
          currentStatus = "Accepted";
        } else {
          color = "error";
          currentStatus = "Rejected";
        }

        return (
          <Tooltip
            title={
              record.status === REQUEST_WORKOUT_STATUS.PENDING
                ? null
                : ` By ${record?.approver?.first_name} ${record?.approver?.surname}`
            }
            color={
              record.status === REQUEST_WORKOUT_STATUS.ACCEPTED
                ? "#1ba4e7"
                : "#cf1322"
            }
            placement="right"
          >
            <Tag color={color} key={record.status}>
              {currentStatus}
            </Tag>
          </Tooltip>
        );
      },
    },
    {
      title: "Action",
      key: "action",
      render: (_, record) => (
        <Space>
          <Button
            type="primary"
            className="btnTKGAction"
            icon={<FolderOutlined />}
            ghost
            onClick={() => handleOpenDetailRequest(record)}
          >
            View
          </Button>
          {record.status === REQUEST_WORKOUT_STATUS.PENDING && (
            <>
              <Button
                type="primary"
                className="btnTKGAction"
                ghost
                onClick={() => handleAccept(record)}
              >
                Accept
              </Button>
              <Button
                type="primary"
                className="btnTKGDeny"
                ghost
                onClick={() => handleOpenReject(record)}
              >
                Reject
              </Button>
            </>
          )}
        </Space>
      ),
    },
  ];
  const columnsEmployee = [
    {
      title: "Employee",
      dataIndex: "employee",
      key: "employee",
      render: (_, record) => (
        <Tag>
          {record?.user.first_name} {record?.user.surname}
        </Tag>
      ),
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
      render: (_, record) => record?.user.email,
    },
    {
      title: "Request Date",
      dataIndex: "date",
      key: "date",
      render: (_, record) => (
        <span>{dayjs(record.date).format("DD-MMM-YYYY")}</span>
      ),
    },
    {
      title: "Reason",
      dataIndex: "reason",
      key: "reason",
      render: (_, record) => (
        <div
          className="view-content"
          dangerouslySetInnerHTML={{
            __html: record?.reason,
          }}
        ></div>
      ),
    },
    {
      title: "Create at",
      dataIndex: "created_at",
      key: "created_at",
      render: (_, record) => (
        <span>{dayjs(record.created_at).format("DD-MMM-YYYY")}</span>
      ),
    },

    {
      title: "STATUS",
      dataIndex: "Status",
      key: "Status",
      // width: "80px",
      render: (_, record) => {
        let color;
        let currentStatus;
        if (record.status === REQUEST_WORKOUT_STATUS.PENDING) {
          color = "warning";
          currentStatus = "Pending";
        } else if (record.status === REQUEST_WORKOUT_STATUS.ACCEPTED) {
          color = "success";
          currentStatus = "Accepted";
        } else {
          color = "error";
          currentStatus = "Rejected";
        }

        return (
          <Tooltip
            title={
              record.status === REQUEST_WORKOUT_STATUS.PENDING
                ? null
                : ` By ${record?.approver?.first_name} ${record?.approver?.surname}`
            }
            color={
              record.status === REQUEST_WORKOUT_STATUS.ACCEPTED
                ? "#1ba4e7"
                : "#cf1322"
            }
            placement="right"
          >
            <Tag color={color} key={record.status}>
              {currentStatus}
            </Tag>
          </Tooltip>
        );
      },
    },
    {
      title: "Action",
      key: "action",
      render: (_, record) => (
        <Space>
          <Button
            type="primary"
            className="btnTKGAction"
            icon={<FolderOutlined />}
            ghost
            onClick={() => handleOpenDetailRequest(record)}
          >
            View
          </Button>
          <Button
            type="primary"
            className="btnTKGAction"
            icon={<FormOutlined />}
            ghost
            onClick={() => handleOpenUpdateRequest(record)}
            disabled={record.status === REQUEST_WORKOUT_STATUS.ACCEPTED}
          >
            Edit
          </Button>
          <Button
            type="primary"
            className="btnTKGDeny"
            icon={<DeleteOutlined />}
            ghost
            onClick={() => handleDeleteRequest(record.id)}
            disabled={record.status === REQUEST_WORKOUT_STATUS.ACCEPTED}
          >
            Delete
          </Button>
        </Space>
      ),
    },
  ];

  return (
    <ModalFormTKG
      title="Request WorkHours"
      onClose={onClose}
      open={open}
      footer={false}
    >
      <Spin spinning={loading}>
        <BoxCustom>
          <Row
            gutter={[16, 16]}
            justify="space-between"
            style={{ marginBottom: "20px" }}
          >
            <Col span={4}>
              <Button
                type="primary"
                className="btnTKGAction"
                icon={<PlusOutlined />}
                onClick={() => context.showRequestModal()}
                ghost
              >
                Create
              </Button>
            </Col>
            <Col span={8}>
              <RangePickerTKG
                form={form}
                onChange={handleChange}
                defaultValue={[
                  dayjs().startOf("month"),
                  dayjs().endOf("month"),
                ]}
              />
            </Col>

            {user.role_id === 1 && listEmployees.length > 0 ? (
              <Col span={8}>
                <SelectTKG
                  // style={{ width: "100%" }}
                  placeholder="Select employee"
                  onChange={onChange}
                  showSearch
                  allowClear
                  defaultValue={`${user?.first_name} ${user?.surname} ( ${user?.group} - ${user?.position} ) `}
                  optionFilterProp="children"
                  filterOption={(input, option) =>
                    (option?.label ?? "")
                      .toLowerCase()
                      .includes(input.toLowerCase())
                  }
                  options={[
                    {
                      label: "Show All",
                      value: "",
                    },
                    ...listEmployees?.map((item) => ({
                      label: `${item?.first_name} ${item?.surname} ( ${item?.group} - ${item?.position} ) `,
                      value: item.id,
                    })),
                  ]}
                />
              </Col>
            ) : null}
          </Row>

          <Row gutter={[16, 16]}>
            <Col span={24}>
              <Table
                columns={user?.role_id === 1 ? columnsAdmin : columnsEmployee}
                dataSource={dataRequest}
              />
            </Col>
          </Row>
        </BoxCustom>

        {context.isOpenRequestModal && (
          <RequestWorkModal
            open={context.isOpenRequestModal}
            onClose={handleCloseRequestModal}
            fetchListRequest={fetchListRequest}
          />
        )}
        {Object.keys(selectedRequest).length > 0 && (
          <DetailRequestWorkHour
            openModal={openDetailRequest}
            handleClose={handleCloseDetailRequest}
            detailData={selectedRequest}
          />
        )}
        {openUpdateRequestModal && (
          <UpdateRequestWorkModal
            open={openUpdateRequestModal}
            setOpenUpdateRequestModal={setOpenUpdateRequestModal}
            onClose={handleCloseModal}
            fetchListRequest={fetchListRequest}
            selectedRequest={selectedRequest}
          />
        )}
        <RejectRequestModal
          isModalOpen={openRejectModal}
          handleConfirm={handleConfirmReject}
          handleCancel={handleCancelReject}
          form={form}
        />
      </Spin>
    </ModalFormTKG>
  );
};

export default ListRequestWorkHours;
