import React, { useCallback, useEffect, useState } from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Filler,
  Legend,
} from "chart.js";
import { Line } from "react-chartjs-2";
import faker from "faker";
import { getListTimeoff } from "./service";
import moment from "moment";
import { getListMonths } from "../../utils/functions";
import { TIME_OFF_STATUS } from "../../utils/constants";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Filler,
  Legend
);

export const options = {
  responsive: true,
  plugins: {
    legend: {
      position: "top",
    },
    title: {
      display: true,
      text: "Number Of Timeoff Requests Accepted In Months",
    },
  },
};

export function TimeoffRequestChart(props) {
  const { filterConditions, dataDashBoard } = props;

  const labels = getListMonths(filterConditions.start, filterConditions.end);
  const data = {
    labels,
    datasets: [
      {
        fill: true,
        label: "Number of Timeoff Requests",
        data: Object.values(dataDashBoard[1] || {}),
        borderColor: "rgb(53, 162, 235)",
        backgroundColor: "rgba(53, 162, 235, 0.5)",
      },
    ],
  };

  return <Line height={"200px"} options={options} data={data} />;
}
