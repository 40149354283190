import { PlusOutlined } from "@ant-design/icons";
import { Button, Col, Form, Modal, Row } from "antd";

import React from "react";
import { InputTKG, SelectTKG, CheckboxTKG } from "tkg-composite-ui";

const listTags = [
  { name: "High", value: "high" },
  { name: "Medium", value: "medium" },
  { name: "Low", value: "low" },
];
const CreateProjectModal = (props) => {
  const { isModalOpen, handleCreate, handleCancel, form } = props;
  return (
    <Modal
      open={isModalOpen}
      footer={false}
      width={"800px"}
      onCancel={handleCancel}
      bodyStyle={{ padding: "50px 20px 15px 20px" }}
    >
      <Form
        form={form}
        layout="vertical"
        onFinish={handleCreate}
        initialValues={{
          mode: false,
          Paid: false,
        }}
      >
        <Row gutter={[16, 16]}>
          <Col span={18}>
            <Form.Item name="Title" label="What are you working on ?">
              <InputTKG form={form} maxLength={200} />
            </Form.Item>
          </Col>
          <Col span={6}>
            <Form.Item name="Tags" label={"Priovity"}>
              <SelectTKG
                form={form}
                placeholder="Select tags"
                showSearch
                optionFilterProp="children"
                filterOption={(input, option) =>
                  (option?.label ?? "")
                    .toLowerCase()
                    .includes(input.toLowerCase())
                }
                options={listTags.map((item) => ({
                  label: item.name,
                  value: item.value,
                }))}
              />
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={[4, 4]}>
          <Col span={24} style={{ textAlign: "right" }}>
            <Button
              type="primary"
              htmlType="submit"
              className="btnTKGAction"
              icon={<PlusOutlined />}
            >
              Save
            </Button>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
};

export default CreateProjectModal;
