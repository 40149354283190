import React, { useEffect } from 'react'
import {Outlet, useNavigate, withRouter} from 'react-router-dom';
import { LayoutComponent } from "../Components/Layout/LayoutComponent";

const RootPage = () => {
  const navigate = useNavigate();
  const path = window.location.pathname.trim('/').split('/');

  return(
       path[1] !== 'verify' ? <LayoutComponent /> : <><Outlet /></>
  )



}

export default RootPage