import { deleteData, getData, postData, putData } from "../../utils/request";
import { Endpoint } from "../../utils/endpoind";
import { buildQueryString } from "../../utils/functions";

export const getListClients = async () => {
  return await getData({ url: `${Endpoint.GET_LIST_USER}` });
};

// project
export const getListProjectCalendar = async (filterCondition) => {
  const query = buildQueryString(filterCondition);
  return await getData({ url: `${Endpoint.GET_PROJECT_CALENDAR}?${query}` });
};
export const createProjectCalendar = async (payload) => {
  return await postData({
    url: `${Endpoint.CREATE_NEW_PROJECT_CALENDAR}`,
    payload,
  });
};
export const updateProjectCalendar = async (payload, id) => {
  return await putData({
    url: `${Endpoint.UPDATE_PROJECT_CALENDAR}/${id}`,
    payload,
  });
};
export const deleteProjectCalendar = async (id) => {
  return await deleteData({ url: `${Endpoint.DELETE_PROJECT_CALENDAR}/${id}` });
};

// meeting
export const getListMeetingCalendar = async (filterCondition) => {
  const query = buildQueryString(filterCondition);
  return await getData({ url: `${Endpoint.GET_MEETING_CALENDAR}?${query}` });
};
export const createMeetingCalendar = async (payload) => {
  return await postData({
    url: `${Endpoint.CREATE_NEW_MEETING_CALENDAR}`,
    payload,
  });
};
export const updateMeetingCalendar = async (payload, id) => {
  return await putData({
    url: `${Endpoint.UPDATE_MEETING_CALENDAR}/${id}`,
    payload,
  });
};
export const deleteMeetingCalendar = async (id) => {
  return await deleteData({ url: `${Endpoint.DELETE_MEETING_CALENDAR}/${id}` });
};
