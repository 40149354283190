import {
  Form,
  Row,
  Col,
  Spin,
  Button,
  List,
  Avatar,
  Space,
  Typography,
} from "antd";
import moment from "moment";
import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { getListUser, getUserWorkOut } from "./service";
import "react-big-calendar/lib/css/react-big-calendar.css";
import PropTypes from "prop-types";
import {
  Calendar,
  Views,
  DateLocalizer,
  momentLocalizer,
} from "react-big-calendar";
import "./clockInOutReport.scss";
import WorkoutModal from "./clockin-out-modal/WorkoutModal";
import BoxCustom from "../../Components/BoxCustom";
import ListRequestWorkHours from "./request-modal/ListRequestWorkHours";
import dayjs from "dayjs";
import Context from "../../utils/context";
import { CLOCK_IN_OUT_STATUS } from "../../utils/constants";
import { SelectTKG } from "tkg-composite-ui/dist/components/Select/SelectTKG";
import CustomToolbar from "../../Components/CustomToolbar";
import styled from "styled-components";
const ClockInOutReport = () => {
  const user = JSON.parse(sessionStorage.getItem("user"));
  const context = useContext(Context);

  const [form] = Form.useForm();
  const [listDataUsers, setListDataUsers] = useState([]);
  const [loading, setLoading] = useState(false);

  const [isOpenWorkout, setIsOpenWorkout] = useState(false);
  const [dataWorkout, setDataWorkout] = useState();
  const [userEvents, setUserEvents] = useState([]);
  const [filterConditions, setFilterConditions] = useState({
    start_date: dayjs().startOf("month").format("YYYY-MM-DD"),
    end_date: dayjs().endOf("month").format("YYYY-MM-DD"),
    user_id: user.id,
  });

  const fetchListUsers = async () => {
    const res = await getListUser();
    setListDataUsers(res.data.response);
    return res;
  };
  useEffect(() => {
    setLoading(true);
    fetchListUsers().then((res) => setLoading(false));
  }, []);
  const fetchUserWorkout = async () => {
    const res = await getUserWorkOut(
      filterConditions.user_id,
      filterConditions
    );

    const userEvents = res.data.response.map((item, ind) => {
      return {
        id: item?.id,
        title: `${dayjs(item?.start_time).format("HH:mm")} - ${
          item.end_time
            ? dayjs(item?.end_time).format("HH:mm")
            : "Missing clock out"
        }`,
        start: new Date(item?.start_time),
        end: new Date(item.end_time ? item?.end_time : item?.start_time),
        isActive: item.end_time ? true : false,
        ...item,
      };
    });
    setUserEvents(userEvents);
    return userEvents;
  };

  useEffect(() => {
    setLoading(true);
    fetchUserWorkout().then((res) => setLoading(false));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterConditions]);

  // const handleSelectSlot = useCallback(({ start, end }) => {
  //   setOpenRequestModal(true);
  // }, []);

  const handleCloseListRequest = () => {
    context.hideListRequestModal();
    setLoading(true);
    fetchUserWorkout().then((res) => setLoading(false));
  };
  const handleCloseModal = () => {
    setIsOpenWorkout(false);
  };
  const handleSelectEvent = (event) => {
    fetchUserWorkout().then((res) => {
      setDataWorkout(res.find((item) => item.id === event.id));
      setIsOpenWorkout(true);
    });
  };

  const CustomEvent = (event) => {
    return (
      <span style={{ display: "flex", alignItems: "center" }}>
        <span
          className={
            event.event.status === CLOCK_IN_OUT_STATUS.REQUEST
              ? "dot yellow-dot"
              : event.event.isActive
              ? "dot green-dot"
              : " dot red-dot"
          }
        ></span>{" "}
        {event.event.title}{" "}
      </span>
    );
  };
  const eventPropGetter = useCallback((event, start, end, isSelected) => {
    return {
      ...(event && {
        style: {
          padding: "5px",
          width: "90%",
          backgroundColor: "transparent",
          color: "#000",
          fontSize: "13px",
          fontWeight: 600,
          // border: "2px solid rgb(0 123 181)",
        },
      }),
    };
  }, []);

  const { defaultDate, scrollToTime, formats } = useMemo(
    () => ({
      defaultDate: new Date(),
      scrollToTime: new Date(1970, 1, 1, 6),
      formats: {
        dayHeaderFormat: (date, culture, localizer) =>
          localizer.format(date, `ddd, DD / MMM`, culture),
        dayRangeHeaderFormat: ({ start, end }, culture, localizer) =>
          localizer.format(start, `ddd, DD / MMM`, culture) +
          " — " +
          localizer.format(end, `ddd, DD / MMM`, culture),
        agendaHeaderFormat: ({ start, end }, culture, localizer) =>
          localizer.format(start, `ddd, DD / MMM`, culture) +
          " — " +
          localizer.format(end, `ddd, DD / MMM`, culture),
      },
    }),
    []
  );

  const onSelectUserChange = (value) => {
    setFilterConditions((prev) => ({ ...prev, user_id: value }));
  };

  const loadMoreData = () => {
    if (loading) {
      return;
    }
    setLoading(true);
    fetchListUsers()
      .then((res) => {
        setListDataUsers([...listDataUsers, ...res]);
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
      });
  };
  useEffect(() => {
    loadMoreData();
  }, []);
  return (
    <Spin spinning={loading}>
      <BoxCustom>
        <Row gutter={[12, 12]} style={{ marginBottom: "20px" }}>
          <Col span={4}>
            <Button
              size="large"
              type="primary"
              className="btnTKGAction"
              onClick={() => context.showListRequestModal()}
            >
              Work Hours Requests
            </Button>
          </Col>
          <Col span={6}>
            <Form.Item name="User">
              <SelectTKG
                form={form}
                onChange={onSelectUserChange}
                // placeholder="Select tags"
                defaultValue={`${user?.first_name} ${user?.surname} ( ${user?.group} - ${user?.position} ) `}
                showSearch
                optionFilterProp="children"
                filterOption={(input, option) =>
                  (option?.label ?? "")
                    .toLowerCase()
                    .includes(input.toLowerCase())
                }
                options={listDataUsers.map((item) => ({
                  label: `${item?.first_name} ${item?.surname} ( ${item?.group} - ${item?.position} ) `,
                  value: item.id,
                }))}
              />
            </Form.Item>
          </Col>
          <Col span={14} align="end">
            <Space>
              <BoxCustom>
                <Space>
                  <Space>
                    <Typography className=" dot red-dot"></Typography>
                    <Typography>
                      Clock in manually - forget clock out
                    </Typography>
                  </Space>
                  |
                  <Space>
                    <Typography className="dot yellow-dot"></Typography>
                    <Typography> Request accepted clock in-out</Typography>
                  </Space>
                  |
                  <Space>
                    <Typography className="dot green-dot"></Typography>
                    <Typography>Clock in-out manually</Typography>
                  </Space>
                </Space>
              </BoxCustom>
            </Space>
          </Col>
        </Row>
        <Row gutter={[12, 12]}>
          {/* <Col span={6}>
            <div
              id="style-scroll"
              style={{
                height: 650,
                overflow: "auto",
                padding: "0 16px",
                // border: "1px solid rgba(140, 140, 140, 0.35)",
              }}
            >
              <List
                dataSource={
                  user.role_id === 1
                    ? [
                        user,
                        ...listDataUsers.filter((item) => item.id !== user.id),
                      ]
                    : [user]
                }
                rowKey="id"
                renderItem={(item) => (
                  <List.Item
                    key={item?.id}
                    onClick={() => onChange(item)}
                    className={
                      item?.id === filterConditions.user_id
                        ? "active-employee"
                        : "hover-employee"
                    }
                  >
                    <List.Item.Meta
                      avatar={<Avatar src={DefaultImage} />}
                      title={`${item?.first_name} ${item?.surname}`}
                      description={item?.email}
                    />
                  </List.Item>
                )}
              />
            </div>
          </Col> */}

          <Col span={24}>
            <Container>
              <Calendar
                defaultDate={defaultDate}
                defaultView={Views.MONTH}
                events={userEvents}
                eventPropGetter={eventPropGetter}
                localizer={momentLocalizer(moment)}
                onSelectEvent={handleSelectEvent}
                // onSelectSlot={handleSelectSlot}
                selectable
                scrollToTime={scrollToTime}
                components={{
                  // toolbar: CustomToolbar,
                  event: CustomEvent,
                }}
                formats={formats}
                views={[Views.MONTH]}
                style={{
                  height: `calc(100vh - 295px)`,
                  width: "100%",
                  backgroundColor: "white",
                  padding: "10px",
                }}
              />
            </Container>
          </Col>
        </Row>
        {dataWorkout && (
          <WorkoutModal
            dataWorkout={dataWorkout}
            isOpenWorkout={isOpenWorkout}
            handleCloseModal={handleCloseModal}
          />
        )}

        {context.isOpenListRequestModal && (
          <ListRequestWorkHours
            open={context.isOpenListRequestModal}
            onClose={handleCloseListRequest}
            form={form}
          />
        )}
      </BoxCustom>
    </Spin>
  );
};

const Container = styled.div`
  .rbc-toolbar-label {
    font-weight: 600 !important;
  }
  .rbc-current button {
    background: blue !important;
    color: #fff !important;
    border-radius: 50%;
    height: 20px !important;
    width: 25px !important;
  }

  .rbc-today button {
    background: blue !important;
    color: #fff !important;
    border-radius: 40%;
    height: 20px !important;
    width: 45px !important;
    font-weight: 600;
    padding: 2px;
  }
  .rbc-row-segment .rbc-show-more {
    color: #063964 !important;
  }
`;
Calendar.propTypes = {
  localizer: PropTypes.instanceOf(DateLocalizer),
};
export default ClockInOutReport;
