const BASE_API_URL_TIME_TRACKER = process.env.REACT_APP_TIME_REPORT_API_URL;
export const API_CALENDAR = {
  // Project
  GET_PROJECT_CALENDAR: `${BASE_API_URL_TIME_TRACKER}/api/project`,
  GET_USER_PROJECT_CALENDAR: `${BASE_API_URL_TIME_TRACKER}/api/project/detail`,
  CREATE_NEW_PROJECT_CALENDAR: `${BASE_API_URL_TIME_TRACKER}/api/project/store`,
  UPDATE_PROJECT_CALENDAR: `${BASE_API_URL_TIME_TRACKER}/api/project/update`,
  DELETE_PROJECT_CALENDAR: `${BASE_API_URL_TIME_TRACKER}/api/project/delete`,

  // meeting
  GET_MEETING_CALENDAR: `${BASE_API_URL_TIME_TRACKER}/api/meeting`,
  GET_USER_MEETING_CALENDAR: `${BASE_API_URL_TIME_TRACKER}/api/meeting/detail`,
  CREATE_NEW_MEETING_CALENDAR: `${BASE_API_URL_TIME_TRACKER}/api/meeting/store`,
  UPDATE_MEETING_CALENDAR: `${BASE_API_URL_TIME_TRACKER}/api/meeting/update`,
  DELETE_MEETING_CALENDAR: `${BASE_API_URL_TIME_TRACKER}/api/meeting/delete`,
};
