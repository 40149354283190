import {
  Form,
  Row,
  Col,
  Spin,
  Button,
  List,
  Avatar,
  Space,
  Typography,
  Input,
  Table,
  DatePicker,
  Tabs,
  Timeline,
  Tag,
} from "antd";
import moment from "moment";
import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { getAllWorkOut, getListUser } from "./service";
import "./clockInOutReport.scss";
import BoxCustom from "../../Components/BoxCustom";
import dayjs from "dayjs";
import { ClockCircleOutlined, SearchOutlined } from "@ant-design/icons";
import { ACTION } from "../../utils/constants";
import { changeDateTimeZone } from "../../utils/functions";
import { calculateTimer } from "../../Components/Layout/clockInClockOut/Timer";

export function time_convert(num) {
  // Calculate the number of hours by dividing num by 60 and rounding down
  var hours = Math.floor(num / 60);

  // Calculate the remaining minutes by taking the remainder when dividing num by 60
  var minutes = num % 60;

  // Return the result as a string in the format "hours:minutes"
  return hours + "h" + minutes + "m";
}
const ManageTimer = () => {
  const [activeTab, setActiveTab] = useState("TWT Team");
  const [loading, setLoading] = useState(false);

  const [userTimer, setUserTimer] = useState([]);
  const [date, setDate] = useState(dayjs().format("YYYY-MM-DD"));

  const onChangeDate = (date, dateString) => {
    setDate(date.format("YYYY-MM-DD"));
  };

  const fetchUserWorkout = async () => {
    const res = await getAllWorkOut({ date: date });

    if (res.status === 200) {
      const dataWorkout = res.data.response;
      await getListUser().then((resUser) => {
        if (resUser.status === 200) {
          const listUsers = resUser.data.response;

          const dataTable = listUsers.map((item, index) => {
            const employeeWorkout = dataWorkout.find(
              (workout) => workout.user_id === item.id
            );

            if (item.first_name === "Social Media") {
              return [];
            }
            if (employeeWorkout) {
              return employeeWorkout;
            } else {
              // console.log(item.first_name !== "Social Media");
              // if (item.first_name !== "Social Media") {
              return {
                user: item,
                logs: [],
                start_time: null,
                end_time: null,
              };
              // }
            }
          });

          setUserTimer(dataTable.flat());
        }
      });
    }

    return res;
  };
  useEffect(() => {
    setLoading(true);
    fetchUserWorkout().then((res) => setLoading(false));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [date]);

  const calculateTimeInMins = (startTime, endTime) => {
    const timer = dayjs(endTime).diff(dayjs(startTime), "minute");
    return timer + 1;
  };
  const getListTimeLine = (logsBreak) => {
    const listData = [];
    if (logsBreak && logsBreak.length > 0) {
      logsBreak.reduce((prev, currentValue) => {
        if (currentValue.action === ACTION.BREAK) {
          listData.push({
            id: currentValue.id,
            children: (
              <>
                Break{" "}
                <Tag color="default">
                  {moment(
                    changeDateTimeZone(currentValue?.created_at, "GMT")
                  ).format("HH:mm:ss")}
                </Tag>
              </>
            ),

            dot: (
              <ClockCircleOutlined
                style={{
                  fontSize: "16px",
                }}
              />
            ),
            color: "red",
          });
          return currentValue;
        } else if (currentValue.action === ACTION.LUNCH_BREAK) {
          listData.push({
            id: currentValue.id,

            children: (
              <>
                Lunch break{" "}
                <Tag color="default">
                  {moment(
                    changeDateTimeZone(currentValue?.created_at, "GMT")
                  ).format("HH:mm:ss")}
                </Tag>
              </>
            ),
            dot: (
              <ClockCircleOutlined
                style={{
                  fontSize: "16px",
                }}
              />
            ),
            color: "red",
          });
          return currentValue;
        } else if (currentValue.action === ACTION.RESUME_WORK) {
          listData.push({
            id: currentValue.id,

            children: (
              <>
                Resume break{" "}
                <Tag color="default">
                  {moment(
                    changeDateTimeZone(currentValue?.created_at, "GMT")
                  ).format("HH:mm:ss")}
                </Tag>
                <Tag>
                  {calculateTimeInMins(
                    prev?.created_at,
                    currentValue?.created_at
                  )}{" "}
                  mins
                </Tag>
              </>
            ),
          });
          return currentValue;
        }
      }, {});
    }
    return listData;
  };
  const getTotalTimeBreak = (dataWorkout) => {
    let totalTimeBreak = 0;
    if (dataWorkout.logs && dataWorkout.logs.length > 0) {
      dataWorkout.logs.slice(1).reduce((prev, currentValue) => {
        if (
          Object.keys(prev || {}).length > 0 &&
          currentValue.action === ACTION.RESUME_WORK
        ) {
          totalTimeBreak += calculateTimeInMins(
            prev?.created_at,
            currentValue?.created_at
          );
        }
        return currentValue;
      }, {});

      return totalTimeBreak;
    }

    return totalTimeBreak;
  };

  const columns = [
    {
      title: "#",
      dataIndex: "index",
      key: "index",
      width: "50px",
      render: (_, record) => record.index,
    },
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      // ...getColumnSearchProps("name"),
      render: (_, record) =>
        `${record.user.first_name} ${record.user.surname} `,
    },
    {
      title: "Start Time",
      dataIndex: "start_time",
      key: "start_time",
      width: "200px",
      sorter: (a, b) =>
        moment(a.start_time).valueOf() - moment(b.start_time).valueOf(),
      sortDirections: ["descend", "ascend"],
      // render: (text) => (
      //   <span
      //     style={
      //       moment(text).isAfter(
      //         moment(`${moment(text).format("YYYY-MM-DD")} 07:00:00`)
      //       )
      //         ? { background: "#ffdd76" }
      //         : {}
      //     }
      //   >
      //     {text ? moment(text).format("HH:mm:ss") : ""}
      //   </span>
      // ),

      render(text, record) {
        return {
          // props: {
          //   style: moment(text).isAfter(
          //     moment(`${moment(text).format("YYYY-MM-DD")} 07:05:00`)
          //   )
          //     ? { background: "#ffdd76" }
          //     : {},
          // },
          children:
            activeTab === "Head Office" ? (
              <div>{text ? moment(text).format("HH:mm:ss") : ""}</div>
            ) : (
              <div>
                <span style={{ marginRight: 10 }}>
                  {text ? moment(text).format("HH:mm:ss") : ""}
                </span>{" "}
                <span style={{ color: "red", fontWeight: 600 }}>
                  {moment(text).isAfter(
                    moment(`${moment(text).format("YYYY-MM-DD")} 07:05:00`)
                  ) && "Late"}
                </span>
              </div>
            ),
        };
      },
    },
    {
      title: "End Time",
      dataIndex: "end_time",
      key: "end_time",
      width: "200px",
      sorter: (a, b) =>
        moment(a.end_time).valueOf() - moment(b.end_time).valueOf(),
      sortDirections: ["descend", "ascend"],
      render: (text) => (
        <>
          {activeTab === "Head Office" ? (
            <div>{text ? moment(text).format("HH:mm:ss") : ""}</div>
          ) : (
            <div>
              <span style={{ marginRight: 10 }}>
                {text ? moment(text).format("HH:mm:ss") : ""}
              </span>{" "}
              <span style={{ color: "red", fontWeight: 600 }}>
                {moment(text).isBefore(
                  moment(`${moment(text).format("YYYY-MM-DD")} 16:00:00`)
                )
                  ? "Leave early"
                  : ""}
              </span>
            </div>
          )}
        </>
      ),
    },
    {
      title: "Break Time",
      dataIndex: "breaks",
      key: "breaks",
      width: "400px",
      sorter: (a, b) =>
        moment(a.end_time).valueOf() - moment(b.end_time).valueOf(),
      sortDirections: ["descend", "ascend"],
      render: (text, record) => {
        return (
          <Timeline
            className="custome-timeline"
            position={"left"}
            items={getListTimeLine(record.logs)}
          />
        );
      },
    },
    {
      title: "Total (hours)",
      dataIndex: "total",
      key: "total",
      width: "200px",
      render: (text, record) => {
        return record.start_time && record.end_time
          ? `${time_convert(
              Math.round(
                Number(
                  moment(record.end_time).diff(
                    moment(record.start_time),
                    "minutes"
                  ) - getTotalTimeBreak(record)
                )
              )
            )}`
          : "";
      },
    },
  ];

  const getData = useMemo(() => {
    const baseData = userTimer;

    switch (activeTab) {
      case "TWT Team":
        return baseData
          .filter((item) => item.user.group === "TWT Team")
          .map((el, idx) => ({ ...el, index: idx + 1 }))
          .sort((a, b) => (a.team_lead_id == null ? 1 : -1));

      case "IT Team":
        return baseData
          .filter((item) => item.user.group === "IT Team")
          .map((el, idx) => ({ ...el, index: idx + 1 }))
          .sort((a, b) => (a.team_lead_id == null ? 1 : -1));
      case "OVC Team":
        return baseData
          .filter((item) => item.user.group === "OVC Team")
          .map((el, idx) => ({ ...el, index: idx + 1 }))
          .sort((a, b) => (a.team_lead_id == null ? 1 : -1));
      case "Head Office":
      case null:
        return baseData
          .filter(
            (item) =>
              item.user.group === "Head Office" || item.user.group === null
          )
          .map((el, idx) => ({ ...el, index: idx + 1 }))
          .sort((a, b) => (a.team_lead_id == null ? 1 : -1));

      default:
        break;
    }
  }, [activeTab, userTimer]);

  return (
    <Spin spinning={loading}>
      <div style={{ marginBottom: 10 }}>
        <BoxCustom>
          <DatePicker
            onChange={onChangeDate}
            defaultValue={dayjs(date)}
            allowClear={false}
            size="large"
            format={"DD-MMM-YYYY"}
            disabledDate={(date) => dayjs(date).isAfter(dayjs())}
          />
        </BoxCustom>
      </div>

      <Tabs
        type="card"
        accessKey={activeTab}
        onChange={(activeKey) => setActiveTab(activeKey)}
        items={[
          {
            label: "TWT Team",
            key: "TWT Team",
            children: (
              <BoxCustom>
                <Table
                  columns={columns}
                  dataSource={getData}
                  bordered
                  // onRow={(record, index) => ({
                  //   style: {
                  //     background: renderBgRowColor(record),
                  //   },
                  // })}

                  pagination={{
                    defaultPageSize: 50,
                  }}
                />
              </BoxCustom>
            ),
          },
          {
            label: "IT Team",
            key: "IT Team",
            children: (
              <BoxCustom>
                <Table
                  columns={columns}
                  dataSource={getData}
                  bordered
                  // onRow={(record, index) => ({
                  //   style: {
                  //     background: renderBgRowColor(record),
                  //   },
                  // })}

                  pagination={{
                    defaultPageSize: 50,
                  }}
                />
              </BoxCustom>
            ),
          },
          {
            label: "OVC Team",
            key: "OVC Team",
            children: (
              <BoxCustom>
                <Table
                  columns={columns}
                  dataSource={getData}
                  bordered
                  // onRow={(record, index) => ({
                  //   style: {
                  //     background: renderBgRowColor(record),
                  //   },
                  // })}

                  pagination={{
                    defaultPageSize: 50,
                  }}
                />
              </BoxCustom>
            ),
          },
          {
            label: "Head Office",
            key: "Head Office",
            children: (
              <BoxCustom>
                <Table
                  columns={columns}
                  dataSource={getData}
                  bordered
                  // onRow={(record, index) => ({
                  //   style: {
                  //     background: renderBgRowColor(record),
                  //   },
                  // })}

                  pagination={{
                    defaultPageSize: 50,
                  }}
                />
              </BoxCustom>
            ),
          },
        ]}
      />
    </Spin>
  );
};

export default ManageTimer;
