import { PlusOutlined } from "@ant-design/icons";
import { Button, Col, Form, Modal, Row, Space, Spin } from "antd";

import React, { useEffect } from "react";
import { TextEditorTKG } from "tkg-composite-ui";

const ModalUpdateNote = (props) => {
  const {
    isModalOpen,
    handleUpdateNote,
    handleDeleteNote,
    handleCancel,
    form,
    loading,
    noteTimeTracker,
  } = props;

  return (
    <Modal
      open={isModalOpen}
      footer={false}
      width={"800px"}
      onCancel={handleCancel}
      bodyStyle={{ padding: "50px 20px 15px 20px" }}
    >
      <Spin spinning={loading}>
        <Form
          form={form}
          layout="vertical"
          onFinish={handleUpdateNote}
          initialValues={{
            note: noteTimeTracker.note,
          }}
        >
          <Row gutter={[16, 16]}>
            <Col span={24}>
              <Form.Item name="note" label="Note">
                <TextEditorTKG form={form} />
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={[4, 4]}>
            <Col span={24} style={{ textAlign: "right" }}>
              <Space>
                <Button
                  type="primary"
                  htmlType="submit"
                  className="btnTKGAction"
                  ghost
                >
                  Edit
                </Button>
                <Button
                  type="primary"
                  className="btnTKGDeny"
                  ghost
                  onClick={handleDeleteNote}
                >
                  Delete
                </Button>
              </Space>
            </Col>
          </Row>
        </Form>
      </Spin>
    </Modal>
  );
};

export default ModalUpdateNote;
