import { Button, Col, Form, Row } from "antd";
import React from "react";
import {
  ModalTKG,
  InputTKG,
  DatePickerTKG,
  SelectTKG,
  RangePickerTKG,
} from "tkg-composite-ui";
import BoxCustom from "../../../Components/BoxCustom";
import { useEffect } from "react";
import dayjs from "dayjs";

const listPriority = [
  {
    value: "high",
    label: "High",
  },
  {
    value: "medium",
    label: "Medium",
  },
  {
    value: "low",
    label: "Low",
  },
];

const UpdateProject = (props) => {
  const { isModalOpen, handleOk, handleCancel, form, listUser, detailData } =
    props;
  const user = JSON.parse(sessionStorage.getItem("user"));

  useEffect(() => {
    form.setFieldsValue({
      title: detailData.title,
      period: [dayjs(detailData.start_time), dayjs(detailData.end_time)],
      start_project: dayjs(detailData.start_project),
      priority: detailData.priority,
      hours_per_day: detailData.hours_per_day,
      working_days: detailData.working_days,
      estimate: detailData.estimate,
      assign_project: detailData.assign_project.map((item) => item.id),
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [detailData]);
  return (
    <ModalTKG
      title="Update Project"
      open={isModalOpen}
      footer={false}
      onCancel={handleCancel}
    >
      <BoxCustom>
        <Form
          layout="vertical"
          onFinish={handleOk}
          initialValues={{
            title: detailData.title,
            period: [dayjs(detailData.start_time), dayjs(detailData.end_time)],
            start_project: dayjs(detailData.start_project),
            priority: detailData.priority,
            hours_per_day: detailData.hours_per_day,
            working_days: detailData.working_days,
            estimate: detailData.estimate,
            assign_project: detailData.assign_project.map((item) => item.id),
          }}
        >
          <Row gutter={[8, 4]}>
            <Col span={24}>
              <Form.Item
                name="title"
                rules={[
                  { required: true, message: "Please input your title!" },
                ]}
              >
                <InputTKG placeholder={"What are you working on?"} />
              </Form.Item>
            </Col>

            <Col span={8}>
              <Form.Item
                name="period"
                label="Period"
                rules={[
                  { required: true, message: "Please choose your time!" },
                ]}
              >
                {/* <RangePickerTKG allowClear={false} format="DD-MM-YYYY" /> */}
                <RangePickerTKG
                  showTime={{
                    format: "HH:mm",
                  }}
                  format="YYYY-MM-DD HH:mm"
                  minuteStep={15}
                />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item
                name="start_project"
                label="Start Project"
                rules={[
                  { required: true, message: "Please choose your time!" },
                ]}
              >
                <DatePickerTKG allowClear={false} format="DD-MM-YYYY" />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item
                name="priority"
                label="Priority"
                rules={[
                  { required: true, message: "Please input your priority!" },
                ]}
              >
                <SelectTKG
                  form={form}
                  // placeholder="Select tags"
                  showSearch
                  optionFilterProp="children"
                  filterOption={(input, option) =>
                    (option?.label ?? "")
                      .toLowerCase()
                      .includes(input.toLowerCase())
                  }
                  options={listPriority.map((item) => ({
                    label: item.label,
                    value: item.value,
                  }))}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={[8, 4]}>
            <Col span={7}>
              <Form.Item
                name="hours_per_day"
                label="Hours per day"
                rules={[
                  {
                    required: true,
                    message: "Please input your hours per day!",
                  },
                ]}
              >
                <InputTKG />
              </Form.Item>
            </Col>
            <Col span={7}>
              <Form.Item
                name="working_days"
                label="Working days"
                rules={[
                  {
                    required: true,
                    message: "Please input your working days!",
                  },
                ]}
              >
                <InputTKG />
              </Form.Item>
            </Col>{" "}
            <Col span={10}>
              <Form.Item
                name="estimate"
                label="Estimate working hours"
                rules={[
                  {
                    required: true,
                    message: "Please input your estimate working hours!",
                  },
                ]}
              >
                <InputTKG />
              </Form.Item>
            </Col>
          </Row>

          {user.role_id === 1 ? (
            <Col span={24}>
              <Form.Item
                name="assign_project"
                label="Assign Project"
                rules={[
                  {
                    required: true,
                    message: "Please choose your assign project!",
                  },
                ]}
              >
                <SelectTKG
                  form={form}
                  mode="multiple"
                  maxTagCount={4}
                  allowClear
                  placeholder="Select members"
                  showSearch
                  optionFilterProp="children"
                  filterOption={(input, option) =>
                    (option?.label ?? "")
                      .toLowerCase()
                      .includes(input.toLowerCase())
                  }
                  options={listUser.map((item) => ({
                    label: `${item?.first_name} ${item?.surname} ( ${item?.group} - ${item?.position} ) `,
                    value: item.id,
                  }))}
                />
              </Form.Item>
            </Col>
          ) : null}

          <Row gutter={[4, 4]} justify="end">
            <Col span={4}>
              <Button
                type="primary"
                className="btnTKGDeny"
                ghost
                onClick={handleCancel}
                style={{ marginRight: "10px" }}
              >
                Cancel
              </Button>

              <Button type="primary" className="btnTKGAction" htmlType="submit">
                Update
              </Button>
            </Col>
          </Row>
        </Form>
      </BoxCustom>
    </ModalTKG>
  );
};

export default UpdateProject;
