import React from "react";
import { Layout } from "antd";
import Logo from "../../assets/default-avatar.jpg";
import "./index.scss";
import { MenuOutlined } from "@ant-design/icons";
import {encode} from "js-base64";
const { Header } = Layout;

export const HeaderCustom = (props) => {
  const {visible, openDrawer} = props;
  const user = JSON.parse(sessionStorage.getItem('user') ?? JSON.stringify({}));

  const redirectToOneLogin = () => {
    window.location.href = `${process.env.REACT_APP_DOMAIN_SSO}verify/${encode(sessionStorage.getItem('accessToken'))}`
  }

  return (

    <Header className="site-layout__header">
    <div className="site-layout__header__logo">
      <div>
        <a onClick={redirectToOneLogin} href="#">
          <img alt="Logo" src={Logo} width="75px" />
        </a>
      </div>
    </div>
    <div className="site-layout__header__title">
      <span className="text-style-shadow h1">Time & Report</span>
    </div>
    <div style={{ flexGrow: 1 }}></div>
    <div className="site-layout__header__name">
      <div style={{textAlign: "end"}}>
        <span className="text-style-shadow h4">Welcome</span>
        <br />
        <span className="text-style-shadow h1">
          {user.first_name ?? ''} {user.surname ?? ''}
        </span>
      </div>
    </div>
    <div className="site-layout__header__menu">
      <MenuOutlined onClick={() => openDrawer(!visible)} />
    </div>
  </Header>
  );
};