import {
  InfoCircleFilled,
  LoadingOutlined,
  SettingOutlined,
} from "@ant-design/icons";

import {
  IconPlayerPlay,
  IconPlayerPause,
  IconPlayerStop,
  IconTrash,
  IconArrowNarrowRight,
  IconMinusVertical,
} from "@tabler/icons-react";

import "./timeTracker.scss";
import {
  Modal,
  Button,
  Col,
  Divider,
  Form,
  Row,
  Spin,
  Tag,
  Tooltip,
  Input,
  List,
  Card,
} from "antd";
import React, { useState } from "react";
import { ModalFormTKG, TableTKG } from "tkg-composite-ui";
import BoxCustom from "../../Components/BoxCustom";
import {
  calculateTimeActivity,
  compareFn,
  formatTime,
  formatDateTimeByTimeZone,
} from "../../utils/functions";
import {
  createNewActivity,
  deleteActivity,
  finishTask,
  pauseTimeActivity,
  startTimeActivity,
} from "./service";
import moment from "moment";
import { StyleContainer, StyledHeader, StyledTitle, StyledUser } from "./styled";
import Swal from "sweetalert2";
const { Search } = Input;

const DetailTask = (props) => {
  const {
    timer,
    taskData,
    setTaskData,
    isOpenViewDetail,
    handleCloseViewDetail,
    handleStopTime,
    fetchListData,
    fetchActivityRunning,
    activityData,
    countRef,
    setActivityData,
    loading,
    setLoading,
  } = props;

  const [form] = Form.useForm();
  const [activityIdAction, setActivityIdAction] = useState(0);
  const [activityID, setActivityID] = useState(0);
  const [isOpenModalWarning, setIsOpenModalWarning] = useState(false);
  const [selectedItem, setSelectedItem] = useState({});
  const [validateForm, setValidateForm] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const listTaskPending = taskData?.activities
    .sort(compareFn)
    .filter(
      (item) =>
        !item?.is_finish &&
        (item?.logs?.length === 0 ||
          (item?.logs?.length > 0 &&
            item?.logs[item?.logs?.length - 1]?.end_time))
    );
  const listTaskOngoing = taskData?.activities
    .sort(compareFn)
    .filter(
      (item) =>
        !item?.is_finish &&
        item?.logs?.length > 0 &&
        !item?.logs[item?.logs?.length - 1]?.end_time
    );
  const listTaskCompleted = taskData?.activities
    .sort(compareFn)
    .filter((item) => item?.is_finish);
  // Activity
  const handleCreateActivity = (result) => {
    if (!result.title) {
      setValidateForm(true);
    } else {
      const payload = {
        title: result.title,
        tags: taskData.tags,
        time_tracker_id: taskData.id,
      };
      setLoading(true);
      createNewActivity(payload).then((res) => {
        fetchListData().then((res) => {
          fetchActivityRunning();
          const selectedRow = res.find((item) => item.id === taskData.id);
          setTaskData(selectedRow);
          setLoading(false);
          form.setFieldsValue({ title: undefined });
          Swal.fire({
            position: "top-end",
            icon: "success",
            title: "Your work has been saved",
            showConfirmButton: false,
            timer: 1000,
            timerProgressBar: true,
          });
        });
      });
    }
  };

  const handleOk = async () => {
    setIsOpenModalWarning(false);
    await pauseTimeActivity(activityData?.id);
    setActivityData({});
    await fetchListData(false).then((res) => {
      const selectedRow = res.find((item) => item.id === taskData.id);
      setTaskData(selectedRow);
      clearInterval(countRef.current);
    });

    // clearInterval(countRef.current);
    await startTimeActivity(activityID);
    await fetchListData().then((res) => {
      const selectedRow = res.find((item) => item.id === taskData.id);
      setTaskData(selectedRow);
      fetchActivityRunning().then((res) => setActivityIdAction(res?.id));
    });
  };
  const handleCancel = () => {
    setIsOpenModalWarning(false);
  };
  const handleStartTime = async (record) => {
    if (activityData?.logs) {
      setIsOpenModalWarning(true);
      setActivityID(record.id);
    } else {
      setActivityIdAction(record.id);

      // clearInterval(countRef.current);
      await startTimeActivity(record.id);
      fetchListData().then((res) => {
        const selectedRow = res.find((item) => item.id === taskData.id);
        setTaskData(selectedRow);
      });
      await fetchActivityRunning();
    }
  };
  const handlePauseTime = async (record, callFetchListData = true) => {
    setActivityIdAction(record.id);
    await pauseTimeActivity(activityData?.id);
    setActivityData({});
    if (callFetchListData) {
      await fetchListData().then((res) => {
        const selectedRow = res.find((item) => item.id === taskData.id);
        setTaskData(selectedRow);
        clearInterval(countRef.current);
      });
    }
  };

  const handleDeleteActivity = (record) => {
    Swal.fire({
      title: "Are you sure to delete this activity?",
      showCancelButton: true,
      confirmButtonText: "Delete",
    }).then((result) => {
      /* Read more about isConfirmed, isDenied below */
      if (result.isConfirmed) {
        setLoading(true);
        deleteActivity(record.id).then((res) => {
          fetchListData().then((res) => {
            fetchActivityRunning();
            const selectedRow = res.find((item) => item.id === taskData.id);
            setTaskData(selectedRow);
            setLoading(false);
            Swal.fire({
              position: "top-end",
              icon: "success",
              title: "Your work has been saved",
              showConfirmButton: false,
              timer: 1000,
              timerProgressBar: true,
            });
          });
        });
      }
    });
    // if (activityData && activityData?.id === record.id) {
  };

  const handleFinishTask = () => {

    const isFinishAll = taskData?.activities?.some(item => !item?.is_finish);

    if(isFinishAll) {
      Swal.fire({
        position: "top-end",
        icon: "info",
        title: "Complete all activities before!",
        showConfirmButton: true,
        // timer: 1500,
        // timerProgressBar: true,
      });
    } else {
      setLoading(true);
    finishTask(taskData.id).then((res) => {
      fetchListData().then((res) => {
        const selectedRow = res.find((item) => item.id === taskData.id);
        setTaskData(selectedRow);
        setLoading(false);
      });
    });
    }
    
  };

  const antIcon = (
    <LoadingOutlined
      style={{
        fontSize: 14,
      }}
      spin
    />
  );
  const columns = [
    {
      title: "Activity Description",
      dataIndex: "title",
      key: "title",
      // width: 350,
      // render: (_, record) => record.logs.length <= 0 ? <Badge offset={[5, 2]} className="font-bold" text={record.title} dot={true}>  </Badge>  : <span className="font-bold" >{record.title}</span>
      render: (_, record) => {
        if (
          activityData &&
          record.id === activityData?.id &&
          !record.is_finish
        ) {
          return (
            <>
              <span className="font-bold">{record.title}</span>

              <span className="pd-left-5">
                <Tag color={"success"}>Running</Tag>
              </span>
            </>
          );
        } else if (
          record?.logs &&
          record?.logs?.length > 0 &&
          !record.is_finish
        ) {
          return (
            <>
              <span className="font-bold">{record.title}</span>

              <span className="pd-left-5">
                <Tag color={"warning"}>Pending</Tag>
              </span>
            </>
          );
        }

        return <span className="font-bold">{record.title}</span>;
      },
    },
    {
      title: "User",
      key: "user",
      dataIndex: "user",
      width: 200,
      render: (_, record) => {
        return (
          <Tag>
            {record.user.first_name} {record.user.surname}
          </Tag>
        );
      },
    },
    {
      title: "Time",
      key: "working_time",
      dataIndex: "working_time",
      width: 200,
      render: (_, record) => {
        if (
          (timer > 0 && activityData.id && record.id === activityData.id) ||
          activityIdAction === record.id
        ) {
          return false ? (
            <Spin indicator={antIcon} />
          ) : (
            <span className="font-bold">
              {formatTime(calculateTimeActivity(record?.logs))}
            </span>
          );
        }
        return (
          <span className="font-bold">
            {formatTime(calculateTimeActivity(record.logs))}
          </span>
        );
      },
    },
    {
      title: "Created At",
      key: "created_at",
      dataIndex: "created_at",
      width: 200,
      render: (_, record) => (
        <span>
          {formatDateTimeByTimeZone(record.created_at, "DD MMM YY - HH:mm")}
        </span>
      ),
    },
    {
      title: "Action",
      key: "action",
      dataIndex: "action",
      width: 200,
      render: (_, record) => {
        return (
          <span>
            {!record.is_finish ? (
              <span>
                {record?.logs?.findLast((item) => item)?.end_time ||
                !record?.logs?.length ? (
                  <Button
                    type="link"
                    icon={
                      <Tooltip
                        title={
                          record?.logs?.length > 0
                            ? "Resume activity"
                            : "Start activity"
                        }
                      >
                        <IconPlayerPlay color="#0288d1" />
                      </Tooltip>
                    }
                    onClick={() =>
                      // isPause
                      //   ? handleResumeTime(record)
                      handleStartTime(record)
                    }
                  />
                ) : (
                  <Button
                    type="link"
                    icon={
                      <Tooltip title="Pause activity">
                        <IconPlayerPause color="#0288d1" />
                      </Tooltip>
                    }
                    onClick={() => handlePauseTime(record)}
                  />
                )}
              </span>
            ) : null}

            {!record.is_finish ? (
              <>
                {record.logs.length > 0 ? (
                  <Button
                    type="link"
                    icon={
                      <Tooltip title="Stop activity">
                        <IconPlayerStop color="#0288d1" />
                      </Tooltip>
                    }
                    onClick={() => handleStopTime(record)}
                  />
                ) : (
                  <Button
                    type="link"
                    icon={
                      <Tooltip title="Delete activity">
                        <IconTrash />
                      </Tooltip>
                    }
                    onClick={() => handleDeleteActivity(record)}
                    danger
                  />
                )}
              </>
            ) : (
              <span>Finished</span>
            )}
          </span>
        );
      },
    },
  ];

  const projectTotalTime = taskData.activities.reduce(
    (projectTotalTime, currentElement) =>
      projectTotalTime + calculateTimeActivity(currentElement?.logs),
    0
  );

  const renderTitle = (record) => {
    if (activityData && record.id === activityData?.id && !record.is_finish) {
      return (
        <>
          <span className="font-bold">{record.title}</span>

          <span className="pd-left-5">
            <Tag color={"success"}>Running</Tag>
          </span>
        </>
      );
    } else if (record?.logs && record?.logs?.length > 0 && !record.is_finish) {
      return (
        <>
          <span className="font-bold">{record.title}</span>

          <span className="pd-left-5">
            <Tag color={"warning"}>Pending</Tag>
          </span>
        </>
      );
    }

    return <span className="font-bold">{record.title}</span>;
  };
  const renderTimer = (record) => {
    if (
      (timer > 0 && activityData.id && record.id === activityData.id) ||
      activityIdAction === record.id
    ) {
      return false ? (
        <Spin indicator={antIcon} />
      ) : (
        <span className="font-bold">
          {formatTime(calculateTimeActivity(record?.logs))}
        </span>
      );
    }
    return (
      <span className="font-bold">
        {formatTime(calculateTimeActivity(record.logs))}
      </span>
    );
  };
  const renderAction = (record) => {
    return (
      <span>
        {!record.is_finish ? (
          <span>
            {record?.logs?.findLast((item) => item)?.end_time ||
            !record?.logs?.length ? (
              <Button
                type="link"
                icon={
                  <Tooltip
                    title={
                      record?.logs?.length > 0
                        ? "Resume activity"
                        : "Start activity"
                    }
                  >
                    <IconPlayerPlay color="#0288d1" />
                  </Tooltip>
                }
                onClick={() =>
                  // isPause
                  //   ? handleResumeTime(record)
                  handleStartTime(record)
                }
              />
            ) : (
              <Button
                type="link"
                icon={
                  <Tooltip title="Pause activity">
                    <IconPlayerPause color="#0288d1" />
                  </Tooltip>
                }
                onClick={() => handlePauseTime(record)}
              />
            )}
          </span>
        ) : null}

        {!record.is_finish ? (
          <>
            {record.logs.length > 0 && (
              <Button
                type="link"
                icon={
                  <Tooltip title="Stop activity">
                    <IconPlayerStop color="#0288d1" />
                  </Tooltip>
                }
                onClick={() => handleStopTime(record)}
              />
            )}
          </>
        ) : (
          <span>Finished</span>
        )}
      </span>
    );
  };

  const cardItem = (item) => {
    return (
      <Card key={item?.id} style={{ marginBottom: 10 }}>
        <div>
          <StyledTitle>
            <div>{renderTitle(item)}</div>
            <div>
              <Button
                type="link"
                icon={
                  <Tooltip title="Delete activity">
                    <IconTrash />
                  </Tooltip>
                }
                onClick={() => handleDeleteActivity(item)}
                danger
              />
            </div>
          </StyledTitle>
          <StyledHeader>
            <div>
              <span
                style={{ marginRight: 5 }}
                onClick={() => {
                  setSelectedItem(item);
                  setIsModalOpen(true);
                }}
              >
                <InfoCircleFilled />
              </span>
              {renderTimer(item)}
            </div>
            <StyledUser>
              {item.user.first_name[0]} {item.user.surname[0]}
            </StyledUser>
          </StyledHeader>
        </div>

        <StyledHeader>
          <div>
            <span>
              {formatDateTimeByTimeZone(item?.created_at, "DD MMM YY - HH:mm")}
            </span>
          </div>
          <div>{renderAction(item)}</div>
        </StyledHeader>
      </Card>
    );
  };
  const children = (
    <Spin spinning={loading}>
      <BoxCustom>
        <div style={{ padding: "10px" }}>
          <Row gutter={12}>
            <Col
              span={18}
              style={{ display: "flex", alignItems: "flex-start" }}
            >
              <p className="mb-0 font-bold fs-18">{taskData.title} </p>{" "}
            </Col>
            <Col span={6}>
              <div style={{ textAlign: "right" }}>
                Priority:{" "}
                <Tag
                  color={
                    taskData?.tags[0] === "Medium"
                      ? "warning"
                      : taskData?.tags[0] === "Low"
                      ? "success"
                      : "error"
                  }
                  key={taskData?.tags[0]}
                >
                  {taskData?.tags[0].toUpperCase()}
                </Tag>
                <p style={{ marginTop: "15px" }} className="mb-0">
                  Created at:{" "}
                  {formatDateTimeByTimeZone(taskData.created_at, "DD MMM YYYY")}
                </p>
                <p style={{ marginTop: "15px" }} className="mb-0">
                  Project time: <b>{formatTime(projectTotalTime)}</b>
                </p>
              </div>
            </Col>
          </Row>
          <Divider />

          <div>
            <Form
              form={form}
              // onFinish={handleCreateActivity}
              layout="horizontal"
              style={{ marginBottom: "25px" }}
            >
              <Row gutter={[8, 4]} align="middle">
                <Col span={18}>
                  <Form.Item
                    style={{ marginBottom: 0 }}
                    name="title"
                    // rules={[
                    //   {
                    //     required: true,
                    //     message: "The title field is required.",
                    //   },
                    // ]}
                    help={
                      validateForm && (
                        <span style={{ color: "red" }}>
                          The title field is required.
                        </span>
                      )
                    }
                    validateStatus={validateForm && "error"}
                  >
                    <Search
                      enterButton="Add Activity"
                      placeholder="Things to do..."
                      size="large"
                      onChange={(e) => e.target.value && setValidateForm(false)}
                      onSearch={() =>
                        handleCreateActivity(form.getFieldsValue())
                      }
                    />
                  </Form.Item>
                </Col>

                {taskData.activities.length > 0 && (
                  <Col
                    span={4}
                    style={{
                      margin: "0 0 auto 0",
                      display: "flex",
                      justifyContent: "end",
                    }}
                  >
                    {!taskData?.finished_at ? (
                      <Button
                        type="primary"
                        danger
                        size="large"
                        onClick={handleFinishTask}
                      >
                        Finish Main Task
                      </Button>
                    ) : (
                      <span>
                        Finished at:{" "}
                        {formatDateTimeByTimeZone(
                          taskData.finished_at,
                          "DD MMM YYYY HH:mm"
                        )}
                      </span>
                    )}
                  </Col>
                )}
              </Row>
            </Form>
          </div>

          <StyleContainer>
            {/* <TableTKG
              dataSource={taskData.activities.sort(compareFn)}
              columns={columns}
              isShowedFullData={true}
              scroll={{ x: "auto" }}
              rowKey="id"
              expandable={{
                expandedRowRender: (record) => (
                  <List
                    bordered
                    dataSource={record?.logs.filter(
                      (item) => item.end_time != null
                    )}
                    renderItem={(item, index) => (
                      <List.Item>
                        <div style={{ display: "flex", alignItems: "center" }}>
                          <span>Period {index + 1}: &nbsp;</span>
                          <Tag style={{ marginRight: 0 }}>
                            {formatDateTimeByTimeZone(
                              item?.start_time,
                              "DD MMM YYYY HH:mm:ss"
                            )}{" "}
                          </Tag>
                          <IconArrowNarrowRight style={{ margin: "0 5px" }} />
                          <Tag style={{ marginRight: 0 }}>
                            {formatDateTimeByTimeZone(
                              item?.end_time,
                              "DD MMM YYYY HH:mm:ss"
                            )}
                          </Tag>
                          <IconMinusVertical style={{ margin: "0 5px" }} />
                          <Tag>
                            {formatTime(
                              moment(item.end_time).diff(
                                moment(item?.start_time),
                                "seconds"
                              )
                            )}
                          </Tag>
                        </div>
                      </List.Item>
                    )}
                  />
                ),
                rowExpandable: (record) => record.logs.length > 0,
              }}
            /> */}

            <Row gutter={16}>
              <Col span={8}>
                <Card
                  title="Assigned/Pending"
                  headStyle={{ background: "#fcae26", fontSize: "20px" }}
                  bodyStyle={{
                    minHeight: 200,
                    maxHeight: "1000px",
                    overflowY: "auto"
                  }}
                >
                  {listTaskPending?.length > 0 &&
                    listTaskPending?.map((item) => cardItem(item))}
                </Card>
              </Col>

              <Col span={8}>
                <Card title="In Process" headStyle={{ background: "#87d068", fontSize: "20px" }}
                  bodyStyle={{
                    minHeight: 200,
                    maxHeight: "1000px",
                    overflowY: "auto"
                  }}>
                  {listTaskOngoing?.length > 0 &&
                    listTaskOngoing?.map((item) => cardItem(item))}
                </Card>
              </Col>
              <Col span={8}>
                <Card title="Complete" headStyle={{ background: "#108ee9", fontSize: "20px" }}
                  bodyStyle={{
                    minHeight: 200,
                    maxHeight: "1000px",
                    overflowY: "auto"
                  }}>
                  {listTaskCompleted?.length > 0 &&
                    listTaskCompleted?.map((item) => cardItem(item))}
                </Card>
              </Col>
            </Row>
          </StyleContainer>
        </div>
      </BoxCustom>
    </Spin>
  );

  return (
    <>
      {" "}
      <ModalFormTKG
        title={`Task #${taskData.id} - ${
          taskData.finished_at ? "Finished" : "In progress"
        }`}
        open={isOpenViewDetail}
        onClose={handleCloseViewDetail}
        children={children}
      />
      <Modal
        title="Basic Modal"
        open={isOpenModalWarning}
        onOk={handleOk}
        onCancel={handleCancel}
      >
        <span>
          Activity{" "}
          <Tag style={{ wordBreak: "break-all", whiteSpace: "unset" }}>
            {activityData?.title} {"("} {activityData?.time_tracker?.title}{" "}
            {")"}
          </Tag>{" "}
          is running. Do you want to stop it and run current activity ?{" "}
        </span>
      </Modal>
      <Modal
        title="History Logs"
        width={650}
        open={isModalOpen}
        footer={null}
        onCancel={() => {
          setIsModalOpen(false);
          setSelectedItem({});
        }}
      >
        <div>
          {Object.keys(selectedItem || {}) && selectedItem?.logs?.length > 0 ? (
            <List
              bordered
              dataSource={selectedItem?.logs?.filter(
                (item) => item?.end_time != null
              )}
              renderItem={(item, index) => (
                <List.Item>
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <span>Period {index + 1}: &nbsp;</span>
                    <Tag style={{ marginRight: 0 }}>
                      {formatDateTimeByTimeZone(
                        item?.start_time,
                        "DD MMM YYYY HH:mm:ss"
                      )}{" "}
                    </Tag>
                    <IconArrowNarrowRight style={{ margin: "0 5px" }} />
                    <Tag style={{ marginRight: 0 }}>
                      {formatDateTimeByTimeZone(
                        item?.end_time,
                        "DD MMM YYYY HH:mm:ss"
                      )}
                    </Tag>
                    <IconMinusVertical style={{ margin: "0 5px" }} />
                    <Tag>
                      {formatTime(
                        moment(item?.end_time).diff(
                          moment(item?.start_time),
                          "seconds"
                        )
                      )}
                    </Tag>
                  </div>
                </List.Item>
              )}
            />
          ) : (
            <span>No data</span>
          )}
        </div>
      </Modal>
    </>
  );
};

export default DetailTask;
