import { API_TIMEOFF } from "../../api-url/timeoff";

export const TIMEOFF_ENDPOIND = {
  LIST_TIMEOFF: API_TIMEOFF.GET_LIST_DATA_TIME_OFF,
  ADD_NEW_REQUEST: API_TIMEOFF.ADD_NEW_REQUEST,
  UPDATE_REQUEST: API_TIMEOFF.UPDATE_REQUEST,
  DELETE_REQUEST: API_TIMEOFF.DELETE_REQUEST,
  RESPONSE_REQUEST: API_TIMEOFF.RESPONSE_REQUEST,
  DETAIL_REQUEST: API_TIMEOFF.DETAIL_REQUEST,
  GET_RULES_PAYOFF: API_TIMEOFF.GET_RULES_PAYOFF,
};
