// import { BASE_API_URL_LO_TRINH_NHAN_VIEN } from "@utils/constants";

const BASE_API_URL_TIME_TRACKER = process.env.REACT_APP_TIME_REPORT_API_URL;
export const API_CLOCK_IN_CLOCK_OUT = {
  CLOCK_IN: `${BASE_API_URL_TIME_TRACKER}/api/work-hours/clock-in`,
  CLOCK_OUT: `${BASE_API_URL_TIME_TRACKER}/api/work-hours/clock-out`,
  BREAK: `${BASE_API_URL_TIME_TRACKER}/api/work-hours/break`,
  LUNCH_BREAK: `${BASE_API_URL_TIME_TRACKER}/api/work-hours/lunch-break`,
  RESUME_WORK: `${BASE_API_URL_TIME_TRACKER}/api/work-hours/resume-work`,
  LOG_TO_DAY: `${BASE_API_URL_TIME_TRACKER}/api/work-hours/today`,
  RESET_TIME: `${BASE_API_URL_TIME_TRACKER}/api/work-hours/clear`,
  TOTAL_CLOCK_IN_OUT: `${BASE_API_URL_TIME_TRACKER}/api/work-hours/total`,

  // Request workout
  REQUEST_WORKOUT: `${BASE_API_URL_TIME_TRACKER}/api/work-hours/request/store`,
  UPDATE_REQUEST_WORKOUT: `${BASE_API_URL_TIME_TRACKER}/api/work-hours/request/update`,
  DELETE_REQUEST_WORKOUT: `${BASE_API_URL_TIME_TRACKER}/api/work-hours/request/delete`,
  LIST_REQUEST_WORKOUT: `${BASE_API_URL_TIME_TRACKER}/api/work-hours/request`,
  REJECT_REQUEST_WORKOUT: `${BASE_API_URL_TIME_TRACKER}/api/work-hours/request/reject`,
  ACCEPT_REQUEST_WORKOUT: `${BASE_API_URL_TIME_TRACKER}/api/work-hours/request/accept`,

  // List workout
  LIST_ALL_WORKOUT: `${BASE_API_URL_TIME_TRACKER}/api/report/list-work-hours`
};
