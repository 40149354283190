import React from "react";
import ReactDOM from "react-dom/client";
import "antd/dist/reset.css";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { ConfigProvider } from "antd";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  // <React.StrictMode>
  <ConfigProvider
    theme={{
      token: {
        fontFamily: "Nunito Sans, sans-serif",
        colorPrimary: "#0288d1",
        colorBorder: "#e3e6ef",
        borderRadius: 8,
        colorTextPlaceholder: "#b7c2c5",
        controlHeightLG: 42,
        fontSizeLG: 14,
      },
    }}
  >
    <App />
  </ConfigProvider>
  // </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
