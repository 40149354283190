import { Button, Checkbox, Col, Form, Radio, Row, TimePicker } from "antd";
import React, { useEffect, useState } from "react";
import {
  ModalFormTKG,
  InputTKG,
  InputNumberTKG,
  SelectTKG,
} from "tkg-composite-ui";
import BoxCustom from "../../../Components/BoxCustom";
import { DatePickerTKG } from "tkg-composite-ui/dist/components/Select/DatePickerTKG";
import dayjs from "dayjs";
import Swal from "sweetalert2";

const listRepeatDays = [
  {
    value: "everyDay",
    label: "Every Day",
  },
  {
    value: "everyMonday",
    label: "Every Moday",
  },
  {
    value: "everyTuesday",
    label: "Every Tuesday",
  },
  {
    value: "everyWednesday",
    label: "Every Wednesday",
  },
  {
    value: "everyThurday",
    label: "Every Thurday",
  },
  {
    value: "everyFriday",
    label: "Every Friday",
  },
];
const listPriority = [
  {
    value: "high",
    label: "High",
  },
  {
    value: "medium",
    label: "Medium",
  },
  {
    value: "low",
    label: "Low",
  },
];

const isDateBefore = (start, end) => {
  return dayjs(end).add(30, "minute").isBefore(dayjs(start), "minute");
};
const isSameDate = (start, end) => {
  return dayjs(start).isSame(dayjs(end), "date");
};
const CreateMeetingModal = (props) => {
  const {
    isModalOpen,
    handleCreateMeeting,
    handleCreateProject,
    handleCancel,
    form,
    listUser,
    selectedSlot,
  } = props;
  const user = JSON.parse(sessionStorage.getItem("user"));
  const [projectMode, setProjectMode] = useState("meeting");
  const [allDay, setAllDay] = useState(false);
  const [startDate, setStartDate] = useState(dayjs());
  const [endDate, setEndDate] = useState(dayjs());
  const [startTime, setStartTime] = useState(dayjs());
  const [endTime, setEndTime] = useState(dayjs());
  useEffect(() => {
    isDateBefore(selectedSlot.start, selectedSlot.end);
    form.setFieldsValue({
      start_date: dayjs(selectedSlot.start),
      start_time: isSameDate(selectedSlot.end, selectedSlot.start)
        ? dayjs(selectedSlot.start)
        : dayjs(selectedSlot.start).add(8, "hour"),
      end_date: isSameDate(selectedSlot.end, selectedSlot.start)
        ? dayjs(selectedSlot.end)
        : dayjs(selectedSlot.start),
      end_time: isSameDate(selectedSlot.end, selectedSlot.start)
        ? dayjs(selectedSlot.end)
        : dayjs(selectedSlot.start).add(8.5, "hour"),
    });
  }, [selectedSlot]);

  useEffect(() => {
    console.log(startDate, endDate, isDateBefore(startDate, endDate));
    if (isDateBefore(startDate, endDate)) {
      Swal.fire("The end date you entered occurs before the start date.");
    }
  }, [startDate, endDate]);
  const onChangeForm = (e) => {
    setProjectMode(e.target.value);
  };
  const onChangeModeEvent = (e) => {
    setAllDay(e.target.checked);
  };
  const onChangeStartDate = (date, stringDate) => {
    setStartDate(date);
  };
  const onChangeEndDate = (date, stringDate) => {
    setEndDate(date);
  };
  return (
    <ModalFormTKG
      title={projectMode === "project" ? "Create Project" : "Create Meeting"}
      open={isModalOpen}
      footer={false}
      onClose={handleCancel}
    >
      <BoxCustom>
        <Row style={{ margin: "10px 0" }}>
          <Radio.Group onChange={onChangeForm} defaultValue="meeting">
            <Radio.Button value="meeting">Meeting</Radio.Button>
            <Radio.Button value="project">Project</Radio.Button>
          </Radio.Group>
        </Row>

        {projectMode === "meeting" ? (
          <Form
            layout="vertical"
            form={form}
            onFinish={handleCreateMeeting}
            initialValues={{
              start_date: dayjs(selectedSlot.start),
              start_time: isSameDate(selectedSlot.end, selectedSlot.start)
                ? dayjs(selectedSlot.start)
                : dayjs(selectedSlot.start).add(8, "hour"),
              end_date: isSameDate(selectedSlot.end, selectedSlot.start)
                ? dayjs(selectedSlot.end)
                : dayjs(selectedSlot.start),
              end_time: isSameDate(selectedSlot.end, selectedSlot.start)
                ? dayjs(selectedSlot.end)
                : dayjs(selectedSlot.start).add(8, "hour"),
            }}
          >
            <Row gutter={[8, 4]}>
              <Col span={24}>
                <Form.Item
                  name="topic"
                  label="Topic"
                  rules={[
                    { required: true, message: "Please input your topic!" },
                  ]}
                >
                  <InputTKG />
                </Form.Item>
              </Col>
              <Col span={24}>
                <Form.Item
                  name="location"
                  label="Location"
                  rules={[
                    { required: true, message: "Please input your location!" },
                  ]}
                >
                  <InputTKG />
                </Form.Item>
              </Col>
              <Col span={24}>
                <Form.Item name="zoom_link" label="Zoom Link">
                  <InputTKG />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={[8, 4]} align={"left"}>
              <Col span={7}>
                <Row gutter={[8, 4]}>
                  {" "}
                  <Col span={16}>
                    <Form.Item
                      name="start_date"
                      label="Start Time"
                      rules={[
                        {
                          required: true,
                          message: "Please choose your start date!",
                        },
                      ]}
                    >
                      {/* <RangePickerTKG allowClear={false} format="DD-MM-YYYY" /> */}
                      <DatePickerTKG
                        format="DD-MM-YYYY"
                        onChange={onChangeStartDate}
                      />
                    </Form.Item>
                  </Col>
                  <Col span={8}>
                    <Form.Item name="start_time" label=" " trigger="onSelect">
                      <TimePicker
                        format="HH:mm"
                        minuteStep={30}
                        size="large"
                        disabled={allDay}
                        value={startTime}
                        onSelect={(time) => {
                          // Making it controlled, but this is NOT required
                          setStartTime(time);
                        }}
                      />
                    </Form.Item>
                  </Col>
                </Row>
                <Row gutter={[8, 4]}>
                  {" "}
                  <Col span={16}>
                    <Form.Item
                      name="end_date"
                      label="End Time"
                      rules={[
                        {
                          required: true,
                          message: "Please choose your end date!",
                        },
                      ]}
                    >
                      <DatePickerTKG
                        format="DD-MM-YYYY"
                        onChange={onChangeEndDate}
                      />
                    </Form.Item>
                  </Col>
                  <Col span={8}>
                    <Form.Item name="end_time" label=" " trigger="onSelect">
                      <TimePicker
                        format="HH:mm"
                        minuteStep={30}
                        size="large"
                        disabled={allDay}
                        value={endTime}
                        onSelect={(time) => {
                          // Making it controlled, but this is NOT required
                          setEndTime(time);
                        }}
                      />
                    </Form.Item>
                  </Col>
                </Row>
              </Col>
              <Col span={7}>
                <Form.Item name="all_day" label=" " valuePropName="checked">
                  <Checkbox onChange={onChangeModeEvent} checked={allDay}>
                    All day event
                  </Checkbox>
                </Form.Item>
              </Col>
            </Row>

            <Row gutter={[8, 4]}>
              {user.role_id === 1 ? (
                <Col span={12}>
                  <Form.Item
                    name="assign_meeting"
                    label="Assign Meeting"
                    rules={[
                      {
                        required: true,
                        message: "Please choose your assign meeting!",
                      },
                    ]}
                  >
                    <SelectTKG
                      form={form}
                      mode="multiple"
                      maxTagCount={2}
                      allowClear
                      placeholder="Select members"
                      showSearch
                      optionFilterProp="children"
                      filterOption={(input, option) =>
                        (option?.label ?? "")
                          .toLowerCase()
                          .includes(input.toLowerCase())
                      }
                      options={listUser.map((item) => ({
                        label: `${item?.first_name} ${item?.surname} ( ${item?.group} - ${item?.position} ) `,
                        value: item.id,
                      }))}
                    />
                  </Form.Item>
                </Col>
              ) : null}
              <Col span={12}>
                <Form.Item
                  name="repeat_days"
                  label="Repeat days"
                  rules={[
                    {
                      required: true,
                      message: "Please choose your repeat days!",
                    },
                  ]}
                >
                  <SelectTKG
                    form={form}
                    // placeholder="Select tags"
                    showSearch
                    optionFilterProp="children"
                    filterOption={(input, option) =>
                      (option?.label ?? "")
                        .toLowerCase()
                        .includes(input.toLowerCase())
                    }
                    options={listRepeatDays.map((item) => ({
                      label: item.label,
                      value: item.value,
                    }))}
                  />
                </Form.Item>
              </Col>
            </Row>

            <Row gutter={[4, 4]} justify="end">
              <Col span={4}>
                <Button
                  type="primary"
                  className="btnTKGDeny"
                  ghost
                  onClick={handleCancel}
                  style={{ marginRight: "10px" }}
                >
                  Cancel
                </Button>

                <Button
                  type="primary"
                  className="btnTKGAction"
                  htmlType="submit"
                >
                  Submit
                </Button>
              </Col>
            </Row>
          </Form>
        ) : (
          <Form
            layout="vertical"
            form={form}
            onFinish={handleCreateProject}
            initialValues={{
              start_date: dayjs(selectedSlot.start),
              start_time: isSameDate(selectedSlot.end, selectedSlot.start)
                ? dayjs(selectedSlot.start)
                : dayjs(selectedSlot.start).add(8, "hour"),
              end_date: isSameDate(selectedSlot.end, selectedSlot.start)
                ? dayjs(selectedSlot.end)
                : dayjs(selectedSlot.start),
              end_time: isSameDate(selectedSlot.end, selectedSlot.start)
                ? dayjs(selectedSlot.end)
                : dayjs(selectedSlot.start).add(8, "hour"),
            }}
          >
            <Row gutter={[8, 4]}>
              <Col span={24}>
                <Form.Item
                  name="title"
                  rules={[
                    { required: true, message: "Please input your title!" },
                  ]}
                >
                  <InputTKG placeholder={"What are you working on?"} />
                </Form.Item>
              </Col>
            </Row>{" "}
            <Row gutter={[8, 4]} align={"left"}>
              <Col span={7}>
                <Row gutter={[8, 4]}>
                  {" "}
                  <Col span={16}>
                    <Form.Item
                      name="start_date"
                      label="Start Time"
                      rules={[
                        {
                          required: true,
                          message: "Please choose your start date!",
                        },
                      ]}
                    >
                      {/* <RangePickerTKG allowClear={false} format="DD-MM-YYYY" /> */}
                      <DatePickerTKG
                        format="DD-MM-YYYY"
                        onChange={onChangeStartDate}
                      />
                    </Form.Item>
                  </Col>
                  <Col span={8}>
                    <Form.Item name="start_time" label=" " trigger="onSelect">
                      <TimePicker
                        format="HH:mm"
                        minuteStep={30}
                        size="large"
                        disabled={allDay}
                        value={startTime}
                        onSelect={(time) => {
                          // Making it controlled, but this is NOT required
                          setStartTime(time);
                        }}
                      />
                    </Form.Item>
                  </Col>
                </Row>
                <Row gutter={[8, 4]}>
                  {" "}
                  <Col span={16}>
                    <Form.Item
                      name="end_date"
                      label="End Time"
                      rules={[
                        {
                          required: true,
                          message: "Please choose your end date!",
                        },
                      ]}
                    >
                      <DatePickerTKG
                        format="DD-MM-YYYY"
                        onChange={onChangeEndDate}
                      />
                    </Form.Item>
                  </Col>
                  <Col span={8}>
                    <Form.Item name="end_time" label=" " trigger="onSelect">
                      <TimePicker
                        format="HH:mm"
                        minuteStep={30}
                        size="large"
                        disabled={allDay}
                        value={endTime}
                        onSelect={(time) => {
                          // Making it controlled, but this is NOT required
                          setEndTime(time);
                        }}
                      />
                    </Form.Item>
                  </Col>
                </Row>
              </Col>
              <Col span={7}>
                <Form.Item name="all_day" label=" " valuePropName="checked">
                  <Checkbox onChange={onChangeModeEvent} checked={allDay}>
                    All day event
                  </Checkbox>
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={[8, 4]}>
              <Col span={7}>
                <Form.Item
                  name="hours_per_day"
                  label="Hours per day"
                  rules={[
                    {
                      required: true,
                      message: "Please input your hours per day!",
                    },
                  ]}
                >
                  <InputNumberTKG />
                </Form.Item>
              </Col>
              <Col span={7}>
                <Form.Item
                  name="working_days"
                  label="Working days"
                  rules={[
                    {
                      required: true,
                      message: "Please input your working days!",
                    },
                  ]}
                >
                  <InputNumberTKG />
                </Form.Item>
              </Col>{" "}
              <Col span={10}>
                <Form.Item
                  name="estimate"
                  label="Estimate working hours"
                  rules={[
                    {
                      required: true,
                      message: "Please input your estimate working hours!",
                    },
                  ]}
                >
                  <InputNumberTKG />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={[8, 4]}>
              {user.role_id === 1 ? (
                <Col span={10}>
                  <Form.Item
                    name="assign_project"
                    label="Assign Project"
                    rules={[
                      {
                        required: true,
                        message: "Please choose your assign project!",
                      },
                    ]}
                  >
                    <SelectTKG
                      form={form}
                      mode="multiple"
                      maxTagCount={2}
                      allowClear
                      placeholder="Select members"
                      showSearch
                      optionFilterProp="children"
                      filterOption={(input, option) =>
                        (option?.label ?? "")
                          .toLowerCase()
                          .includes(input.toLowerCase())
                      }
                      options={listUser.map((item) => ({
                        label: `${item?.first_name} ${item?.surname} ( ${item?.group} - ${item?.position} ) `,
                        value: item.id,
                      }))}
                    />
                  </Form.Item>
                </Col>
              ) : null}

              <Col span={4}>
                <Form.Item
                  name="priority"
                  label="Priority"
                  rules={[
                    { required: true, message: "Please input your priority!" },
                  ]}
                >
                  <SelectTKG
                    form={form}
                    // placeholder="Select tags"
                    showSearch
                    optionFilterProp="children"
                    filterOption={(input, option) =>
                      (option?.label ?? "")
                        .toLowerCase()
                        .includes(input.toLowerCase())
                    }
                    options={listPriority.map((item) => ({
                      label: item.label,
                      value: item.value,
                    }))}
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={[4, 4]} justify="end">
              <Col span={4}>
                <Button
                  type="primary"
                  className="btnTKGDeny"
                  ghost
                  onClick={handleCancel}
                  style={{ marginRight: "10px" }}
                >
                  Cancel
                </Button>

                <Button
                  type="primary"
                  className="btnTKGAction"
                  htmlType="submit"
                >
                  Submit
                </Button>
              </Col>
            </Row>
          </Form>
        )}
      </BoxCustom>
    </ModalFormTKG>
  );
};

export default CreateMeetingModal;
