import React from "react";
import { Modal, Timeline, Divider, TimePicker, Row, Col, Tag } from "antd";
import dayjs from "dayjs";
import "../clockInOutReport.scss";
import moment from "moment";
import { ClockCircleOutlined } from "@ant-design/icons";
import { ACTION } from "../../../utils/constants";
import { compareFnTimePopUp } from "../../../utils/functions";
import { InputTKG } from "tkg-composite-ui";

const calculateTimeInMins = (startTime, endTime) => {
  const timer = dayjs(endTime).diff(dayjs(startTime), "minute");
  return timer + 1;
};

const WorkoutModal = (props) => {
  const { isOpenWorkout, dataWorkout, handleCloseModal } = props;
  const logsBreak = dataWorkout?.logs
    .filter((item) => item.action !== "Clock Out" && item.action !== "Clock In")
    .sort(compareFnTimePopUp);

  const getListTimeLine = () => {
    const listData = [];
    if (logsBreak && logsBreak.length > 0) {
      logsBreak.reduce((prev, currentValue) => {
        if (currentValue.action === ACTION.BREAK) {
          listData.push({
            id: currentValue.id,
            children: (
              <>
                Break{" "}
                <Tag color="default">
                  {moment(currentValue?.created_at).format(
                    "YYYY-MM-DD HH:mm:ss"
                  )}
                </Tag>
              </>
            ),

            dot: (
              <ClockCircleOutlined
                style={{
                  fontSize: "16px",
                }}
              />
            ),
            color: "red",
          });
          return currentValue;
        }
        if (currentValue.action === ACTION.LUNCH_BREAK) {
          listData.push({
            id: currentValue.id,

            children: (
              <>
                Lunch break{" "}
                <Tag color="default">
                  {moment(currentValue?.created_at).format(
                    "YYYY-MM-DD HH:mm:ss"
                  )}
                </Tag>
              </>
            ),
            dot: (
              <ClockCircleOutlined
                style={{
                  fontSize: "16px",
                }}
              />
            ),
            color: "red",
          });
          return currentValue;
        }
        if (currentValue.action === ACTION.RESUME_WORK) {
          listData.push({
            id: currentValue.id,

            children: (
              <>
                Resume break{" "}
                <Tag color="default">
                  {moment(currentValue?.created_at).format(
                    "YYYY-MM-DD HH:mm:ss"
                  )}
                </Tag>
                <Tag>
                  {calculateTimeInMins(
                    prev?.created_at,
                    currentValue?.created_at
                  )}{" "}
                  mins
                </Tag>
              </>
            ),
          });
          return currentValue;
        }
      }, {});
    }

    return listData;
  };

  const timeInOutMins = calculateTimeInMins(
    dataWorkout?.start_time,
    dataWorkout?.end_time
  );
  let totalTimeBreak = 0;
  const getTotalTimeBreak = () => {
    dataWorkout?.breaks.forEach((element) => {
      if (element?.end_time) {
        console.log(
          totalTimeBreak,
          calculateTimeInMins(element?.start_time, element?.end_time)
        );
        return (totalTimeBreak += calculateTimeInMins(
          element?.start_time,
          element?.end_time
        ));
      } else if (dataWorkout?.end_time) {
        return (
          totalTimeBreak +
          calculateTimeInMins(element?.start_time, dataWorkout?.end_time)
        );
      } else {
        return totalTimeBreak;
      }
    });

    return totalTimeBreak;
  };

  // console.log(
  //   dayjs(new Date(dataWorkout?.start_time)),
  //   dayjs(new Date(dataWorkout?.end_time)),
  //   "dataWorkout"
  // );
  return (
    <Modal
      title={`Clock In-Out For ${moment(dataWorkout?.start_time).format("LL")}`}
      centered
      open={isOpenWorkout}
      onCancel={handleCloseModal}
      footer={false}
    >
      <>
        <Row gutter={[12, 12]}>
          <Col span={12}>
            <div className="workout-modal">
              <span>Clock in:</span>
              <span>
                <TimePicker
                  placeholder={" "}
                  format={"HH:mm"}
                  clearIcon={false}
                  value={
                    dataWorkout?.start_time
                      ? dayjs(new Date(dataWorkout?.start_time))
                      : null
                  }
                  suffixIcon={<span></span>}
                  disabled
                />
              </span>
            </div>
          </Col>
          <Col span={12}>
            <div className="workout-modal">
              <span>Clock out:</span>
              <span>
                <TimePicker
                  placeholder={" "}
                  format={"HH:mm"}
                  clearIcon={false}
                  value={
                    dataWorkout?.end_time
                      ? dayjs(new Date(dataWorkout?.end_time))
                      : null
                  }
                  suffixIcon={<span></span>}
                  disabled
                />
              </span>
            </div>
          </Col>
        </Row>

        <Divider />
        <Timeline position={"left"} items={getListTimeLine()} />

        {dataWorkout?.end_time ? (
          <>
            <Divider />
            <div>
              <div>
                Working active time: <strong>{timeInOutMins}</strong> mins
              </div>
              <div>
                Break time: <strong>{getTotalTimeBreak()}</strong> mins
              </div>
              <div>
                Total time:{" "}
                <strong>{timeInOutMins - getTotalTimeBreak()}</strong> mins ~{" "}
                <strong>
                  {((timeInOutMins - getTotalTimeBreak()) / 60).toFixed(2)}
                </strong>{" "}
                hours
              </div>
            </div>
          </>
        ) : null}
      </>
    </Modal>
  );
};

export default WorkoutModal;
