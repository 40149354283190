import { Button, Col, Form, Modal, Row } from "antd";

import React from "react";
import { TextEditorTKG } from "tkg-composite-ui";

const RejectRequestModal = (props) => {
  const { isModalOpen, handleConfirm, handleCancel, form } = props;
  return (
    <Modal
      open={isModalOpen}
      footer={false}
      width={"800px"}
      onCancel={handleCancel}
      bodyStyle={{ padding: "50px 20px 15px 20px" }}
    >
      <Form
        form={form}
        layout="vertical"
        onFinish={handleConfirm}
        // initialValues={{
        //   mode: false,
        //   Paid: false,
        // }}
      >
        <Row gutter={[16, 16]}>
          <Col span={24}>
            <Form.Item name="reason_reject" label="Reason">
              <TextEditorTKG form={form} />
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={[4, 4]}>
          <Col span={24} style={{ textAlign: "right" }}>
            <Button type="primary" htmlType="submit" className="btnTKGAction">
              Save
            </Button>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
};

export default RejectRequestModal;
