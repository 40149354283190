import { Endpoint } from "../../utils/endpoind";
import { buildQueryString } from "../../utils/functions";
import { getData } from "../../utils/request";

export const getListUser = async () => {
  return await getData({ url: `${Endpoint.GET_LIST_USER}` });
};
export const getAllWorkOut = async ( payload) => {
  const query = buildQueryString(payload);
  return await getData({
    url: `${Endpoint.LIST_ALL_WORKOUT}?${query}`,
  });
};