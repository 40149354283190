import { Endpoint } from "../../utils/endpoind";
import { buildQueryString } from "../../utils/functions";
import { deleteData, getData, postData, putData } from "../../utils/request";

export const handleCreateTask = async (payload) => {
    return await postData(
    {url: `${Endpoint.STORE}`, payload}
  );
};
export const getListClients = async () => {
    return await getData(
    {url: `${Endpoint.GET_LIST_USER}`}
  );
};
export const getTimeTrackerData = async (payload) => {
  const query = buildQueryString(payload)
    return await getData(
    {url: `${Endpoint.LIST_TIME_TRACKER}?${query}`}
  );
};
export const deleteTask = async (id, payload) => {
    const query = buildQueryString(payload)
    return await deleteData(
    {url: `${Endpoint.DELETE_TASK}/${id}?${query}`}
  );
};
export const updateTask = async (payload, id) => {
    return await putData(
    {url: `${Endpoint.UPDATE_TASK}/${id}`, payload}
  );
};
export const finishTask = async (id) => {
    return await getData(
    {url: `${Endpoint.FINISH_TASK}/${id}`}
  );
};

// Fragment
export const deleteFragmentask = async (id) => {
    return await deleteData(
    {url: `${Endpoint.DELETE_FRAGMENT_TASK}/${id}`}
  );
};
export const handleStartTimeTracker = async (id) => {
    return await getData(
    {url: `${Endpoint.START_TIME_TRACKER}/${id}`}
  );
};
export const handleStopTimeTracker = async () => {
    return await getData(
    {url: `${Endpoint.STOP_TIME_TRACKER}`}
  );
};
export const getFragmentRunning = async () => {
    return await getData(
    {url: `${Endpoint.RUNNING_FRAGMENT}`}
  );
};

// Activity
export const createNewActivity = async (payload) => {
  return await postData(
  {url: `${Endpoint.ACTIVITY_STORE}`, payload}
);
}

export const getActivityRunning = async () => {
  return await getData(
  {url: `${Endpoint.ACTIVITY_RUNNING}`}
);
};

export const deleteActivity = async (id) => {
  return await deleteData(
  {url: `${Endpoint.ACTIVITY_DELETE}/${id}`}
);
};

export const startTimeActivity = async (id) => {
  return await getData(
  {url: `${Endpoint.ACTIVITY_START}/${id}`}
);
};
export const pauseTimeActivity = async (id) => {
  return await getData(
  {url: `${Endpoint.ACTIVITY_PAUSE}/${id}`}
);
};
export const stopTimeActivity = async (id) => {
  return await getData(
  {url: `${Endpoint.ACTIVITY_STOP}/${id}`}
);
};