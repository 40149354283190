import moment from "moment";
import "moment-timezone";

export const removeAccents = (str) => {
  return str
    .normalize("NFD")
    .replace(/[\u0300-\u036f]/g, "")
    .replace(/đ/g, "d")
    .replace(/Đ/g, "D");
};

export const formatTime = (timer, getHour = true) => {
  const getSeconds = `0${timer % 60}`.slice(-2);
  const minutes = `${Math.floor(timer / 60)}`;
  const getMinutes = `0${minutes % 60}`.slice(-2);
  const getHours = `0${Math.floor(timer / 3600)}`.slice(-2);

  return getHour
    ? `${getHours} : ${getMinutes} : ${getSeconds}`
    : `${getMinutes} : ${getSeconds}`;
};
export const formatHours = (totalSeconds) => {
  const totalMinutes = Math.floor(totalSeconds / 60);

  const seconds = totalSeconds % 60;
  const hours = Math.floor(totalMinutes / 60);
  const minutes = totalMinutes % 60;
  return `${hours < 10 ? `0${hours}` : hours}h${
    minutes < 10 ? `0${minutes}` : minutes
  }`;
};

export function buildQueryString(object) {
  if (typeof object !== "object") return "";
  const args = [];
  for (const key in object) {
    destructure(key, object[key]);
  }
  return args.join("&");

  function destructure(key, value) {
    if (key && (value || value === false || value === 0)) {
      if (Array.isArray(value)) {
        for (let i = 0; i < value.length; i++) {
          destructure(key + "[" + i + "]", value[i]);
        }
      } else if (toString(value) === "[object Object]") {
        for (const i in value) {
          destructure(key + "[" + i + "]", value[i]);
        }
      } else
        args.push(
          encodeURIComponent(key) +
            (value != null && value !== "" && value !== undefined
              ? "=" + encodeURIComponent(value)
              : "")
        );
    }
  }
}

export const parseParams = (querystring) => {
  const params = new URLSearchParams(querystring);
  const obj = {};
  for (const key of params.keys()) {
    if (params.getAll(key).length > 1) {
      if (params.get(key) !== "undefined") obj[key] = params.getAll(key);
    } else {
      if (params.get(key) !== "undefined") obj[key] = params.get(key);
    }
  }

  return obj;
};

export const removeUndefinedAttribute = (obj) => {
  const params = {};
  Object.keys(obj).forEach((key) => {
    if (obj[key]) {
      params[key] = obj[key];
    }
    return {};
  });
  return params;
};

export function compareFn(a, b) {
  if (a.id < b.id) {
    return 1;
  }
  if (a.id > b.id) {
    return -1;
  }
  // a must be equal to b
  return 0;
}
export function compareFnTimePopUp(a, b) {
  if (a.id < b.id) {
    return -1;
  }
  if (a.id > b.id) {
    return 1;
  }
  // a must be equal to b
  return 0;
}

export const formatDateTimeByTimeZone = (date, format) => {
  return moment
    .utc(date)
    .tz(Intl.DateTimeFormat().resolvedOptions().timeZone)
    .format(format);
};

export const changeDateTimeZone = (date, timeZone) => {
  if (typeof date === "string") {
    return new Date(
      new Date(date).toLocaleString("en-US", {
        timeZone,
      })
    );
  }

  return new Date(
    date.toLocaleString("en-US", {
      timeZone,
    })
  );
};

export function getDateRangeOfWeek({ weekNo, year }) {
  var d1, numOfdaysPastSinceLastMonday, rangeIsFrom, rangeIsTo;
  d1 = new Date("" + year + "");
  numOfdaysPastSinceLastMonday = d1.getDay() - 1;
  d1.setDate(d1.getDate() - numOfdaysPastSinceLastMonday);
  d1.setDate(d1.getDate() + 7 * (weekNo - d1.getWeek()));
  rangeIsFrom = d1.getMonth() + 1 + "-" + d1.getDate() + "-" + d1.getFullYear();
  d1.setDate(d1.getDate() + 6);
  rangeIsTo = d1.getMonth() + 1 + "-" + d1.getDate() + "-" + d1.getFullYear();
  return [rangeIsFrom, rangeIsTo];
}

export function calculateTimeActivity(logs) {
  // console.log(logs[logs.length - 1].end_time);
  // console.log(changeDateTimeZone(new Date(), "UTC"), "now");
  return logs.reduce(
    (currentValue, element) =>
      moment(element.end_time ?? changeDateTimeZone(new Date(), "UTC")).diff(
        moment(element?.start_time),
        "seconds"
      ) + currentValue,
    0
  );
}

export const getListMonths = (start_date, end_date) => {
  let startDate = moment(start_date);
  let endDate = moment(end_date);
  const result = [];

  if (endDate.isBefore(startDate)) {
    throw "End date must be greated than start date.";
  }

  while (startDate.isBefore(endDate)) {
    result.push(startDate.format("MMMM/YYYY"));
    startDate.add(1, "month");
  }
  return result;
};
