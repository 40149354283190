import { Outlet, useNavigate } from "react-router-dom";
import {
  CalendarOutlined,
  ClockCircleOutlined,
  DashboardOutlined,
  TableOutlined,
} from "@ant-design/icons";
import { Button, Layout, Typography } from "antd";
import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import { HeaderCustom } from "./HeaderCustom";
import { Sidebar } from "./Sidebar";
import "./index.scss";
import Context from "../../utils/context";
import { Timer } from "./clockInClockOut/Timer";
import { encode } from "js-base64";
import Swal from "sweetalert2";
import { formatTime } from "../../utils/functions";
import dayjs from "dayjs";
import {
  getListRequest,
  getUserWorkOut,
} from "../../modules/clockin-out-report/service";

const { Content } = Layout;
const { Title } = Typography;

const bc = new BroadcastChannel("twt");

window.onload = function () {
  window.addEventListener(
    "message",
    function (event) {
      const { data, source } = event;
      bc.postMessage(data);
    },
    false
  );
};
const listMenuTimeandReport = [
  { key: "/dashboard", icon: <DashboardOutlined />, label: "Dashboard" },
  { key: "/timesheet", icon: <CalendarOutlined />, label: "Timesheet" },
  {
    key: "/time-tracker",
    icon: <ClockCircleOutlined />,
    label: "Time tracker",
  },
  { key: "/calendar", icon: <CalendarOutlined />, label: "Calendar" },
  // { key: "/schedule", icon: <CalendarOutlined />, label: "Schedule" },
  // { key: "/expenses", icon: <DashboardOutlined />, label: "Expenses" },
  { key: "/time-off", icon: <DashboardOutlined />, label: "Time off" },
  // { key: "/reports", icon: <DashboardOutlined />, label: "Reports" },
  {
    key: "/clock-in-out-report",
    icon: <DashboardOutlined />,
    label: "Clock In-Out Report",
  },
];
export const LayoutComponent = (props) => {
  const [dateState, setDateState] = useState(new Date());
  const [visible, setVisible] = React.useState(false);
  const context = useContext(Context);
  const user = JSON.parse(sessionStorage.getItem("user"));
  const navigate = useNavigate();

  const [userWorkOut, setUserWorkOut] = useState({});
  const [isOpenNotificationForget, setIsOpenNotificationForget] =
    useState(false);
  const [filterUserWorkHour] = useState({
    start: dayjs().subtract(1, "days").format("YYYY-MM-DD"),
    end: dayjs().subtract(1, "days").format("YYYY-MM-DD"),
    user_id: user.id,
  });
  const [isRequestWorkHour, setIsRequestWorkHour] = useState();

  const [filterConditionsRequest] = useState({
    start_date: dayjs().startOf("month").format("YYYY-MM-DD"),
    end_date: dayjs().endOf("month").format("YYYY-MM-DD"),
    user_id: user.id,
  });

  const fetchListRequest = async () => {
    const res = await getListRequest(filterConditionsRequest).then(
      (res) => res.data.response
    );
    if (res.requests.length > 0) {
      const userRequests = res.requests.find(
        (item) => item.user_id === user.id
      );
      setIsRequestWorkHour(
        userRequests?.date === dayjs().subtract(1, "days").format("YYYY-MM-DD")
      );

      let resWorkOut = await getUserWorkOut(
        filterUserWorkHour.user_id,
        filterUserWorkHour
      ).then((res) => {
        return res.data.response;
      });
      setUserWorkOut(resWorkOut);
    }
  };

  useEffect(() => {
    fetchListRequest();
  }, [filterConditionsRequest, filterUserWorkHour]);

  useEffect(() => {
    setIsOpenNotificationForget(true);
  }, [userWorkOut]);

  useEffect(() => {
    if (isOpenNotificationForget) {
      if (
        userWorkOut?.length <= 0 ||
        (userWorkOut?.length > 0 && !userWorkOut[0]?.start_time)
      ) {
        if (isRequestWorkHour === false) {
          Swal.fire({
            title: "You forgot to clock in yesterday.",
            text: " Please go to Clock in-out report to make a request!",
            showCancelButton: true,
            confirmButtonText: "Yes",
          }).then((result) => {
            /* Read more about isConfirmed, isDenied below */
            if (result.isConfirmed) {
              navigate("/clock-in-out-report");
              context.showListRequestModal();
              context.showRequestModal();
            }
          });
        }
      } else if (userWorkOut?.length > 0 && !userWorkOut[0]?.end_time) {
        if (isRequestWorkHour === false) {
          Swal.fire({
            title: "You forgot to clock out yesterday.",
            text: "Please go to Clock in-out report to make a request!",
            showCancelButton: true,
            confirmButtonText: "Yes",
          }).then((result) => {
            /* Read more about isConfirmed, isDenied below */
            if (result.isConfirmed) {
              navigate("/clock-in-out-report");
              context.showListRequestModal();
              context.showRequestModal();
            }
          });
        }
      }
    }
  }, [isOpenNotificationForget, isRequestWorkHour, userWorkOut]);
  const openDrawer = () => setVisible(!visible);
  const iframe = document.getElementById("iframe-sso");
  const [timeLogout, setTimeLogout] = useState(0);

  bc.onmessage = useCallback(
    (eventMessage) => {
      const data = eventMessage.data;
      if (data.type == "refresh-token") {
        setTimeLogout(0);
        sessionStorage.setItem("accessToken", data.token);
      } else if (data.type == "logout") {
        window.location.href = process.env.REACT_APP_DOMAIN_SSO;
      } else if (data.type == "show-timer-logout") {
        setTimeLogout(data.timer);
      }
    },
    [bc]
  );

  useEffect(() => {
    if (timeLogout) {
      let timerInterval;
      let localTimeLogout = timeLogout;
      Swal.fire({
        position: "top-end",
        icon: "warning",
        title: "Auto logout alert!",
        html: 'The system will automatically logout after  <br> <b style="font-size: 40px"></b>',
        timer: timeLogout * 1000,
        timerProgressBar: true,
        didOpen: () => {
          const b = Swal.getHtmlContainer().querySelector("b");
          timerInterval = setInterval(() => {
            b.textContent = formatTime(localTimeLogout, false);
            localTimeLogout -= 1;
          }, 1000);
        },
        willClose: () => {
          clearInterval(timerInterval);
        },
        allowOutsideClick: false,
        allowEscapeKey: false,
        showCancelButton: true,
        showConfirmButton: true,
        confirmButtonText: "Refresh",
        cancelButtonText: "Close",
      }).then((result) => {
        if (result.isConfirmed) {
          iframe.contentWindow.postMessage(
            { type: "refresh-token-new" },
            iframe.getAttribute("base-src")
          );
        }
        if (result.dismiss === Swal.DismissReason.timer) {
          iframe.contentWindow.postMessage(
            { type: "logout" },
            iframe.getAttribute("base-src")
          );
        }
        setTimeLogout(0);
      });
    } else {
      Swal.close();
    }
  }, [timeLogout]);

  useEffect(() => {
    setInterval(() => {
      setDateState(new Date());
    }, 1000);
  }, []);

  return (
    <Layout>
      <Sidebar
        listMenu={
          user?.role_id === 1
            ? [
                ...listMenuTimeandReport,
                {
                  key: "/manage-timer",
                  icon: <TableOutlined />,
                  label: "Manage Employee Timer",
                },
              ]
            : listMenuTimeandReport
        }
        visible={visible}
        setVisible={setVisible}
      />
      <Layout className="site-layout">
        {!JSON.parse(sessionStorage.getItem("from-iframe")) &&
          sessionStorage.getItem("from-iframe") != null && (
            <div style={{ display: "none" }}>
              <iframe
                id="iframe-sso"
                base-src={`${process.env.REACT_APP_DOMAIN_SSO}`}
                src={`${process.env.REACT_APP_DOMAIN_SSO}verify/${encode(
                  sessionStorage.getItem("accessToken")
                )}?from-iframe=true`}
              ></iframe>
            </div>
          )}

        <HeaderCustom visible={visible} openDrawer={openDrawer} />
        <div
          style={{
            background: "#fff",
            boxShadow:
              "0 1px 2px 0 rgb(0 0 0 / 3%), 0 1px 6px -1px rgb(0 0 0 / 2%), 0 2px 4px 0 rgb(0 0 0 / 2%)",
            padding: 10,
          }}
        >
          <Title
            level={1}
            style={{
              margin: 0,
              // opacity: 0.7,
              height: 40,
              display: "flex",
              justifyContent: "flex-end",
            }}
          >
            <span
              style={{
                cursor: "pointer",
                margin: "auto 0",
                fontSize: "1.8rem",
              }}
              onClick={() => {
                context.showModal();
              }}
            >
              {dateState.toLocaleString("en-US", {
                hour: "numeric",
                minute: "numeric",
                hour12: true,
              })}
            </span>
          </Title>
        </div>
        <Timer
          isOpenModal={context.isOpenModal}
          handleCancel={() => {
            context.hideModal();
          }}
        />

        <Content className="site-layout__content">
          <Outlet />
        </Content>
        {/* <Footer style={{ textAlign: 'center' }}>
        Ant Design ©2023 Created by Ant UED
      </Footer> */}
      </Layout>
    </Layout>
  );
};
