import { Badge, Button, Descriptions, Image, Space, Tag } from "antd";
import dayjs from "dayjs";
import React from "react";
import { Link } from "react-router-dom";
import { ModalFormTKG } from "tkg-composite-ui";
import BoxCustom from "../../../../../Components/BoxCustom";
import { TIME_OFF_STATUS } from "../../../../../utils/constants";
import { time_convert } from "../RequestTab";
const DetailRequestModal = (props) => {
  const {
    openModal,
    handleClose,
    detailData,
    handleOpenReject,
    handleApprove,
  } = props;
  const user = JSON.parse(sessionStorage.getItem("user"));

  const countDay = (start, end) => {
    let startNow = new Date(start);
    let count = 0;
    let diff = dayjs(end).diff(dayjs(start), "day") + 1;
    for (let i = 0; i < diff; i++) {
      const newStart = startNow.setDate(new Date(start).getDate() + i);
      const lastCount = new Date(newStart).getDay();
      if (Number(lastCount) !== 0 && Number(lastCount) !== 6) {
        count = count + 1;
      }
    }
    return count;
  };

  const renderDay = () => {
    let type = "";

    if (
      dayjs(detailData.end_time).diff(dayjs(detailData.start_time), "minutes") /
      60 ===
      0
    ) {
      type = "OneDay";
    } else if (
      0 <
      dayjs(detailData.end_time).diff(
        dayjs(detailData.start_time),
        "minutes"
      ) /
      60 &&
      dayjs(detailData.end_time).diff(dayjs(detailData.start_time), "minutes") /
      60 <
      12
    ) {
      type = "Custom";
    } else {
      type = "MultiDay";
    }

    switch (type) {
      case "OneDay":
        return (
          <span>{dayjs(detailData.start_time).format("DD-MMM-YYYY")}</span>
        );
      case "Custom":
        return (
          <>
            <div>{`${dayjs(detailData.start_time).format(
              "DD-MMM-YYYY"
            )} `}</div>
            <div>
              {`${dayjs(detailData.start_time).format("HH:mm A")} -> ${dayjs(
                detailData.end_time
              ).format("HH:mm A")}`}
            </div>
          </>
        );
      case "MultiDay":
        return (
          <span>{`${dayjs(detailData.start_time).format(
            "DD-MMM-YYYY"
          )} -> ${dayjs(detailData.end_time).format("DD-MMM-YYYY")}`}</span>
        );

      default:
        return "";
    }
  };

  const renderNumberDay = () => {
    let type = "";

    if (
      dayjs(detailData.end_time).diff(dayjs(detailData.start_time), "minutes") /
      60 ===
      0
    ) {
      type = "OneDay";
    } else if (
      0 <
      dayjs(detailData.end_time).diff(
        dayjs(detailData.start_time),
        "minutes"
      ) /
      60 &&
      dayjs(detailData.end_time).diff(dayjs(detailData.start_time), "minutes") /
      60 <
      12
    ) {
      type = "Custom";
    } else {
      type = "MultiDay";
    }

    switch (type) {
      case "OneDay":
        return (
          <span>
            {countDay(detailData.start_time, detailData.end_time)} days
          </span>
        );
      case "Custom":
        return (
          <span>
            {time_convert(
              dayjs(detailData.end_time).diff(
                dayjs(detailData.start_time),
                "minutes"
              )
            )}
          </span>
        );
      case "MultiDay":
        return (
          <span>
            {countDay(detailData.start_time, detailData.end_time)} days
          </span>
        );

      default:
        return "";
    }
  };
  // console.log(detailData?.approver);

  return (
    <ModalFormTKG title="Detail Request" onClose={handleClose} open={openModal}>
      <BoxCustom>
        <Descriptions title="Requested Info" bordered>
          <Descriptions.Item label="Employee">
            <Tag>
              {detailData?.user.first_name} {detailData?.user.surname}
            </Tag>
          </Descriptions.Item>
          <Descriptions.Item label="Billing Mode">
            {detailData?.policy === "No Paid" ? (
              <Tag color="error">{detailData?.policy}</Tag>
            ) : (
              <Tag color="success">{detailData?.policy}</Tag>
            )}
          </Descriptions.Item>
          {/* <Descriptions.Item label="Automatic Renewal">YES</Descriptions.Item> */}
          <Descriptions.Item label="Order time">
            {renderDay()} {renderNumberDay()}
          </Descriptions.Item>
          <Descriptions.Item label="Reason" span={3}>
            <div
              className="view-content"
              dangerouslySetInnerHTML={{
                __html: detailData?.reason,
              }}
            ></div>
          </Descriptions.Item>
          <Descriptions.Item label="Status" span={2}>
            <Badge
              status={
                detailData.status === TIME_OFF_STATUS.PENDING
                  ? "warning"
                  : detailData.status === TIME_OFF_STATUS.APPROVED
                    ? "success"
                    : "error"
              }
              text={
                detailData.status === TIME_OFF_STATUS.PENDING
                  ? "Pending"
                  : detailData.status === TIME_OFF_STATUS.APPROVED
                    ? "Approve"
                    : "Rejected"
              }
            />
            <span>
              {" by "} {detailData?.approver?.first_name}{" "}
              {detailData?.approver?.surname}
            </span>
          </Descriptions.Item>
          <Descriptions.Item label="Created at">
            {dayjs(detailData?.created_at).format("DD-MMM-YYYY HH:mm A")}
          </Descriptions.Item>

          {detailData?.documents ? (
            <Descriptions.Item label="Documents" span={3}>
              <Space>
                {JSON.parse(detailData?.documents)?.map((item, index) => (
                  <a
                    href={`${process.env.REACT_APP_AWS_URL}${item?.path}`}
                    target="_blank"
                  >
                    {item?.name}
                  </a>
                ))}
              </Space>
            </Descriptions.Item>
          ) : null}

          {user.role_id === 1 &&
            detailData.status === TIME_OFF_STATUS.PENDING && (
              <Descriptions.Item span={3} contentStyle={{ textAlign: "right" }}>
                <Space>
                  <Button
                    type="primary"
                    className="btnTKGAction"
                    ghost
                    onClick={handleApprove}
                  >
                    Approve
                  </Button>
                  <Button
                    type="primary"
                    className="btnTKGDeny"
                    ghost
                    onClick={handleOpenReject}
                  >
                    Reject
                  </Button>
                </Space>
              </Descriptions.Item>
            )}
        </Descriptions>
      </BoxCustom>
    </ModalFormTKG>
  );
};

export default DetailRequestModal;
