import React from "react";
import { Drawer, Layout, Menu } from "antd";
import { Link, useNavigate } from "react-router-dom";
import "./index.scss";
const { Sider } = Layout;

export const Sidebar = (props) => {
  const { listMenu, visible, setVisible } = props;
  const navigate = useNavigate();
  const goToPage = (e) => {
    navigate(e.key);
  };
  return (
    <>
      <Drawer
        title={false}
        placement={"left"}
        closable={false}
        onClose={() => setVisible(false)}
        open={visible}
        key={"left"}
        width={270}
      >
        <Sider className="siderTablet" width={270}>
          <div className={"sider__menu-side-bar"}>
            <Menu mode="inline" items={listMenu} onClick={goToPage} />
          </div>
        </Sider>
      </Drawer>
      <Sider className="sider" width={270}>
        <div className={"sider__menu-side-bar"}>
          <Menu mode="inline" items={listMenu} onClick={goToPage} />
        </div>
      </Sider>
    </>
  );
};
