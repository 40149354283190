import { AUTHENTICATED_USER_ENDPOIND } from "./endpoind/endpoindAuthenticated";
import { CALENDAR_ENDPOIND } from "./endpoind/endpoindCalendar";
import { DASHBOARD_ENDPOIND } from "./endpoind/endpoindDashboard";
import { TIMEOFF_ENDPOIND } from "./endpoind/endpoindTimeoff";
import { TIMESHEET_ENDPOIND } from "./endpoind/endpoindTimesheet";
import { TIME_TRACKER_ENDPOIND } from "./endpoind/endpoindTimeTracker";
import { CLOCKIN_CLOCKOUT_ENDPOIND } from "./endpoind/endpoindWorkout";

export const Endpoint = {
  //Clock in - Clock out
  ...CLOCKIN_CLOCKOUT_ENDPOIND,
  ...AUTHENTICATED_USER_ENDPOIND,
  ...TIME_TRACKER_ENDPOIND,
  ...TIMESHEET_ENDPOIND,
  ...TIMEOFF_ENDPOIND,
  ...DASHBOARD_ENDPOIND,
  ...CALENDAR_ENDPOIND,
};
