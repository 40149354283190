import { Endpoint } from "../../utils/endpoind";
import { buildQueryString } from "../../utils/functions";
import { deleteData, getData, postData, putData } from "../../utils/request";

export const getListUser = async () => {
  return await getData({ url: `${Endpoint.GET_LIST_USER}` });
};
export const getUserWorkOut = async (user_id, payload) => {
  const query = buildQueryString(payload);
  return await getData({
    url: `${Endpoint.GET_USER_WORK_OUT}/${user_id}?${query}`,
  });
};
export const getListRequest = async (payload) => {
  const query = buildQueryString(payload);
  return await getData({
    url: `${Endpoint.LIST_REQUEST_WORKOUT}?${query}`,
  });
};
export const requestWorkHours = async (payload) => {
  return await postData({ url: `${Endpoint.REQUEST_WORKOUT}`, payload });
};
export const acceptRequest = async (id) => {
  return await getData({ url: `${Endpoint.ACCEPT_REQUEST_WORKOUT}/${id}` });
};
export const rejectRequest = async (payload, id) => {
  return await postData({
    url: `${Endpoint.REJECT_REQUEST_WORKOUT}/${id}`,
    payload,
  });
};
export const updateRequestWorkHours = async (payload, id) => {
  return await putData({
    url: `${Endpoint.UPDATE_REQUEST_WORKOUT}/${id}`,
    payload,
  });
};
export const deleteRequest = async (id) => {
  return await deleteData({ url: `${Endpoint.DELETE_REQUEST_WORKOUT}/${id}` });
};
