import { Button, Modal, Space, Spin, Tag, Timeline } from "antd";
import moment from "moment";
import "../index.scss";
import React, { useEffect, useRef, useState } from "react";
import Swal from "sweetalert2";
import "sweetalert2/src/sweetalert2.scss";
import {
  getLogToday,
  handleBreakWork,
  handleClockIn,
  handleClockOut,
  handleLunchBreakWork,
  handleResetTime,
  handleResumeWork,
} from "./service";
import { ClockCircleOutlined } from "@ant-design/icons";
import { ACTION } from "../../../utils/constants";
import { compareFnTimePopUp } from "../../../utils/functions";
import styled from "styled-components";

const TimeLineCustom = styled(Timeline)`
  .ant-timeline-item-head {
    background: transparent;
  }
`;
const formatTime = (timer) => {
  const getSeconds = `0${timer % 60}`.slice(-2);
  const minutes = `${Math.floor(timer / 60)}`;
  const getMinutes = `0${minutes % 60}`.slice(-2);
  const getHours = `0${Math.floor(timer / 3600)}`.slice(-2);

  const TimerStyle = {
    background: "#d1ebff",
    color: "#06c",
    padding: "10px 15px",
    borderRadius: "10px",
    margin: "5px",
    display: "inline-block",
    fontSize: 18,
    boxShadow:
      "0 1px 2px 0 rgb(0 0 0 / 3%), 0 1px 6px -1px rgb(0 0 0 / 2%), 0 2px 4px 0 rgb(0 0 0 / 2%)",
  };
  return (
    <>
      <div style={{ marginTop: 10 }}>
        <Space align="baseline">
          <b style={TimerStyle}>{getHours}</b>:
          <b style={TimerStyle}>{getMinutes}</b>:
          <b style={TimerStyle}>{getSeconds}</b>
        </Space>
      </div>
    </>
  );
};

const ACTUAL_TIME_WORKHOURS = 28800;

export const Timer = (props) => {
  const { isOpenModal, handleCancel } = props;

  const {
    timer,
    isLunchBreak,
    isActive,
    isPaused,
    isEndTime,
    handleStart,
    handleBreak,
    handleBreakLunch,
    handleResume,
    handleStop,
    // handleReset,
    loading,
    dataTable,
    isShowNotification,
    setIsShowNotification,
  } = useTimer(0, isOpenModal);

  const [isNotificationClockOut, setIsNotificationClockOut] = useState();

  useEffect(() => {
    if (timer === ACTUAL_TIME_WORKHOURS) {
      setIsNotificationClockOut(true);
    }
  }, [timer]);
  useEffect(() => {
    if (isNotificationClockOut) {
      Swal.fire({
        html: "<strong>Please Clock Out!</strong>",
        showCloseButton: true,
        showCancelButton: true,
        focusConfirm: false,
        confirmButtonText: "Clock out!",
        cancelButtonText: "Later!",
      }).then((result) => {
        if (result.isConfirmed) {
          handleClockOut();
          setIsShowNotification(false);
        }
        // if (result.isDismissed) {
        // }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isNotificationClockOut]);
  useEffect(() => {
    if (isShowNotification) {
      Swal.fire({
        html: "<strong>Please Clock In!</strong>",
        showCloseButton: true,
        showCancelButton: true,
        focusConfirm: false,
        confirmButtonText: "Clock in!",
        cancelButtonText: "Later!",
      }).then((result) => {
        if (result.isConfirmed) {
          handleStart();
          setIsShowNotification(false);
        }
        // if (result.isDismissed) {
        // }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isShowNotification]);
  const calculateTimeInMins = (startTime, endTime) => {
    const timer = moment(endTime).diff(moment(startTime), "minutes");
    return timer + 1;
  };

  const logsBreak = dataTable?.logs
    ?.filter((item) => item)
    .sort(compareFnTimePopUp);
  const getListTimeLine = () => {
    const listData = [];
    if (logsBreak && logsBreak.length > 0) {
      logsBreak.reduce((prev, currentValue) => {
        if (currentValue.action === ACTION.BREAK) {
          listData.push({
            id: currentValue.id,
            children: (
              <>
                Break{" "}
                <Tag color="default">
                  {moment(currentValue?.created_at).subtract(7, 'hour').format('HH:mm')}
                </Tag>
              </>
            ),

            dot: (
              <ClockCircleOutlined
                style={{
                  fontSize: "16px",
                  background: "transparent",
                }}
              />
            ),
            color: "red",
          });
          return currentValue;
        }
        if (currentValue.action === ACTION.LUNCH_BREAK) {
          listData.push({
            id: currentValue.id,

            children: (
              <>
                Lunch break{" "}
                <Tag color="default">
                  {moment(currentValue?.created_at).subtract(7, 'hour').format('HH:mm')}
                </Tag>
              </>
            ),
            dot: (
              <ClockCircleOutlined
                style={{
                  fontSize: "16px",
                  background: "transparent",
                }}
              />
            ),
            color: "red",
          });
          return currentValue;
        }
        if (currentValue.action === ACTION.RESUME_WORK) {
          listData.push({
            id: currentValue.id,

            children: (
              <>
                Resume break{" "}
                <Tag color="default">
                  {moment(currentValue?.created_at).subtract(7, 'hour').format('HH:mm')}
                </Tag>
                <Tag>
                  {calculateTimeInMins(
                    prev?.created_at,
                    currentValue?.created_at
                  )}{" "}
                  mins
                </Tag>
              </>
            ),
          });
          return currentValue;
        } else {
          listData.push({
            id: currentValue.id,

            children: (
              <>
                <b>{currentValue?.action.toUpperCase()}</b>{" "}
                <Tag color="default">
                  {moment(currentValue?.created_at).subtract(7, 'hour').format('HH:mm')}
                </Tag>
              </>
            ),
          });
          return currentValue;
        }
      }, {});
    }

    return listData;
  };
  return (
    <Modal
      open={isOpenModal}
      footer={false}
      onCancel={handleCancel}
      bodyStyle={{
        minHeight: "250px",
        padding: 20,
      }}
    >
      <Spin spinning={loading}>
        <div style={{ textAlign: "center" }}>
          <p style={{ color: "#154d76", fontSize: "20px", fontWeight: "600" }}>
            Daily Working Progress
          </p>
          {formatTime(timer)}
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <div
              style={{
                marginTop: "20px",
                marginBottom: "20px",
                width: "100%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              {!isActive && (
                <Button
                  size="large"
                  style={{ color: "#ffffff", backgroundColor: "#28a745" }}
                  onClick={handleStart}
                >
                  Start Your Day
                </Button>
              )}
            </div>
          </div>
        </div>
        {!isEndTime && (
          <>
            {" "}
            {isActive && (
              <div style={{ marginBottom: 30 }}>
                <Space
                  wrap
                  align="center"
                  style={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  {isPaused ? (
                    <Button
                      size="large"
                      style={{ backgroundColor: "#ffc008", color: "#3c4433" }}
                      onClick={handleResume}
                    >
                      Resume Working Day
                    </Button>
                  ) : (
                    <>
                      <div>
                        {!isLunchBreak && (
                          <Button
                            size="large"
                            style={{
                              backgroundColor: "#ffc107",
                              color: "#1f2d3d",
                            }}
                            onClick={handleBreakLunch}
                          >
                            Lunch Break
                          </Button>
                        )}
                        <Button
                          size="large"
                          style={{
                            backgroundColor: "#ffc008",
                            color: "#1f2d3d",
                          }}
                          onClick={handleBreak}
                        >
                          Break
                        </Button>
                      </div>
                    </>
                  )}

                  {isActive && (
                    <div>
                      <Button
                        size="large"
                        style={{ backgroundColor: "#dc3545", color: "#fef9fa" }}
                        onClick={handleStop}
                      >
                        End Working Day
                      </Button>
                    </div>
                  )}
                </Space>
              </div>
            )}
          </>
        )}
        {/* <div style={{ marginBottom: 10 }}>
          <Button onClick={handleReset}>Reset</Button>
        </div> */}
        <div
          id="style-scroll"
          style={{
            padding: "20px 10px",
            boxShadow:
              "0 1px 2px 0 rgb(0 0 0 / 3%), 0 1px 6px -1px rgb(0 0 0 / 2%), 0 2px 4px 0 rgb(0 0 0 / 2%)",
            background: "#f0f0f0a3",
            borderRadius: 10,
            maxHeight: "350px",
            overflow: "auto",
          }}
        >
          <TimeLineCustom mode="left" items={getListTimeLine()} />
        </div>
      </Spin>
    </Modal>
  );
};
const calculateTimer = (startTime, endTime, listBreak) => {
  let initialBreakTime = 0;
  if (!startTime) return 0;
  if (!endTime) {
    if (listBreak.length > 0) {
      let totalBreak = listBreak.reduce(function (total, currentValue) {
        return (
          total +
          moment(currentValue.end_time || new Date()).diff(
            moment(currentValue.start_time),
            "seconds"
          )
        );
      }, initialBreakTime);
      return moment(new Date()).diff(moment(startTime), "seconds") - totalBreak;
    } else {
      return moment(new Date()).diff(moment(startTime), "seconds");
    }
  } else {
    if (listBreak.length > 0) {
      let totalBreak = listBreak.reduce(function (total, currentValue) {
        return (
          total +
          moment(currentValue.end_time || endTime).diff(
            moment(currentValue.start_time),
            "seconds"
          )
        );
      }, initialBreakTime);
      return moment(endTime).diff(moment(startTime), "seconds") - totalBreak;
    } else {
      return moment(endTime).diff(moment(startTime), "seconds");
    }
  }
};

const useTimer = (initialState = 0, isOpenModal) => {
  const [timer, setTimer] = useState(initialState);
  const [isActive, setIsActive] = useState(false);
  const [isPaused, setIsPaused] = useState(false);
  const [isLunchBreak, setIsLunchBreak] = useState(false);
  const [isEndTime, setIsEndTime] = useState(false);
  const [loading, setLoading] = useState(false);
  const [dataTable, setDataTable] = useState([]);
  const [isShowNotification, setIsShowNotification] = useState();

  const countRef = useRef(null);

  const reFetchData = () => {
    fetchLogToday().then((res) => {
      setLoading(false);
    });
  };
  const fetchLogToday = async () => {
    //
    let res = await getLogToday();
    res = res.data.response;
    if (res && res?.logs?.length <= 0) {
      setIsShowNotification(true);
    } else {
      setIsShowNotification(false);
    }
    setIsPaused(res.is_break);
    setIsLunchBreak(res.lunch_breaked);
    setIsActive(res.start_time ? true : false);
    setIsEndTime(res.end_time ? true : false);
    setDataTable(res);
    let second = calculateTimer(res.start_time, res.end_time, res.breaks);
    setTimer(second);
    if (res?.start_time && !res.is_break && res.end_time == null) {
      clearInterval(countRef.current);
      countRef.current = setInterval(() => {
        setTimer((timer) => timer + 1);
      }, 1000);
    } else {
      clearInterval(countRef.current);
    }
    return res;
  };
  useEffect(() => {
    fetchLogToday();
  }, [isOpenModal]);

  const handleStart = () => {
    setLoading(true);
    handleClockIn().then((res) => {
      reFetchData();
    });
  };

  const handleBreak = () => {
    Swal.fire({
      title: "Do you want to break ?",
      showCancelButton: true,
      confirmButtonText: "Save",
    }).then((result) => {
      /* Read more about isConfirmed, isDenied below */
      if (result.isConfirmed) {
        setLoading(true);
        clearInterval(countRef.current);
        handleBreakWork().then((res) => {
          fetchLogToday().then((res) => {
            setLoading(false);
          });
        });
      }
    });
  };
  const handleBreakLunch = () => {
    Swal.fire({
      title: "Do you want lunch break ?",
      showCancelButton: true,
      confirmButtonText: "Save",
    }).then((result) => {
      /* Read more about isConfirmed, isDenied below */
      if (result.isConfirmed) {
        setLoading(true);
        clearInterval(countRef.current);
        handleLunchBreakWork().then((res) => {
          fetchLogToday().then((res) => {
            setLoading(false);
          });
        });
      }
    });
  };

  const handleResume = () => {
    setLoading(true);
    handleResumeWork().then((res) => {
      reFetchData();
    });
  };

  const handleStop = () => {
    Swal.fire({
      title: "Do you want clock out ?",
      showCancelButton: true,
      confirmButtonText: "Yes",
    }).then((result) => {
      /* Read more about isConfirmed, isDenied below */
      if (result.isConfirmed) {
        setLoading(true);
        clearInterval(countRef.current);
        handleClockOut().then((res) => {
          fetchLogToday().then((res) => {
            setLoading(false);
          });
        });
      }
    });
  };
  const handleReset = () => {
    setLoading(true);
    handleResetTime().then((res) => {
      fetchLogToday().then((res) => {
        clearInterval(countRef.current);
        setLoading(false);
      });
    });
  };

  return {
    timer,
    isLunchBreak,
    isActive,
    isPaused,
    isEndTime,
    handleStart,
    handleBreak,
    handleBreakLunch,
    handleResume,
    handleStop,
    handleReset,
    loading,
    dataTable,
    isShowNotification,
    setIsShowNotification,
  };
};
